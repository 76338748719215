import styled, { css } from 'styled-components';

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`;

export const MainContent = styled.div<{ maxWidth?: string }>`
  ${({ theme, maxWidth }) => css`
    width: 100%;
    max-width: ${maxWidth || '627px'};
    /* margin: 20px; */
    padding: 32px;

    background-color: ${theme.colors.white00};

    border-radius: 4px;
    display: flex;
    flex-direction: column;
  `};
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;

    margin-bottom: 24px;

    > h2 {
      ${theme.texts.headingSM};
      color: ${theme.colors.black00};
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      
      height: 40px;
      width: 40px;
      
      border: 1px solid ${theme.colors.black10};
      border-radius: 4px;

      background-color: transparent;
      transition: ${theme.transitions.default};

      cursor: pointer;

      :hover {
        border-color: ${theme.colors.black00};
      }

      > svg {
        font-size: 2rem;
      }

      &:first-child {
        margin: 0 16px 0 0;
      }

      &:last-child {
        margin-left: auto;
      }
    }
  `};
`;

export const Body = styled.div`
  position: relative;
`;
