import React, {
  ReactElement, useEffect, useState, useMemo,
} from 'react';

import {
  RiCheckLine,
  RiDeleteBin5Line,
  RiEdit2Line,
  RiFile2Line,
  RiFilePdfLine,
  RiFileWordLine,
  RiFileExcelLine,
  RiImage2Line,
  RiErrorWarningLine,
  RiEyeLine,
} from 'react-icons/ri';
import { InfoMode } from '../../../@types/InfoMode';

import Button from '../../Button';
import ExcludeModal from '../../ExcludeModal';
import { Props } from './ListDocument.spec';

import {
  BoxDocument,
  BoxDocumentContent,
  EditName,
  ActionsButtons,
  Flag,
} from './styles';
import { formatDateWithHour } from '../../../utils/formatters';
import TooltipComponent from '../../Tooltip';

const ListDocument = ({
  file, onRemove, onEdit, mode,
}: Props): ReactElement => {
  const [editable, setEditable] = useState<boolean>(false);
  const [removeDoc, setRemoveDoc] = useState(false);

  function formatBytes(bytes: any): string {
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const dm = 2;
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  }

  const icon = useMemo(() => {
    if (file.mimeType === 'application/pdf') {
      return <RiFilePdfLine className="red-icon" />;
    } if (file.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.mimeType === 'application/msword') {
      return <RiFileWordLine className="blue-icon" />;
    } if (file.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.mimeType === 'application/vnd.ms-excel') {
      return <RiFileExcelLine className="green-icon" />;
    } if (file.mimeType.match(/^image\//)) {
      return <RiImage2Line />;
    }
    return <RiFile2Line />;
  }, [file.mimeType]);

  const isExpired = (): boolean => new Date(file.dueDate) < new Date();

  return (

    <BoxDocument key={file.id}>
      {icon}
      <BoxDocumentContent>
        {editable ? (

          <EditName>
            <input
              type="text"
              onChange={(e) => {
                onEdit({ ...file, filename: e.target.value });
              }}
              value={file.filename}
            />

            <RiCheckLine onClick={() => setEditable(false)} />
          </EditName>

        ) : (

          <EditName>
            <p>
              {file.filename}
            </p>
            <RiEdit2Line onClick={() => setEditable(true)} />
          </EditName>

        )}

        <small>
          {formatBytes(file.size)}
          {' '}
          •
          {' '}
          {formatDateWithHour(file.createdAt)}
        </small>
      </BoxDocumentContent>

      <ActionsButtons>
        {isExpired() && (
          <TooltipComponent
            text="Documento com prazo de validade excedido, requer atualização do mesmo."
            placement="bottom"
            tooltip={(
              <RiErrorWarningLine />
            )}
          />
        )}

        <Flag className={isExpired() ? 'alert' : ''}>{file.categoryText}</Flag>

        <div>
          <Button type="button" icon={<RiEyeLine />} message="" className="view" />
          {mode !== InfoMode.VIEW && (
            <Button type="button" icon={<RiDeleteBin5Line />} message="" className="delete" onClick={() => setRemoveDoc(true)} />
          )}
        </div>
      </ActionsButtons>

      {removeDoc && (
        <ExcludeModal
          handleCloseModal={() => setRemoveDoc(false)}
          handleDeleteConfirmation={() => onRemove(file)}
          type="document"
          visibleValue={file.filename}
        />
      )}
    </BoxDocument>

  );
};

export default ListDocument;
