import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > label {
      ${theme.texts.paragraphXXS};
      margin: 0 0 8px 0;

      > small {
        color: ${theme.colors.black50};
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
    }

    input {
      border-radius: 4px;
      background-color: ${theme.colors.black05};
      border: 0;
      /* height: 42px; */
      padding: 12px 16px;
      transition: ${theme.transitions.default};
      ${theme.texts.paragraphXS}

      &::placeholder {
        color: ${theme.colors.black30};
      }

      &:disabled {
        color: ${theme.colors.black40};
        background-color: ${theme.colors.black20};
      }
    }

    &.error {
      > small {
        color: ${theme.colors.error};
      }
      > input {
        border-color: ${theme.colors.error};
      }
    }
  `};
`;

export const CustomInput = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    position: relative;

    display: flex;
    align-items: center;

    > input {
      width: 100%;
    }

    > svg {
      font-size: 1.6rem;
      color: ${theme.colors.black00};

      position: absolute;
      right: 20px;
    }

    ${disabled
    && css`
      > svg {
        color: ${theme.colors.black40};
      }

      ${Circle} {
        background-color: ${theme.colors.black20};
      }
    `};
  `};
`;
export const LoadingCircle = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 20px;

    margin: 0 0 0 auto;
    padding: 1.2px;
    border-radius: 50%;
    display: inline-block;
    background: conic-gradient(
      from 180deg at 50% 50%,
      ${theme.colors.black00} 0deg,
      rgba(255, 255, 255, 0) 360deg
    );

    transition: ${theme.transitions.default};
    animation: ${spinAnimation} 0.6s linear infinite;
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 14px;
    height: 14px;
    background-color: ${theme.colors.black05};
    border-radius: 50%;
    transition: ${theme.transitions.default};
  `}
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`;
