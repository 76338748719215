import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Form = styled.form``;
export const ContentForm = styled.div`
${({ theme }) => css`
    > h2 {
      ${theme.texts.headingXXS};
      margin-bottom: 24px;
    }
  `}
`;
export const InputRow = styled.div`
display: flex;
  justify-content: space-between;
  flex: 1;

  margin-bottom: 24px;

  > div {
    width: 100%;

    :not(:last-child) {
      margin-right: 12px;
    }
  }

`;
