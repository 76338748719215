import React, { ReactElement, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import {
  Input,
  InputCurrency,
  MaskedInput,
  Select,
} from '../../..';

import { Container, ContentForm, InputRow } from './styles';
import { cnpjMask, cpfMask, phoneMask9 } from '../../../../utils/masks';
import InputDate from '../../../DateInput';
import { GuarantorDataCore } from '../../GuarantorData.spec';
import { formatDate } from '../../../../utils/formatters';
import CheckboxInput from '../../../CheckboxInput';

interface GuarantorDataProps {
  formik: FormikProps<GuarantorDataCore>;
}

export const selectRelationshipCompany = [{
  id: 1,
  text: 'Empresa',
}];

export const selectBusinessTypes = [
  {
    id: 1,
    text: 'Comércio',
  },
];

const GuarantorData = ({ formik }: GuarantorDataProps): ReactElement => {
  const { t } = useTranslation();

  const company = formik.values;

  const handleTaxCheck = (taxIDFieldName: string, taxFreeFieldName: string, isTaxFree: boolean): void => {
    formik.setFieldValue(taxFreeFieldName, isTaxFree);
    formik.setFieldValue(taxIDFieldName, '');
  };

  return (
    <Container>
      <ContentForm>
        <InputRow>
          <MaskedInput
            label={t('guarantorDataCompany.labelCNPJ')}
            name="documentNumber"
            mask={cnpjMask}
            guide={false}
            value={company?.documentNumber}
            placeholder="00.000.000/0000-00"
            onChange={({ target: { value } }) => formik?.setFieldValue(
              'documentNumber',
              value?.replace(/\D/g, ''),
            )}
            message={formik.errors.documentNumber}
            required
          />
          <Input
            name="legalName"
            label={t('guarantorDataCompany.labelCompanyName') || ''}
            value={company?.legalName}
            onChange={formik?.handleChange}
            message={formik.errors.legalName}
            placeholder={t('guarantorDataCompany.placeholderCompanyName') || ''}
            required
          />
        </InputRow>
        <InputRow>
          <Input
            name="tradeName"
            label={t('guarantorDataCompany.labelTradeName') || ''}
            value={company?.tradeName}
            placeholder={t('guarantorDataCompany.placeholderTradeName') || ''}
            onChange={formik?.handleChange}
            message={formik.errors.tradeName}
            required
          />
          <Select
            label={t('guarantorDataCompany.labelBusinessType')}
            name="businessTypeID"
            options={selectBusinessTypes}
            message={formik.errors.businessTypeID}
            value={selectBusinessTypes.find((businessType) => businessType.id === Number(formik?.values.businessTypeID))}
            onChange={(selectedOption: any) => formik?.setFieldValue('businessTypeID', selectedOption.id)}
            visibleProp="text"
            required
          />
        </InputRow>
        <InputRow>
          <CheckboxInput
            type="number"
            name="stateTaxID"
            label={t('guarantorDataCompany.labelInscriptionState') || ''}
            placeholder={t('guarantorDataCompany.placeholderInscriptionState') || ''}
            onChange={formik.handleChange}
            value={company.stateTaxFree ? '' : company.stateTaxID}
            message={formik.errors.stateTaxID}
            onCheck={(checked) => handleTaxCheck('stateTaxID', 'stateTaxFree', checked)}
            check={company.stateTaxFree}
            required
          />
          <CheckboxInput
            type="number"
            name="cityTaxID"
            label={t('guarantorDataCompany.labelInscriptionMunicipal') || ''}
            onCheck={(checked) => handleTaxCheck('cityTaxID', 'cityTaxFree', checked)}
            value={company.cityTaxFree ? '' : company.cityTaxID}
            onChange={formik.handleChange}
            message={formik.errors.cityTaxID}
            placeholder={t('guarantorDataCompany.labelInscriptionMunicipal') || ''}
            check={company.cityTaxFree}
            required
          />
        </InputRow>
        <InputRow>
          <InputDate
            name="incorporationDate"
            label={t('guarantorDataCompany.labelOpeningDate')}
            placeholderText="00/00/0000"
            selected={company?.incorporationDate ? formatDate(company.incorporationDate) : null}
            message={formik.errors.incorporationDate}
            onChange={(value: any) => {
              formik?.setFieldValue(
                'incorporationDate',
                value ? value.toISOString().slice(0, 10) : '',
              );
            }}
            required
          />
          <InputCurrency
            name="equity"
            label={t('guarantorDataCompany.labelNetWorth') || ''}
            value={company?.equity}
            message={formik.errors.equity}
            onStringChange={(v, name) => {
              formik?.setFieldValue(name, v);
            }}
            required
          />
        </InputRow>
        <InputRow>
          <Input
            name="email"
            label={t('guarantorDataCompany.labelEmail') || ''}
            value={company?.email}
            message={formik.errors.email}
            onChange={formik?.handleChange}
            type="email"
            placeholder={t('guarantorDataCompany.placeholderEmail') || ''}
            required
          />
          <MaskedInput
            name="mobilePhoneNumber"
            label={t('guarantorDataCompany.labelPhoneNumber') || ''}
            placeholder={t('guarantorDataCompany.placeholderPhoneNumber') || ''}
            mask={phoneMask9}
            guide={false}
            message={formik.errors.mobilePhoneNumber}
            value={company?.mobilePhoneNumber}
            onChange={({ target: { value } }) => {
              formik?.setFieldValue(
                'mobilePhoneNumber',
                value?.replace(/\D/g, ''),
              );
              formik?.setFieldValue(
                'landlinePhoneNumber',
                value?.replace(/\D/g, ''),
              );
            }}
            required
          />
        </InputRow>
        <InputRow>
          <Select
            label={t('guarantorDataPerson.labelRelationshipType') || ''}
            name="relationshipTypeID"
            options={selectRelationshipCompany}
            message={formik.errors.relationshipTypeID}
            value={selectRelationshipCompany.find((relationship) => relationship.id === Number(formik?.values.relationshipTypeID))}
            onChange={(selectedOption: any) => formik?.setFieldValue('relationshipTypeID', selectedOption.id)}
            visibleProp="text"
            required
          />
        </InputRow>
      </ContentForm>
    </Container>
  );
};

export default GuarantorData;
