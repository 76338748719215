import styled, { css } from 'styled-components';

export const Subtitle = styled.h2`
  ${({ theme }) => css`
      ${theme.texts.headingXXS}
      margin: 20px 0;
  `};
`;

export const CheckboxLabel = styled.p`
  ${({ theme }) => css`
    ${theme.texts.paragraphXXS}
  } 
  `} 
`;

export const CheckboxControl = styled.div`
  display: flex;
  gap: 24px;
  margin: 12px 0;
`;

export const PaymentInfoFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px 0;
`;

export const PaymentInfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 0 12px;
`;

export const PaymentInfoItem = styled.div`
  ${({ theme }) => css`
    padding: 12px 16px;
    background-color: ${theme.colors.black05};
    width: 100%;
    max-width: 248px;
    border-radius: 4px;
  `}
`;

export const PaymentInfoLabel = styled.p`
  ${({ theme }) => css`
    ${theme.texts.paragraphXS};
  `}
`;

export const PaymentInfoValue = styled.span`
  ${({ theme }) => css`
    ${theme.texts.buttonSM};
  `}
`;

export const PaymentInfoActions = styled.div`
  display: flex;
  align-items: center;

  button {
    padding: 12px 20px;
    ${({ theme }) => css`
      ${theme.texts.buttonXS};
    `}

  }

  button:first-child {
    border: none;
  } 

  button:last-child {
    padding: 12px 40px 12px 20px;
  }
`;

export const CheckboxItem = styled.div`
${({ theme }) => css`
  ${theme.texts.paragraphSM};
`}
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Form = styled.div``;

export const InputControlRow = styled.div`
  display: flex;
  margin-bottom: 24px;

  > div {
    width: 49%;

    :first-child {
      margin-right: 12px;
    }

    :last-child {
      margin-right: 0;
    }
  };
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.black10};
    width: 100%;
    position: absolute;
    bottom: 90px;
    left: 0;
  `}
`;

export const Bottom = styled.div`
  ${({ theme }) => css`
    padding: 16px 0 0 0;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    > button {
      max-width: 102px;
      padding: 10px 20px;

      ${theme.texts.buttonXS};
    }
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto 0 0 0;
    padding: 24px 0 0 0;
    border-top: 1px solid ${theme.colors.black10};
  `};
`;

export const StepsNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > small {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
    }
  `};
`;

export const StepsList = styled.div`
  ${({ theme }) => css`
    display: flex;

    margin: 0 0 0 2px;

    > span {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black30};
      padding: 3.5px 12px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:not(:last-child) {
        border-right: 0.5px solid ${theme.colors.black10};
      }

      > svg {
        font-size: 1.4rem;
        color: ${theme.colors.success00};
      }

      &.active {
        color: ${theme.colors.black00};
      }
    }
  `};
`;

export const FooterButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      height: 42px;

      &:first-child {
        background-color: transparent;
        margin: 0 6px 0 0;
        padding: 10px 20px;
        ${theme.texts.buttonXS};
      }
    }
  `};
`;
