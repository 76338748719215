import { FormikProps } from 'formik';
import React, { ReactElement } from 'react';
import { SocialNetwork } from '../../../SocialNetwork';
import { GuarantorDataCore } from '../../GuarantorData.spec';

interface GuarantorSocialProps {
  formik: FormikProps<GuarantorDataCore>
}

export function GuarantorSocial({ formik }: GuarantorSocialProps): ReactElement {
  return <SocialNetwork socialList={formik.values.socialNetworks} setSocialList={(socials) => formik.setFieldValue('socialNetworks', socials)} />;
}
