import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ProductList = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;

    > h4 {
      color: ${theme.colors.black00};
      ${theme.texts.paragraphXS};
      margin-bottom: 12px;
    }
  `};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  height: fit-content;
  max-height: 250px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ListContent = styled.div<{ active?: boolean }>`
  ${({ theme, active }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 14px 10px 12px;
    background-color: ${theme.colors.black05};
    cursor: pointer;
    border: 1px solid ${theme.colors.black05};
    border-radius: 6px;
    max-height: 52px;

    transition: ${theme.transitions.default};

    :nth-child(2n) {
      background-color: ${theme.colors.white00};
      border-color: ${theme.colors.white00};
      margin: 4px 0;
    }

    ${active
    && css`
      border-color: ${theme.colors.success00} !important;
      background-color: ${theme.colors.success05} !important;
    `};

    small {
      display: block;
      background-color: red;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 12px 0 auto;
    }
  `};
`;

export const Texts = styled.div`
  ${({ theme }) => css`
    user-select: none;
    
    > h3 {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black00};
    }

    span {
      ${theme.texts.paragraphXXXS};
      color: ${theme.colors.black50};
    }
  `};
`;

export const StepsNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > small {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
    }
  `};
`;

export const StepsList = styled.div`
  ${({ theme }) => css`
    display: flex;

    margin: 0 0 0 2px;

    > span {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black30};
      padding: 3.5px 12px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:not(:last-child) {
        border-right: 0.5px solid ${theme.colors.black10};
      }

      > svg {
        font-size: 1.4rem;
        color: ${theme.colors.success00};
      }

      &.active {
        color: ${theme.colors.black00};
      }
    }
  `};
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.black10};
    padding: 24px 0 0 0;
    width: 100%;
    max-height: 90px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > button {
      height: 42px;
    }

    > small {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black00};

      display: flex;
      align-items: center;

      > svg {
        margin: 0 6px 0 0;
      }

      > b {
        font-weight: 500;
        text-decoration: underline;
        margin: 0 0 0 3px;
        cursor: pointer;
      }
    }
  `};  
`;

export const BlockedContent = styled.section<{ isLoading?: boolean }>`
  ${({ isLoading }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    /* margin: 0 0 32px 0; */
    /* min-height: 270px; */
    /* max-height: 270px; */
    flex: 1;
    /* overflow-y: auto; */

    ${isLoading
    && css`
      /* overflow-y: hidden; */
    `};
  `};
`;

export const EmptyProducts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 37px 0;
    margin: auto;
    user-select: none;

    > img {
      margin: 0 0 24px 0;
      max-width: 148px;
      pointer-events: none;
    }
    > h4 {
      ${theme.texts.buttonLG};
      letter-spacing: -0.2px;
      margin: 0 0 4px 0;s
    }
    > p {
      color: ${theme.colors.black00};
      ${theme.texts.paragraphMD};
    }
  `};  
`;
