import { faker } from '@faker-js/faker';
import { CoreOnboarding } from '../@types/OnboardingModal';
// Table fields header & content
export const customerTableHeaderTitles = ['Nome completo', 'CPF/CNPJ', 'Telefone', 'E-mail', 'Tipo de cliente'];
export const customerTableContentKeys = ['name', 'cpf', 'phone', 'email', 'typeUser'];

export const listSelect = [{ id: '1', name: 'Item01' }, { id: '2', name: 'Item02' }];

export const onboardingItems: CoreOnboarding[] = [];
export const onboardingMe: CoreOnboarding = createRandomOnboarding();

export function createRandomOnboarding(): any {
  return {

    guarantors: [
      {
        id: faker.datatype.uuid(),
        name: faker.name.fullName(),
        cpf: faker.phone.number('9999999999'),
        civilStatus: faker.helpers.arrayElement(listSelect),
        phone: faker.phone.number(),
        birthDate: faker.date.birthdate(),
        email: faker.internet.email(),
        occupation: faker.helpers.arrayElement(listSelect),
        income: faker.finance.amount(),
        netWorth: faker.finance.amount(),
        spouse: faker.datatype.boolean(),
      },
    ],
  };
}

export default onboardingItems;
