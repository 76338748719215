import React, { ReactElement, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { ProfessionSDTO } from '../../../../../../@types/Profession';
import ProfessionService from '../../../../../../services/profession';
import {
  Container, ContentForm, InputRow, InputSolo,
} from './styles';
import {
  Input, InputCurrency, MaskedInput, Select, Textarea,
} from '../../../../..';
import { cnpjMask, cpfMask, phoneMask9 } from '../../../../../../utils/masks';
import InputDate from '../../../../../DateInput';
import { MARITAL_STATUS, SEX_OPTIONS } from '../../../../CustomerData.spec';
import InputControl from '../../../../../Input';
import { formatDate } from '../../../../../../utils/formatters';
import { useListItem } from '../../../../../../hooks';
import { AgentContext } from '../Agent.spec';

export function PrimaryInfoAgent(): ReactElement {
  const { t } = useTranslation();
  const { formik } = useContext(AgentContext);

  const [professions, setProfessions] = useState<ProfessionSDTO[]>([{ id: 1, uid: '', text: 'Empresário' }]);
  const [relationshipTypes, setRelationshipTypes] = useState<any[]>([{ id: 1, uid: '', text: 'Sócio Majoritário' }]);

  return (

    <Container>
      <ContentForm>
        <h2>{t('partnerAgent.titleTypeBond') || ''}</h2>
        <InputRow>
          <Select
            name="responsability"
            label={t('partnerAgent.labelInputResponsibility') || ''}
            options={[]}
            value={formik.values.responsability}
            onChange={(selectedOption: any) => formik.setFieldValue('responsability', selectedOption)}
          />
        </InputRow>
      </ContentForm>
      <ContentForm>
        <h2>{t('partnerAgent.titlePersonalData') || ''}</h2>
        <InputRow>
          <MaskedInput
            name="documentNumber"
            label={t('partnerAgent.labelInputCPF') || ''}
            placeholder="000.000.000-00"
            mask={cpfMask}
            guide={false}
            value={formik.values.documentNumber}
            onChange={({ target: { value } }) => formik.setFieldValue(
              'documentNumber',
              value?.replace(/\D/g, ''),
            )}
            message={formik.errors.documentNumber}
            required
          />
          <Input
            name="fullName"
            label={t('partnerAgent.labelInputName') || ''}
            placeholder={t('partnerAgent.placeholderInputName') || ''}
            value={formik.values.fullName}
            onChange={formik.handleChange}
            message={formik.errors.fullName}
            required
          />
        </InputRow>
        <InputRow>
          <InputDate
            name="birthdate"
            label={t('partnerAgent.labelInputBirthDate') || ''}
            selected={formatDate(formik.values.birthdate)}
            onChange={(value: any) => {
              formik.setFieldValue('birthdate', value.toISOString().split('T')[0]);
            }}
            message={formik.errors.birthdate}
            required
          />
          <Select
            name="civilStatus"
            label={t('partnerAgent.labelInputCivilStatus') || ''}
            options={MARITAL_STATUS}
            message={formik.errors.civilStatusID}
            value={
              formik.values.civilStatusID
                ? MARITAL_STATUS?.find(
                  (e) => e.id === Number(formik.values.civilStatusID),
                )
                : null
            }
            onChange={(selectedOption: any) => formik.setFieldValue(
              'civilStatusID',
              selectedOption.id,
            )}
            required
          />

        </InputRow>
        <InputRow>
          <Select
            name="sex"
            label="Sexo"
            options={SEX_OPTIONS}
            message={formik.errors.sexID}
            value={
              formik.values.sexID
                ? SEX_OPTIONS?.find(
                  (e) => e.id === Number(formik.values.sexID),
                )
                : null
            }
            onChange={(selectedOption: any) => formik.setFieldValue(
              'sexID',
              selectedOption.id,
            )}
            required
          />
          <MaskedInput
            name="mobilePhoneNumber"
            label={t('partnerAgent.labelInputPhone') || ''}
            placeholder="+55 51 9 9999 9999"
            message={formik.errors.mobilePhoneNumber}
            mask={phoneMask9}
            guide={false}
            value={formik.values.mobilePhoneNumber}
            onChange={({ target: { value } }) => formik.setFieldValue(
              'mobilePhoneNumber',
              value?.replace(/\D/g, ''),
            )}
            required
          />
        </InputRow>
        <InputRow>
          <InputControl
            name="email"
            type="email"
            placeholder="seuemail@email.com.br"
            label="E-mail"
            onChange={formik.handleChange}
            value={formik.values.email}
            message={formik.errors.email}
            required
          />
          <InputCurrency
            name="netWorth"
            label="Patrimônio líquido"
            value={formik.values.netWorth}
            message={formik.errors.netWorth}
            onStringChange={(value, name) => formik.setFieldValue(name, value)}
            required
          />
        </InputRow>
        <InputRow>
          <Select
            name="profession"
            label="Profissão"
            value={professions?.find((profession) => profession.id === Number(formik.values.professionID))}
            message={formik.errors.professionID}
            onChange={(selectedOption: any) => formik.setFieldValue('professionID', selectedOption.id)}
            options={professions}
            required
          />
          <InputCurrency
            name="income"
            label="Renda"
            value={formik.values.income}
            message={formik.errors.income}
            onStringChange={(value, name) => formik.setFieldValue(name, value)}
            required
          />
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>Empresa em seu nome</h2>
        <InputRow>
          <MaskedInput
            name="companyDocumentNumber"
            label={t('partnerAgent.labelInputCNPJ') || ''}
            placeholder="00.000.0000/0000-00"
            mask={cnpjMask}
            guide={false}
            value={formik.values.companyDocumentNumber}
            onChange={({ target: { value } }) => formik.setFieldValue(
              'companyDocumentNumber',
              value?.replace(/\D/g, ''),
            )}
            message={formik.errors.companyDocumentNumber}
            required
          />
          <Input
            name="companyLegalName"
            label={t('partnerAgent.labelCompanyLegalName') || ''}
            placeholder={t('partnerAgent.placeholderCompanyLegalName') || ''}
            type="text"
            value={formik.values.companyLegalName}
            message={formik.errors.companyLegalName}
            onChange={formik.handleChange}
            required
          />
        </InputRow>
        <InputRow>
          <Select
            name="relationshipTypeID"
            label="Tipo de vínculo"
            value={relationshipTypes?.find((relationship) => relationship.id === Number(formik.values.relationshipTypeID))}
            message={formik.errors.relationshipTypeID}
            onChange={(selectedOption: any) => formik.setFieldValue('relationshipTypeID', selectedOption.id)}
            options={professions}
            required
          />
          <InputCurrency
            name="companyPercent"
            label="Percentual"
            value={formik.values.companyPercent}
            message={formik.errors.companyPercent}
            onStringChange={(value, name) => formik.setFieldValue(name, value)}
            prefix="%"
            required
          />
        </InputRow>
      </ContentForm>
      <ContentForm>
        <h2>{t('partnerAgent.titleAdditionalInformation')}</h2>
        <InputSolo>
          <Textarea
            name="description"
            label={t('partnerAgent.labelInputObservation') || ''}
            placeholder={
              t('partnerAgent.placeholderInputObservation') || ''
            }
            value={formik.values.observations}
            onChange={formik.handleChange}
          />
        </InputSolo>
      </ContentForm>
    </Container>
  );
}
