import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  RiInformationLine,
  RiCloseCircleFill,
} from 'react-icons/ri';

import EmptyTable from '../../assets/EmptyTable.svg';

import { ProductItemProps } from '../../@types';

import {
  Button, Modal, Loading, Checkbox, SearchInput,
} from '..';

import { productService } from '../../services';

import { OperationInputContext } from '../../routes/AuthenticatedRoutes';

import {
  Container,
  ProductList,
  List,
  ListContent,
  Texts,
  BlockedContent,
  Footer,
  EmptyProducts,
} from './styles';

import { CustomerTypeTypes } from './ProductListModal.spec';

import TabNavigation from '../SimulatorSteps/SimulatorModal/TabNavigation';

interface ProductSelectModalProps {
  handleCloseModal?: () => void;
}

const ProductListModal = ({
  handleCloseModal,
}: ProductSelectModalProps): ReactElement => {
  const routerNavigate = useNavigate();
  const {
    selectedProduct, setSelectedProduct, selectedCustomerType, setSelectedCustomerType,
  } = useContext(
    OperationInputContext,
  );
  const [products, setProducts] = useState<ProductItemProps[] | any>([]);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>('');

  useEffect((): void => {
    getProducts();
  }, [selectedCustomerType, searchQuery]);

  async function getProducts(): Promise<void> {
    setIsLoading(true);
    try {
      if (selectedCustomerType) {
        setSelectedProduct(null);
        const productsResponse = (
          await productService.getProductByCustomerType(selectedCustomerType, searchQuery)
        )?.content;
        console.log(productsResponse);
        setProducts(productsResponse);
      }
    } catch {
      toast.error(
        'Não foi possível listar os produtos para esse tipo de cliente.',
        {
          className: 'toast-message',
          icon: RiCloseCircleFill,
          containerId: 'close-on',
        },
      );
    } finally {
      setIsLoading(false);
    }
  }

  function handleOperationStart(): void {
    setIsButtonLoading(true);
    setTimeout(() => {
      setIsButtonLoading(false);
      routerNavigate('/operations');
    }, 1000);
  }

  return (
    <Modal title="Seleção de produto" onClose={handleCloseModal}>
      <Container>
        <TabNavigation
          customerType={selectedCustomerType}
          changeCustomerType={(customerProps: CustomerTypeTypes) => {
            setSearchQuery('');
            setSelectedCustomerType(customerProps);
          }}
        />
        {products?.length || isLoading ? (
          <SearchInput
            value={searchQuery}
            onChange={setSearchQuery}
          />
        ) : (
          ''
        )}
        <BlockedContent isLoading={isLoading || isButtonLoading}>
          <ProductList>
            {products?.length || isLoading ? <h4>Lista de produtos:</h4> : ''}
            <List>
              {products?.length && !isLoading
                ? products?.map((item: ProductItemProps) => (
                  <ListContent
                    key={item.id}
                    onClick={() => {
                      if (item?.workflowID) {
                        setSelectedProduct(item);
                      }
                    }}
                    active={item?.id === selectedProduct?.id}
                  >
                    <Texts>
                      <h3>{item.name}</h3>
                      <span>{item.description}</span>
                    </Texts>
                    {item?.workflowID ? (
                      <Checkbox
                        checked={item?.id === selectedProduct?.id}
                        shape="circle"
                        customColor
                      />
                    ) : (
                      ''
                    )}
                  </ListContent>
                ))
                : ''}
            </List>
            {!products?.length && !isLoading ? (
              <EmptyProducts>
                <img src={EmptyTable} alt="EmptyTable" />
                <h4>Nenhum dado encontrado</h4>
                <p>Não possui nenhum tipo de dado para ser exibido.</p>
              </EmptyProducts>
            ) : (
              ''
            )}
          </ProductList>
        </BlockedContent>
        {(isLoading || isButtonLoading) && <Loading />}
        <Footer>
          <small>
            <RiInformationLine />
            Não encontrou o produto correspondente?
            {' '}
            <b>Clique aqui.</b>
          </small>
          <Button
            onClick={() => handleOperationStart()}
            isLoading={isButtonLoading}
            type="button"
            message="Continuar"
            loadingMessage="Iniciando.."
            disabled={!selectedProduct || isButtonLoading}
          />
        </Footer>
      </Container>
    </Modal>
  );
};

export default ProductListModal;
