import styled, { css } from 'styled-components';

export const Container = styled.section``;

export const InputRow = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex: 1;
  flex-wrap: wrap;

  margin-bottom: 24px;

  > div {
    width: 24%;

    :not(:last-child) {
      margin-right: 12px;
    }
  }
`;

export const InputSolo = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  margin-bottom: 24px;

  > div {
    width: 100%;
    margin-right: 12px;
  }
`;

export const ContentForm = styled.div`
  ${({ theme }) => css`
    > h2 {
      ${theme.texts.headingXXS};
      margin-bottom: 24px;
    }
  `}
`;
