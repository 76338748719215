import React, {
  ReactElement, useState, useContext, useEffect,
} from 'react';
import { useFormik } from 'formik';

import PhysicalPerson from './PhysicalPerson';
import LegalPerson from './LegalPerson';

import { INITIAL_VALUES, YUP_SCHEMAS } from './DataPreReview.spec';

import { OperationStepsContext } from '../../../pages/Operations';

import {
  Container, Content, Form,
} from './styles';
import { OperationInputContext } from '../../../routes/AuthenticatedRoutes';
import { OutletContent } from '../../../pages/Operations/styles';
import OperationStepFormBase from '../../OperationStepFormBase';

export const DataPreReview = (): ReactElement => {
  const { handleStepSubmission, currentStepData } = useContext(OperationStepsContext);
  const { selectedCustomerType } = useContext(OperationInputContext);

  const dataPreReviewForm = useFormik({
    enableReinitialize: true,
    validationSchema: YUP_SCHEMAS[selectedCustomerType],
    validateOnChange: false,
    initialValues: currentStepData?.inputData ?? INITIAL_VALUES[selectedCustomerType],
    async onSubmit(formValues) {
      // console.log(formValues);
      handleStepSubmission(formValues);
    },
  });

  return (
    <OperationStepFormBase handleSubmit={dataPreReviewForm.handleSubmit}>
      <OutletContent>
        <Container>
          <Form>
            <Content>
              {selectedCustomerType === 'PERSON' ? (
                <PhysicalPerson parentFormik={dataPreReviewForm} />
              ) : (
                <LegalPerson />
              )}
            </Content>
          </Form>
        </Container>
      </OutletContent>
    </OperationStepFormBase>
  );
};

export default DataPreReview;
