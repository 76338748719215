import { faker } from '@faker-js/faker';
import { SummaryItemProps } from '../@types';

const summaryItemTitles = ['Em análise', 'Pendente', 'Rec. Alçada', 'Cancelada', 'Pago'];

export const summaryItems: SummaryItemProps[] = [];

export function createRandomSummaryItem(): SummaryItemProps {
  return {
    id: faker.datatype.uuid(),
    title: faker.helpers.arrayElement(summaryItemTitles),
    number: faker.datatype.number(),
    value: faker.finance.amount(),
  };
}

Array.from({ length: 5 }).forEach(() => {
  summaryItems.push(createRandomSummaryItem());
});

export default summaryItems;
