import React, {
  ReactElement, useMemo, useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Modal from '../Modal';
import { Button, TabNavigation } from '../..';
import {
  ContentModal, Divider, FooterButton,
} from './styles';
import GuarantorData from './GuarantorData';
import { TabElementProps } from '../../../@types/TabNavigation';
import GuarantorAddress from './GuarantorAddress';
import { BankData } from '../../CustomerData/CustomerDataSteps';
import { GuarantorSocial } from './GuarantorSocial';
import { GuarantorDataCore, INITIAL_VALUES } from '../GuarantorData.spec';
import { UploadDocument } from '../../UploadDocument';
import { CoreFile } from '../../../@types';
import { InfoMode } from '../../../@types/InfoMode';
import { YUP_SCHEMA } from './GuarantorPersonModal.spec';

const GuarantorPersonModal = ({
  onClose, onSubmit, initialValues, infoMode,
}: any): ReactElement => {
  const { t } = useTranslation();

  const formik = useFormik<GuarantorDataCore>({
    initialValues,
    validationSchema: YUP_SCHEMA,
    async onSubmit(values) {
      onSubmit(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  const tabNavigationItems = [
    {
      title: t('guarantorPersonModal.titleTabNavigationItems.guarantorData'),
      element: <GuarantorData formik={formik} />,
      enabled: true,
    },
    {
      title: t('guarantorPersonModal.titleTabNavigationItems.address'),
      element: <GuarantorAddress formik={formik} />,
      enabled: true,
    },
    {
      title: t('guarantorPersonModal.titleTabNavigationItems.documents'),
      element: <UploadDocument documents={formik.values.attachments} handleChange={(files: CoreFile[]) => formik.setFieldValue('attachments', files)} />,
      enabled: true,
    },
    {
      title: t('guarantorPersonModal.titleTabNavigationItems.bankData'),
      element: <BankData accountList={formik.values.accounts} setAccountList={(account) => formik.setFieldValue('accounts', account)} />,
      enabled: true,
    },
    {
      title: t('guarantorPersonModal.titleTabNavigationItems.social'),
      element: <GuarantorSocial formik={formik} />,
      enabled: true,
    },
  ] as TabElementProps;

  return (
    <Modal
      title={t('guarantorPersonModal.title') || 'Novo avalista'}
      onClose={onClose}
      maxWidth="900px"
    >
      <ContentModal onSubmit={formik.handleSubmit}>
        <TabNavigation
          tabNavigationItems={tabNavigationItems}
          type="avalista"
        />
        <FooterButton>
          <Button
            message={`${infoMode === InfoMode.CREATE ? 'Adicionar' : 'Editar'} avalista`}
            type="submit"
            onClick={() => formik.handleSubmit()}
          />
        </FooterButton>
        <Divider />
      </ContentModal>
    </Modal>
  );
};

export default GuarantorPersonModal;
