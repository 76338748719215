import React, { ReactElement } from 'react';
import { cnpjMask } from '../../../../../../utils/masks';
import InputCurrency from '../../../../../CurrencyInput';
import InputControl from '../../../../../Input';
import InputMask from '../../../../../MaskedInput';
import SelectField from '../../../../../Select';
import { InputControlRow } from '../../../../styles';
import { ProfessionFormProps } from '../PrimaryData.spec';

export function EntrepeneurForm({ formik }: ProfessionFormProps): ReactElement {
  const relationshipOptions = [{ id: 1, text: 'Sócio' }];
  return (
    <>
      <h2>Empresa em seu nome</h2>

      <InputControlRow>
        <InputMask
          name="companyDocumentNumber"
          label="CNPJ"
          placeholder="Digite o CNPJ"
          guide={false}
          mask={cnpjMask}
          value={formik.values.companyDocumentNumber}
          onChange={({ target: { value } }) => formik.setFieldValue(
            'companyDocumentNumber',
            value?.replace(/\D/g, ''),
          )}
        />
        <InputControl
          name="companyLegalName"
          label="Razão social"
          placeholder="Digite a razão social"
          value={formik.values.companyLegalName}
          onChange={formik.handleChange}
        />
      </InputControlRow>
      <InputControlRow>
        <SelectField
          name="companyRelationship"
          label="Tipo de vínculo"
          value={formik.values.companyRelationship}
          onChange={(selectedOption: any) => formik.setFieldValue('companyRelationship', selectedOption)}
          options={relationshipOptions}
          required
        />
        <InputCurrency
          name="companyPercent"
          label="Percentual"
          placeholder="Percentual"
          prefix="%"
          value={formik.values.companyPercent}
          onStringChange={(value) => formik.setFieldValue(
            'companyPercent',
            value?.replace(/\D/g, ''),
          )}
        />
      </InputControlRow>
    </>
  );
}
