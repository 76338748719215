import React, { ReactElement } from 'react';
import Checkbox from '../Checkbox';
import { RadioProps } from './Radio.spec';

import { Container, CheckboxControl } from './styles';

const Radio = ({
  label, checked, name, onChangeOne, onChangeTwo, readonly, required,
}: RadioProps): ReactElement => (
  <Container>
    <label>
      {label}
      {' '}
      {required && (
        <small>
          (obrigatório)
        </small>
      )}
      {readonly && (
        <small>
          (apenas um cônjuge por cliente)
        </small>
      )}

    </label>

    <CheckboxControl className={readonly ? 'readonly' : ''} readonly={readonly}>
      <Checkbox
        name={name}
        shape="circle"
        checked={checked}
        onChange={onChangeOne}
        disabled={checked || readonly}
      />
      Sim
      <Checkbox
        name={name}
        shape="circle"
        checked={!checked}
        onChange={onChangeTwo}
        disabled={!checked || readonly}
      />
      Não
    </CheckboxControl>

  </Container>
);

export default Radio;
