import React, { ReactElement, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import {
  Button,
  Input,
  InputCurrency,
  MaskedInput,
  Radio,
  Select,
} from '../../..';

import { Container, ContentForm, InputRow } from './styles';
import { cpfMask, phoneMask9 } from '../../../../utils/masks';
import InputDate from '../../../DateInput';
import { useListItem } from '../../../../hooks';
import { civilStatusService, professionService, sexService } from '../../../../services';
import { selectRelationship } from '../GuarantorPersonModal.spec';
import { GuarantorDataCore } from '../../GuarantorData.spec';
import { formatDate } from '../../../../utils/formatters';

interface GuarantorDataProps {
  formik: FormikProps<GuarantorDataCore>;
}

const GuarantorData = ({ formik }: GuarantorDataProps): ReactElement => {
  const { t } = useTranslation();
  const { item: selectCivilStatus } = useListItem(civilStatusService);
  const { item: selectProfession } = useListItem(professionService);
  const { item: selectSex } = useListItem(sexService);
  const person = formik.values;

  return (
    <Container>
      <ContentForm>
        <InputRow>
          <MaskedInput
            label={t('guarantorDataPerson.labelCPF')}
            name="documentNumber"
            mask={cpfMask}
            guide={false}
            value={person?.documentNumber}
            onChange={({ target: { value } }) => formik?.setFieldValue(
              'documentNumber',
              value?.replace(/\D/g, ''),
            )}
            placeholder="000.000.000-00"
            message={formik.errors.documentNumber}
            required
          />
          <Input
            name="fullName"
            label={t('guarantorDataPerson.labelName') || ''}
            value={person?.fullName}
            onChange={formik?.handleChange}
            message={formik.errors.fullName}
            placeholder={t('guarantorDataPerson.placeholderName') || ''}
            required
          />
        </InputRow>
        <InputRow>
          <InputDate
            name="person.birthdate"
            label={t('guarantorDataPerson.labelBirthdate')}
            required
            placeholderText="00/00/0000"
            selected={person.birthdate ? formatDate(person.birthdate) : null}
            message={formik.errors.birthdate}
            onChange={(value: any) => {
              formik.setFieldValue(
                'birthdate',
                value ? value.toISOString().split('T')[0] : '',
              );
            }}
          />
          <Select
            name="person.civilStatusID"
            label={t('guarantorDataPerson.labelCivilStatus')}
            options={selectCivilStatus}
            message={formik.errors.civilStatusID}
            value={
              person?.civilStatusID
                ? selectCivilStatus?.find(
                  (e: any) => e.id === Number(person?.civilStatusID),
                )
                : null
            }
            onChange={(selectedOption: any) => formik?.setFieldValue('civilStatusID', selectedOption.id)}
            required
            visibleProp="text"
          />
        </InputRow>
        <InputRow>
          <MaskedInput
            name="person.mobilePhoneNumber"
            label={t('guarantorDataPerson.labelPhoneNumber')}
            placeholder={t('guarantorDataPerson.placeholderPhoneNumber') || ''}
            mask={phoneMask9}
            guide={false}
            value={person?.mobilePhoneNumber}
            message={formik.errors.mobilePhoneNumber}
            onChange={({ target: { value } }) => formik?.setFieldValue(
              'mobilePhoneNumber',
              value?.replace(/\D/g, ''),
            )}
            required
          />
          <Input
            name="email"
            label={t('guarantorDataPerson.labelEmail') || ''}
            placeholder={t('guarantorDataPerson.placeholderEmail') || ''}
            type="email"
            value={person?.email}
            message={formik.errors.email}
            onChange={formik?.handleChange}
            required
          />
        </InputRow>
        <InputRow>
          <Select
            name="person.professionID"
            options={selectProfession}
            label={t('guarantorDataPerson.labelOccupation')}
            message={formik.errors.professionID}
            value={
              person?.professionID
                ? selectProfession?.find(
                  (e: any) => e.id === Number(person?.professionID),
                )
                : null
            }
            onChange={(selectedOption: any) => formik?.setFieldValue('professionID', selectedOption.id)}
            visibleProp="text"
            required
          />
          <InputCurrency
            name="income"
            label={t('guarantorDataPerson.labelIncome')}
            message={formik.errors.income}
            value={person?.income}
            onStringChange={(value, name) => {
              formik?.setFieldValue(name, value);
            }}
            required
          />
        </InputRow>
        <InputRow>
          <InputCurrency
            name="netWorth"
            label={t('guarantorDataPerson.labelNetWorth')}
            value={person?.netWorth}
            message={formik.errors.netWorth}
            onStringChange={(value, name) => {
              formik?.setFieldValue(name, value);
            }}
            required
          />
          <Select
            label={t('guarantorDataPerson.labelRelationshipType') || ''}
            name="relationshipType"
            options={selectRelationship}
            message={formik.errors.relationshipTypeID}
            value={selectRelationship.find((relationship) => relationship.id === Number(formik?.values.relationshipTypeID))}
            onChange={(selectedOption: any) => formik?.setFieldValue('relationshipTypeID', selectedOption.id)}
            visibleProp="text"
            required
          />
        </InputRow>
        <InputRow>
          <Select
            name="sexID"
            label={t('guarantorDataPerson.labelGender') || ''}
            message={formik.errors.sexID}
            options={selectSex}
            value={
              person?.sexID
                ? selectSex?.find(
                  (e: any) => e.id === Number(person?.sexID),
                )
                : null
            }
            onChange={(selectedOption: any) => formik?.setFieldValue('sexID', selectedOption.id)}
            visibleProp="text"
            required
          />
          <Radio
            name="hasCompany"
            label={t('guarantorDataPerson.labelHasCompany') || ''}
            checked={person?.hasCompany}
            onChangeOne={() => formik?.setFieldValue('hasCompany', true)}
            onChangeTwo={() => formik?.setFieldValue('hasCompany', false)}
          />
        </InputRow>
      </ContentForm>
    </Container>
  );
};

export default GuarantorData;
