import React from 'react';
import ReactDOM from 'react-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import { AppThemeProvider } from './contexts/ThemeContext';

ReactDOM.render(
  <React.StrictMode>
    <AppThemeProvider>
      <ProSidebarProvider>
        <App />
        <ToastContainer
          containerId="close-on"
          position="bottom-right"
          autoClose={7000}
          hideProgressBar
          closeOnClick
          pauseOnHover
          draggable
          className="toast-container"
          enableMultiContainer
        />
      </ProSidebarProvider>
    </AppThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
