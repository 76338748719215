import React, { useState } from 'react';
import { RiEyeCloseLine, RiEyeLine, RiArrowRightUpLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';

import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';

import Logo from '../../assets/LogoWLabel.svg';

import {
  Container,
  SignInForm,
  FormHeader,
  Form,
  FormTexts,
  InputControl,
  Support,
  Brand,
  KeepConnected,
  CheckBoxControl,
  ForgotPassword,
} from './styles';

const SignIn: React.FC = () => {
  const { Login } = useAuth();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  async function handleLogin(): Promise<void> {
    setTimeout(() => {
      setIsLoading(false);
      Login({
        email: 'rodrigo.petry@bravosul.com.br',
        password: '123456',
      });
    }, 2500);
  }

  return (
    <Container>
      <SignInForm>
        <FormHeader>
          <Brand>
            <img src={Logo} alt="Logo" />
          </Brand>
          <a href="/">
            {t('signIn.back')}
            <RiArrowRightUpLine />
          </a>
        </FormHeader>
        <FormTexts>
          <h1>{t('signIn.title')}</h1>
          <p>{t('signIn.subTitle')}</p>
        </FormTexts>

        <Form>
          <InputControl>
            <small>{t('signIn.email')}</small>
            <input type="email" placeholder={t('signIn.emailPlaceholder') ?? ''} />
          </InputControl>

          <InputControl>
            <small>{t('signIn.password')}</small>
            <input type={showPassword ? 'text' : 'password'} placeholder="*************" />
            <button type="button" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <RiEyeLine /> : <RiEyeCloseLine />}
            </button>
          </InputControl>

          <KeepConnected>
            <CheckBoxControl>
              <Checkbox />
              {t('signIn.keepConnected')}
            </CheckBoxControl>
            <ForgotPassword>
              <Link to="/forgot-password">
                {t('signIn.forgotPassword')}
              </Link>
            </ForgotPassword>
          </KeepConnected>

          <Button
            message={t('signIn.accessText')}
            type="button"
            onClick={() => {
              setIsLoading(true);
              handleLogin();
            }}
            isLoading={isLoading}
            loadingMessage={t('buttonLoadingMessage')}
          />
        </Form>

        <Support>
          <small>{t('signIn.supportText')}</small>
        </Support>
      </SignInForm>
    </Container>
  );
};

export default SignIn;
