import * as Yup from 'yup';
import { AccountDTO } from '../../../../@types/Account';

export function getInitialValues(account = {} as AccountDTO, personID: number): AccountDTO {
  return {
    enabled: account?.enabled || false,
    personID: account?.personID,
    documentNumber: account?.documentNumber,
    holderName: account?.holderName,
    accountTypeID: account?.accountTypeID,
    bankID: account?.bankID,
    agencyNumber: account?.agencyNumber,
    accountNumber: account?.accountNumber,
    accountNumberDigit: account?.accountNumberDigit,
  };
}

export const validationSchema = Yup.object().shape({
  relationshipTypeID: Yup.number().required('O campo "Tipo de vínculo" é obrigatório.'),
  person: Yup.object({
    fullName: Yup.string().required(),
  }),
});

export const ACCOUNT_YUP_SCHEMA = Yup.object<AccountCore>().shape({
  accountTypeID: Yup.number().required('O campo "Tipo de conta" é obrigatório.'),
  bank: Yup.string().required('O campo "Banco" é obrigatório.'),
  accountNumber: Yup.string().required('O campo "Número da conta" é obrigatório.'),
  agencyNumber: Yup.string().required('O campo "Agência sem dígito" é obrigatório.'),
  pixKeyTypeID: Yup.number().required('O campo "Tipo de chave" é obrigatório.'),
  pixKey: Yup.string().required('O campo "Chave PIX" é obrigatório.'),
});

export interface AccountCore {
  accountTypeID: string;
  bank: string;
  accountNumber: string;
  agencyNumber: string;
  pixKeyTypeID: string;
  pixKey: string;
  isPrimaryAccount?: boolean;
}

export const NEW_ACCOUNT_INITIAL_VALUES: AccountCore = {
  accountTypeID: '',
  bank: '',
  accountNumber: '',
  agencyNumber: '',
  pixKeyTypeID: '',
  pixKey: '',
  isPrimaryAccount: false,
};
