export const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
export const cnpjMask = [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
export const cepMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const phoneMask9 = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
export const phoneMask8 = ['+', /[1-9]/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
export const cpfMaskPlus = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

export function maskCurrency(value: string | undefined): string | undefined {
  return value ? value
    .replace(/\D/g, '')
    .replace(/(\d)(\d{2})$/, '$1,$2')
    .replace(/(?=(\d{3})+(\D))\B/g, '.') : '';
}

export function format(num: number): any {
  return num ? num.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '';
}

export function formatCNPJ(cnpj?: string): any {
  const formatedCnpj = cnpj?.replace(/\D/g, '');
  return formatedCnpj?.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5',
  );
}

export function formatCpfCnpj(value: string): string {
  const cleanedValue = value.replace(/\D/g, ''); // Remove all non-digit characters

  if (cleanedValue.length <= 11) {
    // CPF formatting
    const match = cleanedValue.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
    }
  } else {
    // CNPJ formatting
    const match = cleanedValue.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
    if (match) {
      return `${match[1]}.${match[2]}.${match[3]}/${match[4]}-${match[5]}`;
    }
  }
  return cleanedValue;
}
