import { CoreOnboarding } from '../../@types/OnboardingModal';

export interface OnboardingContextValue {
  prev: () => void;
  next: () => void;
  jump: (step: number) => void;
}

export const initialValues: CoreOnboarding = {
  name: '',
  cpf: '',
  birthDate: new Date(),
  maritalStatus: '',
  phone: '',
  email: '',
  profession: '',
  income: '',
  netWorth: '',
  ownCompany: false,
  cnpj: '',
  corporateName: '',
  address: {
    postalCode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    country: '',
  },
  proofOfResidence: null,
  documentWithPhoto: null,
  documentWithSelfie: null,
  proofOfIncome: null,
  officialDocumentOfLegalEntities: null,
  officialDocumentOfCompanyPartner: null,
  additionalDocuments: [],
  guarantors: [
    {
      id: '',
      name: '',
      cpf: '',
      civilStatus: '',
      phone: '',
      birthDate: new Date(),
      email: '',
      occupation: '',
      income: '',
      netWorth: '',
      spouse: false,
    },
  ],
};
