import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import moment, { Moment } from 'moment';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  RiInformationLine,
  RiAlertFill,
  RiCloseCircleFill,
} from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import 'moment/locale/pt-br';

import dayjs from 'dayjs';
import TabNavigation from './TabNavigation';

import { productService } from '../../../services';

import { formatDate, getDaysDifference } from '../../../utils';

import { OperationInputContext } from '../../../routes/AuthenticatedRoutes';

import { ProductSDTO } from '../../../@types/Product';

import {
  Input as InputControl,
  Loading,
  Button,
  Modal,
  Checkbox,
  Tooltip,
  InputCurrency,
  SearchableSelect,
} from '../..';

import {
  Container,
  Form,
  InputControlRow,
  Result,
  ResultTitle,
  ButtonsGroup,
  CheckBoxControl,
  DatePickerController,
} from './styles';

import {
  validationSchema,
  simulatorInitialValues,
  SimulatorModalProps,
  CustomerTypeTypes,
  SimulatorInitialValuesProps,
} from './SimulatorModal.spec';
import InputDate from '../../DateInput';

const SimulatorModal = ({ onClose }: SimulatorModalProps): ReactElement => {
  const { t } = useTranslation();
  const routerNavigate = useNavigate();
  const {
    selectedProduct,
    setSelectedProduct,
    selectedCustomerType,
    setSelectedCustomerType,
    setSimulateValue,
  } = useContext(OperationInputContext);
  const [result, setResult] = useState<any | null>();
  const [isLoadingSimulate, setIsLoadingSimulate] = useState<boolean>(false);
  const [isRedirectLoading, setIsRedirectLoading] = useState<boolean>(false);
  const [cachedInitialValues, setCachedInitialValues] = useState(
    simulatorInitialValues(),
  );
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<ProductSDTO[]>([]);

  useEffect((): void => {
    getProducts();
  }, [selectedCustomerType]);

  function handleOperationStart(): void {
    setIsRedirectLoading(true);
    setTimeout(() => {
      if (selectedProduct) {
        setIsRedirectLoading(false);
        routerNavigate('/operations');
      }
    }, 1000);
  }

  async function simulate(
    formikValues: SimulatorInitialValuesProps,
  ): Promise<void> {
    setIsLoadingSimulate(true);
    try {
      const { productID, simplifiedTax, ...dto } = formikValues;
      if (productID) {
        const simulateData = await productService.simulate(productID, {
          customerType: selectedCustomerType,
          simplifiedTax:
            selectedCustomerType === 'COMPANY'
              ? formikValues?.simplifiedTax
              : false,
          ...dto,
        });
        setResult(simulateData);
        setSimulateValue({ result: simulateData, insertedValue: formikValues });
        setCachedInitialValues(formikValues);
      }
    } catch {
      toast.warning('Parametrização inexistente para este produto.', {
        className: 'toast-message',
        icon: RiAlertFill,
        containerId: 'close-on',
      });
      setResult(null);
    } finally {
      setIsLoadingSimulate(false);
    }
  }

  const getProducts = useCallback(async () => {
    setIsLoadingSimulate(true);
    try {
      if (selectedCustomerType) {
        setSelectedProduct(null);
        const productsResponse = (
          await productService.getProductByCustomerType(selectedCustomerType)
        )?.content;
        setProducts(productsResponse);
      }
    } catch {
      toast.error(
        'Não foi possível listar os produtos para esse tipo de cliente.',
        {
          className: 'toast-message',
          icon: RiCloseCircleFill,
          containerId: 'close-on',
        },
      );
    } finally {
      setIsLoadingSimulate(false);
    }
  }, [selectedCustomerType]);

  return (
    <Modal
      title={t('simulatorModal.title')}
      onClose={onClose}
      hasPrev={!!result}
      prevClick={() => setResult(null)}
    >
      <Container>
        {!result && (
        <TabNavigation
          customerType={selectedCustomerType}
          changeCustomerType={(customerProps: CustomerTypeTypes) => {
            setSelectedCustomerType(customerProps);
          }}
        />
        )}
        <Formik
          enableReinitialize
          initialValues={cachedInitialValues}
          validationSchema={validationSchema}
          onSubmit={(formikValues: SimulatorInitialValuesProps) => simulate(formikValues)}
        >
          {(formikProps) => (
            <Form onSubmit={formikProps.handleSubmit}>
              <InputControlRow>
                <SearchableSelect
                  options={products}
                  label="Produto"
                  onChange={(product: any) => {
                    setSelectedProduct(product);
                    formikProps.setFieldValue('productID', product?.id);
                  }}
                  selectedProduct={selectedProduct}
                />
                <InputCurrency
                  name="value"
                  label="Valor solicitado"
                  value={formikProps.values.value}
                  decimalsLimit={2}
                  onStringChange={(value, name) => {
                    formikProps.setFieldValue(name, value);
                    if (+value > 30000.0) {
                      formikProps.setFieldValue('simplifiedTax', false);
                    }
                  }}
                  required
                />
              </InputControlRow>

              <InputControlRow>
                <InputControl
                  name="installmentQuantity"
                  type="number"
                  label={
                    t('simulatorModal.labelInputNumberOfInstallments')
                    || 'Número de parcelas'
                  }
                  value={formikProps.values.installmentQuantity || ''}
                  onChange={formikProps.handleChange}
                  placeholder="1"
                  min={1}
                  required
                />
                <DatePickerController>
                  <InputDate
                    label="1º Vencimento"
                    name="firstInstallmentDueDate"
                    onChange={(newDate) => {
                      formikProps.setFieldValue(
                        'firstInstallmentDueDate',
                        newDate,
                      );
                    }}
                    selected={formikProps.values.firstInstallmentDueDate}
                    required
                    disabledDate={(value: any) => value < moment(formikProps.initialValues.firstInstallmentDueDate)}
                  />
                </DatePickerController>
              </InputControlRow>

              {selectedCustomerType === 'COMPANY' && (
              <CheckBoxControl disabled={+formikProps.values.value > 30000.0}>
                <Checkbox
                  name="simplifiedTax"
                  checked={formikProps.values.simplifiedTax}
                  onChange={(e) => {
                    formikProps.setFieldValue(
                      'simplifiedTax',
                      !formikProps.values.simplifiedTax,
                    );
                  }}
                  disabled={+formikProps.values.value > 30000.0}
                />
                É optante ao Simples Nacional
                <Tooltip
                  text="As operações com essa opção marcada deverão obrigatoriamente respeitar o valor solicitado igual ou inferior a R$ 30.000,00."
                  tooltip={<RiInformationLine />}
                />
              </CheckBoxControl>
              )}

              {!result && (
              <Button
                message={t('simulatorModal.buttonSimulate') || 'Simular'}
                type="submit"
                disabled={isLoadingSimulate || !formikProps?.isValid}
                isLoading={isLoadingSimulate}
                loadingMessage="Simulando..."
              />
              )}

              {result && (
              <Result>
                <ResultTitle>
                  <h3>Resultado obtido:</h3>
                </ResultTitle>
                <InputControlRow>
                  <InputControl
                    name="result.numberOfDaysDueDate"
                    label={
                        t('simulatorModal.labelInputNumberOfDays')
                        || 'Número de dias para o 1º vencimento'
                      }
                    value={getDaysDifference(result?.firstInstallmentDueDate)}
                    disabled
                  />

                  <InputDate
                    name="result.lastInstallmentDueDate"
                    label={
                        t('simulatorModal.lastInstallmentDueDate')
                        || 'Último vencimento'
                      }
                    selected={result?.lastInstallmentDueDate}
                    disabled
                  />
                </InputControlRow>
                <InputControlRow>
                  <InputCurrency
                    name="result.installmentValue"
                    placeholder="R$ 0,00"
                    prefix="R$ "
                    decimalSeparator=","
                    groupSeparator="."
                    decimalsLimit={2}
                    label="Valor aproximado da parcela"
                    value={result?.installmentValue || ''}
                    readOnly
                    required
                  />
                </InputControlRow>
                <ButtonsGroup>
                  <Button
                    type="submit"
                    variant="secondary"
                    message="Refazer simulação"
                    isLoading={isLoadingSimulate}
                    loadingMessage="Refazendo..."
                    disabled={isLoadingSimulate || !formikProps?.isValid}
                  />
                  <Button
                    type="button"
                    variant="primary"
                    message="Continuar com os dados acima"
                    isLoading={isRedirectLoading}
                    loadingMessage="Redirecionando..."
                    onClick={() => handleOperationStart()}
                    disabled={
                        isRedirectLoading
                        || !formikProps?.isValid
                        || formikProps?.dirty
                      }
                  />
                </ButtonsGroup>
              </Result>
              )}
            </Form>
          )}
        </Formik>
      </Container>
      {isLoadingSimulate && <Loading />}
    </Modal>
  );
};

export default SimulatorModal;
