import * as Yup from 'yup';
import { CoreFile } from '../../../../@types';
import { AccountCore } from '../BankData/BankData.spec';
import { CustomerDataCore } from '../../CustomerData.spec';

export const YUP_SCHEMA_PERSON = Yup.object<CustomerDataCore>().shape({
  // PRIMARY DATA
  fullName: Yup.string().required('"Nome completo" é um campo obrigatório'),
  documentNumber: Yup.string().required('"CPF" é um campo obrigatório'),
  birthdate: Yup.string().required('"Data de nascimento" é um campo obrigatório'),
  civilStatusID: Yup.number().required('"Estado civil" é um campo obrigatório'),
  mobilePhoneNumber: Yup.string().required('"Celular" é um campo obrigatório'),
  email: Yup.string().required('"E-mail" é um campo obrigatório'),
  professionID: Yup.number().required('"Profissão" é um campo obrigatório'),
  income: Yup.number().min(1, '"Renda" é um campo obrigatório'),
  netWorth: Yup.number().min(1, '"Patrimônio Líquido" é um campo obrigatório'),
  sexID: Yup.number().required('"Sexo" é um campo obrigatório'),

  // ADDRESS
  postalCode: Yup.string().required('"CEP" é um campo obrigatório'),
  line1: Yup.string().required('"Endereço" é um campo obrigatório'),
  houseNumber: Yup.string().required('"Número" é um campo obrigatório'),
  neighborhood: Yup.string().required('"Bairro" é um campo obrigatório'),
  city: Yup.string().required('"Cidade" é um campo obrigatório'),
  state: Yup.string().required('"Estado" é um campo obrigatório'),
  countryID: Yup.number().required('"País" é um campo obrigatório'),
});
