import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  gap: 24px;
`;

export const BoxData = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 12px;

    border-radius: 4px;
    background-color: ${theme.colors.black05};

    :nth-child(2n) {
      background-color: ${theme.colors.white00};
    }

    > h4 {
      ${theme.texts.headingXXXS};
      width: 100%;
      max-width: 100px;
      margin-right: 24px;

      color: ${theme.colors.black00};
    }

    > p {
      ${theme.texts.paragraphXS};

      color: ${theme.colors.black50};
    }
  `}
`;

export const CustomerData = styled.section`
  ${({ theme }) => css`
    flex: 1;

    > h2 {
      ${theme.texts.headingXXS};
      margin-bottom: 24px;
    }
  `}
`;

export const ProductData = styled.section`
  ${({ theme }) => css`
    flex: 1;

    > h2 {
      ${theme.texts.headingXXS};
      margin-bottom: 24px;
    }
  `}
`;

export const Documents = styled.section`
  ${({ theme }) => css`
    width: 100%;
    margin-top: 24px;

    > h2 {
      ${theme.texts.headingXXS};
      margin-bottom: 24px;
    }
  `}
`;

export const BoxDocument = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  `}
`;

export const ItemDocument = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 10px 12px;
    margin: 0 14px 14px 0;
    cursor: pointer;

    ${theme.texts.paragraphXS};

    border-radius: 4px;
    background-color: ${theme.colors.success10};
    > svg {
      font-size: 1.4rem;
      margin-right: 10px;
    }
  `}
`;

export const Observation = styled.section`
  ${({ theme }) => css`
    width: 100%;
    margin: 24px 0;

    > h2 {
      ${theme.texts.headingXXS};
      margin-bottom: 24px;
    }

    > textarea {
      width: 100%;
      max-width: 100%;

      background-color: ${theme.colors.black05};
      border-color: ${theme.colors.black05};
      border-radius: 4px;
      padding: 15px 20px;

      font-family: inherit;
      resize: none;

      ${theme.texts.paragraphXS};
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.black10};

    position: absolute;
    left: 0;
    bottom: 90px;
  `};
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto 0 0 0;
    padding: 24px 0 0 0;
    border-top: 1px solid ${theme.colors.black10};
  `};
`;

export const StepsNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > small {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
    }
  `};
`;

export const StepsList = styled.div`
  ${({ theme }) => css`
    display: flex;

    margin: 0 0 0 2px;

    > span {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black00};
      padding: 3.5px 12px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:not(:last-child) {
        border-right: 0.5px solid ${theme.colors.black10};
      }

      > svg {
        font-size: 1.4rem;
        color: ${theme.colors.success00};
      }

      &.active {
        color: ${theme.colors.black00};
      }
    }
  `};
`;

export const FooterButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      height: 42px;

      &:first-child {
        background-color: transparent;
        margin: 0 6px 0 0;
        padding: 10px 20px;
        ${theme.texts.buttonXS};
      }
    }
  `};
`;
