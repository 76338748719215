import { useFormik } from 'formik';
import React, { ReactElement, useContext, useState } from 'react';

import { RiAddFill } from 'react-icons/ri';

import {
  Button, OperationStepFormBase,
} from '..';

import HandshakeIcon from '../../assets/HandshakeIcon.svg';
import { initialValues } from './VehicleWarranty.spec';

import { OperationStepsContext } from '../../pages/Operations';

import {
  BoxLeft,
  BoxRight,
  Container,
  HeaderBox,
  NoContent,
} from './styles';

import FormVehicleWarranty from './FormVehicleWarranty';

import { CoreVehicleWarranty } from '../../@types/VehicleWarranty';
import { OutletContent } from '../../pages/Operations/styles';
import DataCardsList from '../DataCardsList';

const VehicleWarranty = (): ReactElement => {
  const { currentStepData, handleStepSubmission } = useContext(OperationStepsContext);
  const [openedWarrantyID, setOpenedWarrantyID] = useState<string>('');

  const formik = useFormik({
    initialValues: currentStepData?.inputData ?? initialValues,
    onSubmit(values: any) {
      console.log(values);
      handleStepSubmission(values);
    },
  });

  const removeWarranties = (item: CoreVehicleWarranty) => () => {
    formik.setFieldValue(
      'collateralVehicles',
      collateralVehicles?.filter((e: any) => e !== item),
    );
  };

  const duplicate = (index: number): void => {
    const newItem = formik.values.collateralVehicles[index];
    formik.setFieldValue('collateralVehicles', [
      ...formik.values.collateralVehicles,
      {
        ...newItem,
        id: crypto.randomUUID(),
        model: {
          text: `${formik.values.collateralVehicles[index]?.model?.text} (Cópia)`,
        },
      },
    ]);
  };

  const newWarranty = (): void => {
    const id = crypto.randomUUID();

    formik.setFieldValue('collateralVehicles', [
      {
        id,
        model: {
          name: `Modelo do veículo ${collateralVehicles.length + 2}`,
        },
      },
      ...formik.values.collateralVehicles,
    ]);

    setOpenedWarrantyID(id);
  };

  const { collateralVehicles } = formik.values as any;
  return (
    <OperationStepFormBase handleSubmit={formik.handleSubmit}>
      <OutletContent>
        <Container>
          <HeaderBox>
            <BoxLeft>
              <h2>Lista de garantias</h2>
              <p>
                <span>{formik.values.collateralVehicles?.length || 0}</span>
                {' '}
                garantia(s)
                encontrada(s).
                {' '}
              </p>
            </BoxLeft>
            <BoxRight>
              <Button
                type="button"
                message="Nova garantia"
                icon={<RiAddFill />}
                onClick={newWarranty}
              />
            </BoxRight>
          </HeaderBox>
          {collateralVehicles.length > 0 ? (
            <>
              {collateralVehicles?.map((warranty: any, index: number) => (
                <DataCardsList
                  item={warranty}
                  key={warranty.id}
                  onRemove={removeWarranties(warranty)}
                  onDuplicate={() => duplicate(index)}
                  open={warranty.id === openedWarrantyID}
                  onOpen={() => setOpenedWarrantyID(warranty.id)}
                  onClose={() => setOpenedWarrantyID('')}
                  type="VehicleWarranty"
                  visibleValue={warranty.name}
                  title={warranty.model?.text || 'Nome do Modelo do veículo'}
                  subtitle={warranty?.licensePlate || 'Placa do veículo'}
                  onSubmit={() => setOpenedWarrantyID('')}
                >
                  <FormVehicleWarranty
                    index={index}
                    vehicleWarranty={warranty}
                    formik={formik}
                  />
                </DataCardsList>
              ))}
            </>
          ) : (
            <NoContent>
              <img src={HandshakeIcon} alt="Handshake Icon" />
              <h2>Nenhuma garantia encontrada</h2>
              <p>Esta tabela não possui garantias a serem exibidas.</p>
            </NoContent>
          )}
        </Container>
      </OutletContent>
      {/* {isLoading && <Loading />} */}
    </OperationStepFormBase>
  );
};

export default VehicleWarranty;
