import React, { createContext, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Users from '../pages/Registration/Users';

import DashboardLayout from '../layouts/DashboardLayout';
import Customers from '../pages/Registration/Customers';
import UserInfo from '../pages/Registration/Users/UserInfo';
import { InfoMode } from '../@types/InfoMode';
import CustomerInfo from '../pages/Registration/Customers/CustomerInfo';
import Operations from '../pages/Operations';

interface OperationInputContextValue {
  selectedProduct: string | any;
  setSelectedProduct: React.Dispatch<string | any>;
  selectedCustomerType: 'PERSON' | 'COMPANY';
  setSelectedCustomerType: React.Dispatch<string | any>;
  simulateValue: any;
  setSimulateValue: React.Dispatch<any>;
}

export const OperationInputContext = createContext(
  {} as OperationInputContextValue,
);

const AuthenticatedRoutes: React.FC = () => {
  const [selectedProduct, setSelectedProduct] = useState<string | any>('');
  const [selectedCustomerType, setSelectedCustomerType] = useState<'PERSON' | 'COMPANY'>('PERSON');
  const [simulateValue, setSimulateValue] = useState<any>();

  return (
    <OperationInputContext.Provider
      value={{
        selectedProduct,
        setSelectedProduct,
        selectedCustomerType,
        setSelectedCustomerType,
        simulateValue,
        setSimulateValue,
      }}
    >
      <Routes>
        <Route
          path=""
          element={(
            <DashboardLayout>
              <Home />
            </DashboardLayout>
          )}
        />
        <Route path="operations">
          <Route
            index
            element={(
              <DashboardLayout>
                <Operations />
              </DashboardLayout>
            )}
          />
        </Route>

        <Route path="registration">
          <Route
            index
            element={(
              <DashboardLayout>
                <Users />
              </DashboardLayout>
            )}
          />
          {/* Registration -> Users routes */}
          <Route path="users">
            <Route
              path=""
              element={(
                <DashboardLayout>
                  <Users />
                </DashboardLayout>
              )}
            />
            <Route
              path="view/:id"
              element={(
                <DashboardLayout>
                  <UserInfo mode={InfoMode.VIEW} />
                </DashboardLayout>
              )}
            />
            <Route
              path="create"
              element={(
                <DashboardLayout>
                  <UserInfo mode={InfoMode.CREATE} />
                </DashboardLayout>
              )}
            />
            <Route
              path="edit/:id"
              element={(
                <DashboardLayout>
                  <UserInfo mode={InfoMode.EDIT} />
                </DashboardLayout>
              )}
            />
          </Route>

          {/* Registration -> Customers routes */}
          <Route path="customers">
            <Route
              path=""
              element={(
                <DashboardLayout>
                  <Customers />
                </DashboardLayout>
              )}
            />
            <Route
              path="view/:id"
              element={(
                <DashboardLayout>
                  <CustomerInfo mode={InfoMode.VIEW} />
                </DashboardLayout>
              )}
            />
            <Route
              path="create"
              element={(
                <DashboardLayout>
                  <CustomerInfo mode={InfoMode.CREATE} />
                </DashboardLayout>
              )}
            />
            <Route
              path="edit/:id"
              element={(
                <DashboardLayout>
                  <CustomerInfo mode={InfoMode.EDIT} />
                </DashboardLayout>
              )}
            />
          </Route>
        </Route>
      </Routes>
    </OperationInputContext.Provider>
  );
};

export default AuthenticatedRoutes;
