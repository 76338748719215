import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.black05};
    min-width: 204px;
    flex: 1;
    width: 100%;
    height: 108px;
    border-radius: 4px;
    padding: 18px;

    display: flex;
    flex-direction: column;
  `};
`;

export const CardHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: ${theme.colors.black00};
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2rem;

    > h3 {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  `};
`;

export const CardContent = styled.div`
  ${({ theme }) => css`
    margin-top: auto;
    color: ${theme.colors.black00};
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-weight: 400;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg {
      font-size: 2.4rem;
    }
  `};
`;
