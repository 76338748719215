import { faker } from '@faker-js/faker';
import { DataPreReviewCore } from '../@types';

export function createRandomPreReview(): DataPreReviewCore {
  return {
    name: faker.name.firstName(),
    cpf: faker.helpers.fake('000.000.000-00'),
    birthDate: faker.helpers.fake('01/02/2000'),
    phone: faker.phone.number(),
    email: faker.internet.email(),
    corporateName: faker.company.name(),
    cpnj: faker.helpers.fake('00.000.000/0000-00'),
    status: true,
  };
}

const dataPreReviewModal = createRandomPreReview();

export default dataPreReviewModal;
