import lightTheme from './lightTheme';

const darkTheme: typeof lightTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
  },
};

export default darkTheme;
