import React, { ReactElement } from 'react';

import { useFormikContext } from 'formik';
import { cpfMask, phoneMask9 } from '../../../utils/masks';

import {
  DateInput as InputDate,
  Input as InputControl,
  MaskedInput as InputMask,
  QueryInput,
} from '../..';

import { DataPreReviewPersonProps } from './DataPreReview.spec';

import { InputControlRow } from './styles';

const PhysicalPerson = ({
  parentFormik,
}: DataPreReviewPersonProps): ReactElement => (

  <>
    {/* {JSON.stringify(parentFormik)} */}
    <InputControlRow>
      <QueryInput
        name="documentNumber"
        label="CPF"
        placeholder="000.000.000-00"
        mask={cpfMask}
        guide={false}
        value={parentFormik.values.documentNumber}
        message={parentFormik.errors.documentNumber}
        onChange={({ target: { value } }) => parentFormik.setFieldValue('documentNumber', value?.replace(/\D/g, ''))}
        required
      />
      <InputControl
        name="fullName"
        label="Nome completo"
        placeholder="Digite o nome completo"
        required
        value={parentFormik.values.fullName}
        onChange={parentFormik.handleChange}
        message={parentFormik.errors.fullName}
      />
    </InputControlRow>
    <InputControlRow>
      {/* <InputDate
        name="birthdate"
        label="Data de nascimento"
        onChange={(value) => {
          parentFormik.setFieldValue('birthDate', value);
        }}
        required
      /> */}
    </InputControlRow>
    <InputControlRow>
      <InputMask
        name="mobilePhoneNumber"
        label="Celular"
        placeholder="(99) 9 9999 9999"
        mask={phoneMask9}
        value={parentFormik.values.mobilePhoneNumber}
        message={parentFormik.errors.mobilePhoneNumber}
        guide={false}
        required
        onChange={({ target: { value } }) => parentFormik.setFieldValue('mobilePhoneNumber', value?.replace(/\D/g, ''))}
      />
      <InputControl
        name="email"
        type="email"
        label="E-mail"
        placeholder="seuemail@exemplo.com"
        value={parentFormik.values.email}
        message={parentFormik.errors.email}
        required
        onChange={parentFormik.handleChange}
      />
    </InputControlRow>
  </>
);

export default PhysicalPerson;
