// import { SocialLink } from '../../@types/Socials';

export const socialsTypes = [
  {
    id: 1,
    name: 'Site',
    username: '',
  },
  {
    id: 2,
    name: 'Facebook',
    username: '',
    url: 'https://www.facebook.com/',
  },
  {
    id: 3,
    name: 'Instagram',
    username: '',
    url: 'https://www.instagram.com/',
  },
  {
    id: 4,
    name: 'LinkedIn pessoal',
    username: '',
    url: 'https://www.linkedin.com/in/',
  },
  {
    id: 5,
    name: 'LinkedIn empresarial',
    username: '',
    url: 'https://www.linkedin.com/company/',
  },
  {
    id: 6,
    name: 'Twitter',
    username: '',
    url: 'https://twitter.com/',
  },
  {
    id: 7,
    name: 'Pinterest',
    username: '',
    url: 'https://br.pinterest.com/',
  },
  {
    id: 8,
    name: 'Outro',
    username: '',
  },
];

export interface SocialsOptionsProps {
  registeredSocials: any[];
  handleClick: (social: any) => void;
}
