import * as Yup from 'yup';
import { formatDate } from '../../utils/formatters';

export interface OperationDataProps {
  onClose?: () => void;
}

export interface OperationDataFormFields {
  value?: string;
  installmentQuantity: string;
  firstInstallmentDueDate: string | undefined;
  lastInstallmentDueDate: string | undefined;
  valueTAC: string;
  operationFee: string;
  iofPerDay: string;
  iofFee: string;
  maximumInstallment: string;
  installmentValue: string;
  inPersonSale: boolean;
  financeCreditLifeInsurance: boolean;
  financeIOF: boolean;
  financeTAC: boolean;
  downPayment: string;
  creditLifeInsurance: string;
  creditLifeInsurancePct: string;
}

export const INITIAL_VALUES: OperationDataFormFields = {
  value: '',
  installmentQuantity: '',
  firstInstallmentDueDate: '',
  lastInstallmentDueDate: '',
  valueTAC: '',
  operationFee: '',
  iofPerDay: '',
  iofFee: '',
  maximumInstallment: '',
  inPersonSale: false,
  installmentValue: '',
  financeCreditLifeInsurance: false,
  financeIOF: false,
  financeTAC: false,
  downPayment: '',
  creditLifeInsurance: '',
  creditLifeInsurancePct: '0.02',
};

export const YUP_SCHEMA = Yup.object<OperationDataFormFields>().shape({
  value: Yup.string().required('"Valor solicitado" é um campo obrigatório'),
  installmentQuantity: Yup.number().required('"Número de parcelas" é um campo obrigatório'),
  firstInstallmentDueDate: Yup.string().required('"Data da primeira parcela" é um campo obrigatório'),
});
