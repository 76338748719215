import { CoreDTO, FindOptions, Page } from '../@types/Core';
import api from './api';

export abstract class CoreService<E extends CoreDTO, I extends CoreDTO> {
  abstract baseURL: string;

  async getPage(findOptions?: FindOptions): Promise<Page<I>> {
    return (await api.get(this.baseURL, {
      params: findOptions,
    }))?.data;
  }

  async getList(): Promise<Array<I>> {
    return (await api.get(`${this.baseURL}/list`))?.data;
  }

  async getOne(id: E['id']): Promise<E> {
    return (await api.get(`${this.baseURL}/${id}`))?.data;
  }

  async create(dto: E): Promise<E> {
    return (await api.post(this.baseURL, dto))?.data;
  }

  async update(id: E['id'], dto: E): Promise<E> {
    return (await api.put(`${this.baseURL}/${id}`, dto))?.data;
  }

  async delete(id: E['id']): Promise<E> {
    return (await api.delete(`${this.baseURL}/${id}`))?.data;
  }
}
