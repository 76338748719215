import React, { ReactElement } from 'react';
import { RiArrowLeftLine } from 'react-icons/ri';

import { Breadcrumb, Navbar } from '..';

import { HeaderProps } from './header.spec';

import { Container, PrevButton } from './styles';

const Header = ({
  breadcrumbItems,
  hasPrev,
  title,
  handlePrev,
}: HeaderProps): ReactElement => (
  <Container>
    <h1>
      <Breadcrumb breadCrumbItems={breadcrumbItems} />
      <span>
        {hasPrev && (
          <PrevButton onClick={handlePrev}>
            <RiArrowLeftLine />
          </PrevButton>
        )}
        {title}
      </span>
    </h1>
    <Navbar />
  </Container>
);

export default Header;
