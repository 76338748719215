import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import {
  Input, MaskedInput, Select,
} from '../../..';

import { Container, ContentForm, InputRow } from './styles';
import { cepMask } from '../../../../utils/masks';
import { useListItem } from '../../../../hooks';
import { level1AdminDivService, level2AdminDivService } from '../../../../services';
import { GuarantorDataCore } from '../../GuarantorData.spec';

interface GuarantorAddressProps {
  formik: FormikProps<GuarantorDataCore>;
}

const GuarantorAddress = ({ formik }: GuarantorAddressProps): ReactElement => {
  const { t } = useTranslation();
  const { item: selectLevel2AdminDiv } = useListItem(level2AdminDivService);
  const { item: selectLevel1AdminDiv } = useListItem(level1AdminDivService);
  const selectCountry = [{
    id: 1,
    text: 'Brasil',
  }];

  const person = formik.values;

  return (
    <Container>
      <ContentForm>
        <InputRow>
          <MaskedInput
            name="postalCode"
            label={t('guarantorAddress.labelPostalCode')}
            placeholder={t('guarantorAddress.placeholderPostalCode') || ''}
            mask={cepMask}
            guide={false}
            value={person?.postalCode}
            onChange={({ target: { value } }) => formik?.setFieldValue(
              'postalCode',
              value?.replace(/\D/g, ''),
            )}
            message={formik.errors.postalCode}
            required
          />
          <Input
            name="line1"
            label={t('guarantorAddress.labelAddress') || ''}
            placeholder={t('guarantorAddress.placeholderAddress') || ''}
            value={person?.line1}
            onChange={formik.handleChange}
            message={formik.errors.line1}
            required
          />
        </InputRow>
        <InputRow>
          <Input
            name="houseNumber"
            label={t('guarantorAddress.labelHouseNumber') || ''}
            placeholder={t('guarantorAddress.placeholderHouseNumber') || ''}
            value={person?.houseNumber}
            onChange={formik.handleChange}
            message={formik.errors.houseNumber}
            type="number"
            required
          />
          <Input
            placeholder={t('guarantorAddress.placeholderComplement') || ''}
            name="complement"
            label={t('guarantorAddress.labelComplement') || ''}
            value={person?.complement}
            message={formik.errors.complement}
            onChange={formik?.handleChange}
          />
        </InputRow>
        <InputRow>
          <Input
            name="neighborhood"
            label={t('guarantorAddress.labelNeighborhood') || ''}
            placeholder={t('guarantorAddress.placeholderNeighborhood') || ''}
            value={person?.neighborhood}
            message={formik.errors.neighborhood}
            onChange={formik?.handleChange}
            required
          />
          <Select
            label={t('billingAddressBlock.labelCity') || ''}
            name="level2AdminDivID"
            options={selectLevel2AdminDiv}
            message={formik.errors.level2AdminDivID}
            value={
              person?.level2AdminDivID
                ? selectLevel2AdminDiv?.find(
                  (e) => e.id === Number(person?.level2AdminDivID),
                )
                : null
            }
            onChange={(selectedOption: any) => formik?.setFieldValue(
              'level2AdminDivID',
              selectedOption.id,
            )}
            visibleProp="name"
            required
          />
        </InputRow>
        <InputRow>
          <Select
            label={t('billingAddressBlock.labelState') || ''}
            name="level1AdminDivID"
            options={selectLevel1AdminDiv}
            message={formik.errors.level1AdminDivID}
            value={
              person?.level1AdminDivID
                ? selectLevel1AdminDiv?.find(
                  (e) => e.id === Number(person?.level1AdminDivID),
                )
                : null
            }
            onChange={(selectedOption: any) => formik?.setFieldValue(
              'level1AdminDivID',
              selectedOption.id,
            )}
            visibleProp="name"
            required
          />
          <Select
            label={t('billingAddressBlock.labelCountry') || ''}
            name="countryID"
            options={selectCountry}
            message={formik.errors.countryID}
            value={
              person?.countryID
                ? selectCountry?.find(
                  (e) => e.id === Number(person?.countryID),
                )
                : null
            }
            onChange={(selectedOption: any) => formik?.setFieldValue(
              'countryID',
              selectedOption.id,
            )}
            visibleProp="text"
            required
          />
        </InputRow>
      </ContentForm>
    </Container>
  );
};

export default GuarantorAddress;
