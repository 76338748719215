import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { RiDeleteBin5Line, RiEditLine, RiUserSearchLine } from 'react-icons/ri';
import OperationStepFormBase from '../OperationStepFormBase';
import Button from '../Button';
import {
  Header, BoxLeft, BoxRight, Container, GuarantorCard, GuarantorInfo, GuarantorActions, GuarantorsTable, TableHeader, NoContent,
} from './styles';

import GuarantorPersonModal from './GuarantorPersonModal';
import {
  GuarantorDataCore, GuarantorType, INITIAL_VALUES, getRelationshipByCustomerType, removeEmptyFields,
} from './GuarantorData.spec';
import { OperationStepsContext } from '../../pages/Operations';
import { InfoMode } from '../../@types/InfoMode';
import GuarantorCompanyModal from './GuarantorCompanyModal';
import { selectRelationshipCompany } from './GuarantorCompanyModal/GuarantorData';
import { formatCpfCnpj } from '../../utils/masks';
import HandshakeIcon from '../../assets/HandshakeIcon.svg';
import { TypeOfGuarantorSelect } from './TypeOfGuarantorSelect';

function GuarantorData(): ReactElement {
  const [showTypeOfGuarantorSelect, setShowTypeOfGuarantorSelect] = useState<boolean>(false);
  const [guarantorType, setGuarantorType] = useState<'PERSON' | 'COMPANY' | null>(null);
  const { handleStepSubmission, currentStepData, valuePreviousStep } = useContext(OperationStepsContext);
  const [guarantors, setGuarantors] = useState<GuarantorDataCore[]>([]);
  const [infoMode, setInfoMode] = useState<InfoMode>(InfoMode.CREATE);
  const [currentEditIndex, setCurrentEditIndex] = useState<number>();
  const [initialValues, setInitialValues] = useState<GuarantorDataCore>(INITIAL_VALUES);

  useEffect(() => {
    setGuarantors((valuePreviousStep as any)?.guarantors ?? (currentStepData as any)?.guarantors ?? []);
  }, []);

  const handleSelectGuarantorType = (option: GuarantorType): void => {
    setGuarantorType(option);
    setShowTypeOfGuarantorSelect(false);
  };

  const reset = (): void => {
    setGuarantorType(null);
    setInitialValues(INITIAL_VALUES);
    setCurrentEditIndex(undefined);
    setInfoMode(InfoMode.CREATE);
  };

  const handleCloseModal = (): void => {
    reset();
  };

  const handleEditModal = (index: number, type: GuarantorType | undefined): void => {
    setInitialValues(guarantors[index]);
    setCurrentEditIndex(index);

    if (type) {
      setGuarantorType(type);
    }

    setInfoMode(InfoMode.EDIT);
  };

  const handleSubmit = (values: any, type: GuarantorType): void => {
    const newValues = removeEmptyFields(values);

    if (infoMode === InfoMode.CREATE) {
      setGuarantors([...guarantors, {
        ...newValues,
        customerType: type,
      }]);

      setGuarantorType(null);
    }

    if (infoMode === InfoMode.EDIT) {
      setGuarantors(guarantors.map((g, index) => (index === currentEditIndex ? { ...newValues, customerType: type } : g)));
      reset();
    }
  };

  const handleDelete = (index: number): void => {
    setGuarantors(guarantors.filter((_, i) => (index !== i)));
  };

  const renderGuarantorsList = (): ReactElement | null => {
    if (guarantors.length === 0) return null;

    return (
      <GuarantorsTable>
        <h2>Avalistas da operação</h2>
        <TableHeader>
          <h3>Nome completo / Razão social</h3>
          <h3>CPF/CNPJ</h3>
          <h3>Tipo de vínculo</h3>
        </TableHeader>
        {guarantors.map(({
          fullName, documentNumber, legalName, relationshipTypeID, customerType,
        }, index) => (
          <GuarantorCard>
            <p>{fullName ?? legalName}</p>
            <p>{formatCpfCnpj(documentNumber)}</p>
            <p>
              {getRelationshipByCustomerType(customerType, relationshipTypeID)}
            </p>
            <GuarantorActions>
              <Button message="" variant="secondary" icon={<RiEditLine />} onClick={() => handleEditModal(index, customerType)} />
              <Button message="" icon={<RiDeleteBin5Line />} onClick={() => handleDelete(index)} />
            </GuarantorActions>
          </GuarantorCard>
        ))}
      </GuarantorsTable>
    );
  };

  function renderGuarantorModal(): ReactElement | null {
    if (guarantorType === 'PERSON') {
      return <GuarantorPersonModal initialValues={initialValues} infoMode={infoMode} onClose={handleCloseModal} onSubmit={(values: any) => handleSubmit(values, 'PERSON')} />;
    }

    if (guarantorType === 'COMPANY') {
      return <GuarantorCompanyModal initialValues={initialValues} infoMode={infoMode} onClose={handleCloseModal} onSubmit={(values: any) => handleSubmit(values, 'COMPANY')} />;
    }

    return null;
  }

  return (
    <OperationStepFormBase handleSubmit={() => handleStepSubmission({ guarantors })}>
      <Container>
        <Header>
          <BoxLeft>
            <h2>
              Lista de avalistas
            </h2>
            <p>
              <span>{guarantors.length}</span>
              {' '}
              avalista(s)
              encontrado(s).
              {' '}
            </p>
          </BoxLeft>
          <BoxRight>
            <Button onClick={() => setShowTypeOfGuarantorSelect(!showTypeOfGuarantorSelect)} message="Novo avalista" icon={<RiUserSearchLine />} />
            {showTypeOfGuarantorSelect && <TypeOfGuarantorSelect handleSelect={handleSelectGuarantorType} />}
          </BoxRight>
        </Header>
        {renderGuarantorModal()}
        {renderGuarantorsList()}
        {
          !guarantorType && !guarantors.length && (
            <NoContent>
              <img src={HandshakeIcon} alt="Handshake Icon" />
              <h2>Nenhum avalista cadastrado</h2>
              <p>Cadastre um novo avalista através do botão a sua direita.</p>
            </NoContent>
          )
        }
      </Container>
    </OperationStepFormBase>
  );
}

export default GuarantorData;
