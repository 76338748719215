import { DateTime } from 'luxon';

function getDaysDifference(date: string): number {
  const today = DateTime.local();
  const endDate = DateTime.fromISO(date);
  const diffInDays = endDate.diff(today, 'days').toObject().days || 0;

  return Math.ceil(diffInDays);
}

export default getDaysDifference;
