import { FormikProps } from 'formik';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSteps } from 'react-step-builder';
import { cepMask } from '../../../../utils/masks';

import { OperationStepsContext } from '../../../../pages/Operations';

import {
  Input as InputControl,
  MaskedInput as InputMask,
} from '../../..';

import SelectField from '../../../Select';
import {
  Container,
  Form,
  InputControlRow,
} from '../../styles';
import { COUNTRIES_OPTIONS, CustomerDataCore } from '../../CustomerData.spec';

interface AddressProps {
  formik: FormikProps<CustomerDataCore>;
}

export const Address = ({ formik }: AddressProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Container>
      <Form>
        <InputControlRow>
          <InputMask
            name="postalCode"
            label="CEP"
            placeholder="Digite seu CEP"
            guide={false}
            mask={cepMask}
            value={formik.values.postalCode}
            message={formik.errors.postalCode}
            onChange={({ target: { value } }) => formik.setFieldValue('postalCode', value?.replace(/\D/g, ''))}
            required
          />
          <InputControl
            name="line1"
            label="Endereço"
            placeholder="Digite seu endereço"
            value={formik.values.line1}
            message={formik.errors.line1}
            onChange={formik.handleChange}
            required
          />
        </InputControlRow>
        <InputControlRow>
          <InputControl
            name="houseNumber"
            type="number"
            label="Número"
            placeholder="Digite seu número"
            value={formik.values.houseNumber}
            message={formik.errors.houseNumber}
            onChange={formik.handleChange}
            required
          />
          <InputControl
            name="complement"
            label="Complemento"
            placeholder="Digite seu complemento"
            value={formik.values.complement}
            message={formik.errors.complement}
            onChange={formik.handleChange}
          />
        </InputControlRow>
        <InputControlRow>
          <InputControl
            name="neighborhood"
            label="Bairro"
            placeholder="Digite seu bairro"
            value={formik.values.neighborhood}
            message={formik.errors.neighborhood}
            onChange={formik.handleChange}
            required
          />
          <InputControl
            name="city"
            label="Cidade"
            placeholder="Digite a sua cidade"
            value={formik.values.city}
            message={formik.errors.city}
            onChange={formik.handleChange}
            required
          />
        </InputControlRow>
        <InputControlRow>
          <InputControl
            name="state"
            label="Estado"
            placeholder="Digite o seu estado"
            value={formik.values.state}
            message={formik.errors.state}
            onChange={formik.handleChange}
            required
          />
          <SelectField
            name="country"
            label="País"
            value={COUNTRIES_OPTIONS.find((option) => option.id === formik.values.countryID)}
            message={formik.errors.countryID}
            onChange={(selectedOption: any) => formik.setFieldValue('countryID', selectedOption.id)}
            options={COUNTRIES_OPTIONS}
            required
          />
        </InputControlRow>
      </Form>
    </Container>
  );
};
