import React, {
  ReactElement, useContext,
} from 'react';
import { UploadDocument } from '../../../../../UploadDocument';
import { PrimaryInfoAgent } from './PrimaryInfoAgent';
import { FormContainer } from './styles';
import TabNavigation from '../../../../../TabNavigation';
import { AgentContext } from '../Agent.spec';

const FormAgent = (): ReactElement => {
  const { formik } = useContext(AgentContext);
  const tabs = [
    {
      title: 'Dados primários',
      element: <PrimaryInfoAgent />,
      enabled: true,
    },
    {
      title: 'Documentos',
      element: <UploadDocument documents={formik.values.attachments} handleChange={(files) => formik.setFieldValue('attachments', files)} />,
      enabled: true,
    },
  ];

  return (
    <FormContainer>
      <TabNavigation tabNavigationItems={tabs} />
    </FormContainer>
  );
};

export default FormAgent;
