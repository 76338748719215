import React, { ReactElement } from 'react';

import { Container } from './styles';

import { InputProps } from './input.spec';
import ErrorMessage from '../ErrorMessage';

const InputControl = ({
  label = '', type = 'text', placeholder = '', message, field, required, requiredText, ...inputProps
}: InputProps): ReactElement => (
  <Container>
    <label>
      {label}
      {' '}
      {(required || requiredText) && (
        <small>
          {requiredText ? `(${requiredText})` : '(obrigatório)'}
        </small>
      )}
    </label>
    <input type={type} placeholder={placeholder} {...field} {...inputProps} />
    {message && <ErrorMessage message={message} />}
  </Container>
);

export default InputControl;
