import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    .tooltip {
      ${theme.texts.paragraphSM};
      text-align: center;
      color: ${theme.colors.black00};
      background-color: ${theme.colors.white00};
      opacity: 1;
      border: 1px solid ${theme.colors.black20};
      border-radius: 4px;
      max-width: 444px;

      > div {
        border-bottom: 1px solid ${theme.colors.black20};
        border-right: 1px solid ${theme.colors.black20};

      }
    }
  `};
`;
