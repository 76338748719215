import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import ExcludeIllustration from '../../assets/ExcludeIllustration.svg';

import { ExcludeModalProps } from './ExcludeModal.spec';

import { Container, Content, ContentButtons } from './styles';

const ExcludeModal = ({
  type,
  visibleValue,
  handleCloseModal,
  handleDeleteConfirmation,
}: ExcludeModalProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <img src={ExcludeIllustration} alt="ExcludeIllustration" />
        <h3>
          Deseja realmente
          <br />
          {t(`excludeModal.${type}.title`)}
        </h3>
        <p>
          {t(`excludeModal.${type}.text`)}
          <b>
            {' '}
            {visibleValue}
          </b>
          ?
          <br />
          Esta ação não poderá ser desfeita.
        </p>
        <ContentButtons>
          <button type="button" onClick={handleCloseModal}>Cancelar</button>
          <button type="button" onClick={handleDeleteConfirmation}>
            {t(`excludeModal.${type}.button`)}
          </button>
        </ContentButtons>
      </Content>
    </Container>
  );
};

export default ExcludeModal;
