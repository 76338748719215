import { faker } from '@faker-js/faker';
import { CoreCustomer, WorkflowControlItemsProps } from '../@types';

const userTypes = ['Pessoa física', 'Pessoa jurídica'];
// Table fields header & content
export const customerTableHeaderTitles = ['Nome completo', 'CPF/CNPJ', 'Telefone', 'E-mail', 'Tipo de cliente'];
export const customerTableContentKeys = ['name', 'cpf', 'phone', 'email', 'typeUser'];

export const listSelect = [{ id: '1', name: 'Item01' }, { id: '2', name: 'Item02' }];

export const customerItems: CoreCustomer[] = [];
export const customerMe: CoreCustomer = createRandomUser();

export function createRandomUserItem(): CoreCustomer {
  return {
    id: faker.datatype.uuid(),
    name: faker.name.fullName(),
    cpf: faker.phone.number('9999999999'),
    phone: faker.phone.number(),
    email: faker.internet.email(),
    typeUser: faker.helpers.arrayElement(userTypes),
  };
}

Array.from({ length: 4 }).forEach(() => {
  customerItems.push(createRandomUserItem());
});

export function createRandomUser(): any {
  return {

    id: faker.datatype.uuid(),
    typeUser: faker.helpers.arrayElement(listSelect),
    status: faker.helpers.arrayElement(listSelect),
    name: faker.name.fullName(),
    cpf: faker.phone.number('9999999999'),
    civilStatus: faker.helpers.arrayElement(listSelect),
    phone: faker.phone.number(),
    birthDate: faker.date.birthdate(),
    email: faker.internet.email(),
    naturalness: faker.helpers.fake('Brasileiro'),
    ufNaturalness: faker.helpers.fake('RS'),
    occupation: faker.helpers.arrayElement(listSelect),
    income: faker.finance.amount(),
    netWorth: faker.finance.amount(),
    spouse: faker.datatype.boolean(),
    address: {
      postalCode: faker.address.zipCode(),
      street: faker.address.street(),
      number: faker.address.buildingNumber(),
      complement: faker.address.street(),
      neighborhood: faker.address.stateAbbr(),
      city: faker.address.city(),
      state: faker.address.state(),
    },
    documents: [
      // {
      //   id: faker.datatype.number(),
      //   filename: faker.system.fileName(),
      //   size: faker.datatype.number(),
      //   mimeType: faker.system.mimeType(),
      // },
    ],
    guarantors: [
      {
        id: faker.datatype.uuid(),
        name: faker.name.fullName(),
        cpf: faker.phone.number('9999999999'),
        civilStatus: faker.helpers.arrayElement(listSelect),
        phone: faker.phone.number(),
        birthDate: faker.date.birthdate(),
        email: faker.internet.email(),
        occupation: faker.helpers.arrayElement(listSelect),
        income: faker.finance.amount(),
        netWorth: faker.finance.amount(),
        spouse: faker.datatype.boolean(),
      },
    ],
  };
}

export const customerDataItems: WorkflowControlItemsProps[] = [
  {
    mainTitle: {
      title: 'Dados do cliente',
      checked: true,
      type: 'checkbox',
    },
    items: [
      { title: 'Nome completo', checked: true },
      { title: 'CPF', checked: true },
      { title: 'Data de nascimento', checked: true },
      { title: 'Estado civil', checked: true },
      { title: 'Celular', checked: true },
      { title: 'E-mail', checked: true },
      { title: 'Profissão', checked: true },
      { title: 'Renda', checked: true },
      { title: 'Patrimônio líquido', checked: true },
      { title: 'Possui empresa', checked: true },
    ],
  },
];

export const addressDataItems: WorkflowControlItemsProps[] = [
  {
    mainTitle: {
      title: 'Endereço do cliente',
      checked: true,
      type: 'checkbox',
    },
    items: [
      { title: 'CEP', checked: true },
      { title: 'Endereço', checked: true },
      { title: 'Número', checked: true },
      { title: 'Complemento', checked: true },
      { title: 'Bairro', checked: true },
      { title: 'Cidade', checked: true },
      { title: 'Estado', checked: true },
      { title: 'País', checked: true },
    ],
  },
];

export const guarantorDataItems: WorkflowControlItemsProps[] = [
  {
    mainTitle: {
      title: 'Avalista',
      checked: true,
      type: 'checkbox',
    },
    items: [
      { title: 'Nome completo', checked: true },
      { title: 'CPF', checked: true },
      { title: 'Data de nascimento', checked: true },
      { title: 'Estado civil', checked: true },
      { title: 'Celular', checked: true },
      { title: 'E-mail', checked: true },
      { title: 'Profissão', checked: true },
      { title: 'Renda', checked: true },
      { title: 'Patrimônio líquido', checked: true },
      { title: 'Cônjuge', checked: true },
    ],
  },
];

export const documentsItems: WorkflowControlItemsProps[] = [
  {
    mainTitle: {
      title: 'Defina a obrigatoriedade de cada anexo/documento',
      checked: undefined,
      type: 'switch',
    },
    items: [
      { title: 'Comprovante de endereço', checked: false },
      { title: 'Documento com foto', checked: false },
      { title: 'Selfie com documento', checked: false },
      { title: 'Comprovante de renda', checked: false },
      { title: 'Documentos oficiais de pessoa jurídica', checked: true },
      { title: 'Documentos oficiais dos sócios da empresa', checked: true },
    ],
  },
];

export default customerItems;
