import React, { ReactElement, useState } from 'react';
import {
  RiEyeLine,
  // RiMessage2Line,
  // RiMoreFill,
  RiDeleteBinLine,
  RiEditLine,
} from 'react-icons/ri';

import { Link } from 'react-router-dom';
import { TableProps } from './table.spec';

import {
  Container,
  MainTableHeader,
  MainTableHeaderItem,
  MainTableContent,
  MainTableContentRow,
  MainTableContentRowItem,
  MainTableActionsTab,
} from './styles';
import { Pagination, ExcludeModal } from '..';

const Table = ({
  tableHeader,
  tableData,
  tableKeys,
  handleDeleteItem,
  excludeModalTexts,
  itemBaseURL,
}: TableProps): ReactElement => {
  const [activeActionsIndex, setActiveActionsIndex] = useState<number | null>(null);
  const [isExcludeModalVisible, setIsExcludeModalVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(4);
  const [pageCount] = useState<number>(1);

  return (
    <Container>
      <MainTableHeader>
        {tableHeader?.map((title) => (
          <MainTableHeaderItem key={title}>{title}</MainTableHeaderItem>
        ))}
      </MainTableHeader>

      <MainTableContent>
        {tableData?.map((data: any, index: number) => {
          const filteredData = Object.fromEntries(
            Object.entries(data).filter(([key]) => tableKeys.includes(key)),
          );

          return (
            <MainTableContentRow
              key={data?.id || index}
              onMouseOver={() => setActiveActionsIndex(index)}
              onMouseLeave={() => setActiveActionsIndex(null)}
            >
              {Object.keys(filteredData).map((keyName) => (
                <MainTableContentRowItem key={keyName} className={keyName}>
                  <p>{filteredData[keyName]}</p>
                </MainTableContentRowItem>
              ))}

              {activeActionsIndex === index && (
              <MainTableActionsTab>
                <Link to={`${itemBaseURL}/view/${data?.id}`}>
                  <RiEyeLine />
                </Link>

                <Link to={`${itemBaseURL}/edit/${data?.id}`}>
                  <RiEditLine />
                </Link>

                <button
                  type="button"
                  onClick={() => {
                    setSelectedItem(data);
                    setIsExcludeModalVisible(true);
                  }}
                >
                  <RiDeleteBinLine />
                </button>
              </MainTableActionsTab>
              )}
            </MainTableContentRow>
          );
        })}
      </MainTableContent>

      <Pagination
        pagination={{
          page,
          pageSize,
          pageCount,
          total: tableData?.length,
        }}
        onChange={({ page: newPage, pageSize: newPageSize }) => {
          setPage(newPage);
          setPageSize(newPageSize);
        }}
      />

      {isExcludeModalVisible && (
        <ExcludeModal
          {...excludeModalTexts}
          handleCloseModal={() => setIsExcludeModalVisible(false)}
          handleDeleteConfirmation={() => {
            if (handleDeleteItem) {
              handleDeleteItem(selectedItem?.id);
            }
            setIsExcludeModalVisible(false);
          }}
          visibleValue={selectedItem[excludeModalTexts?.visibleValue || 'name']}
        />
      )}
    </Container>
  );
};

export default Table;
