import { BaseService } from './baseService';
import { ProfessionDTO, ProfessionSDTO } from '../@types/Profession';
import { AccountDTO } from '../@types/Account';
import { AccountTypeDTO, AccountTypeSDTO } from '../@types/AccountType';

class AccountTypeService extends BaseService<
  AccountTypeDTO,
  AccountTypeSDTO
> {
  baseURL = '/account-types';
}

export default new AccountTypeService();
