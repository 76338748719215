import { FormikProps } from 'formik';
import React, {
  ReactElement,
  useEffect, useState,
} from 'react';

import {
  Form,
  InputControlRow,
} from '../../../styles';

import {
  cpfMask,
  phoneMask9,
} from '../../../../../utils/masks';

import {
  Input as InputControl,
  InputCurrency,
  DateInput as InputDate,
  MaskedInput as InputMask,
  Loading,
  QueryInput,
  Select as SelectField,
} from '../../../..';
import { ProfessionSDTO } from '../../../../../@types/Profession';
import {
  CustomerDataCore,
  MARITAL_STATUS, SEX_OPTIONS,
} from '../../../CustomerData.spec';
import { formatDate } from '../../../../../utils/formatters';

interface PrimaryDataProps {
  formik: FormikProps<CustomerDataCore>;
}

export function PrimaryData({ formik }: PrimaryDataProps): ReactElement {
  const [professions, setProfessions] = useState<ProfessionSDTO[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Form>
      <InputControlRow>
        <QueryInput
          name="documentNumber"
          label="CPF"
          placeholder="000.000.000-00"
          guide={false}
          mask={cpfMask}
          value={formik.values.documentNumber}
          onChange={({ target: { value } }) => formik.setFieldValue('documentNumber', value?.replace(/\D/g, ''))}
          message={formik.errors.documentNumber}
          required
        />
        <InputControl
          name="fullName"
          label="Nome completo"
          placeholder="Digite seu nome"
          value={formik.values.fullName}
          onChange={formik.handleChange}
          message={formik.errors.fullName}
          required
        />
      </InputControlRow>
      <InputControlRow>
        {/* <InputDate
          name="birthdate"
          label="Data de nascimento"
          selected={formatDate(formik.values.birthdate)}
          placeholderText="01/01/2001"
          onChange={(value: any) => {
            formik.setFieldValue('birthdate', value.toISOString().split('T')[0]);
          }}
          message={formik.errors.birthdate}
          required
        /> */}
        <SelectField
          name="civilStatus"
          label="Estado civil"
          value={MARITAL_STATUS.find((status) => status.id === formik.values.civilStatusID)}
          message={formik.errors.civilStatusID}
          onChange={(selectedOption: any) => formik.setFieldValue('civilStatusID', selectedOption.id)}
          options={MARITAL_STATUS}
          required
        />
      </InputControlRow>
      <InputControlRow>
        <SelectField
          name="sex"
          label="Sexo"
          value={SEX_OPTIONS.find((sex) => sex.id === formik.values.sexID)}
          message={formik.errors.sexID}
          onChange={(selectedOption: any) => formik.setFieldValue('sexID', selectedOption.id)}
          options={SEX_OPTIONS}
          required
        />
        <InputMask
          name="mobilePhoneNumber"
          label="Celular"
          placeholder="(99) 9 9999 9999"
          guide={false}
          mask={phoneMask9}
          value={formik.values.mobilePhoneNumber}
          message={formik.errors.mobilePhoneNumber}
          onChange={({ target: { value } }) => formik.setFieldValue(
            'mobilePhoneNumber',
            value?.replace(/\D/g, ''),
          )}
          required
        />

      </InputControlRow>
      <InputControlRow>
        <InputControl
          name="email"
          type="email"
          placeholder="seuemail@email.com.br"
          label="E-mail"
          onChange={formik.handleChange}
          value={formik.values.email}
          message={formik.errors.email}
          required
        />
        <InputCurrency
          name="netWorth"
          label="Patrimônio líquido"
          value={formik.values.netWorth}
          message={formik.errors.netWorth}
          onStringChange={(value, name) => formik.setFieldValue(name, value)}
          required
        />
      </InputControlRow>
      <InputControlRow>
        <SelectField
          name="profession"
          label="Profissão"
          value={professions?.find((profession) => profession.id === formik.values.professionID)}
          message={formik.errors.professionID}
          onChange={(selectedOption: any) => formik.setFieldValue('professionID', selectedOption.id)}
          options={professions}
          required
        />
        <InputCurrency
          name="income"
          label="Renda"
          value={formik.values.income}
          message={formik.errors.income}
          onStringChange={(value, name) => formik.setFieldValue(name, value)}
          required
        />
      </InputControlRow>
      {isLoading && <Loading />}
    </Form>
  );
}
