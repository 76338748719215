import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    position: sticky;
    top: 0;
    padding: 0 0 12px 0;
    background-color: ${theme.colors.white00};
    z-index: 99999;
    margin: 0 0 12px 0;

    > svg {
      position: absolute;
      left: 12px;
      font-size: 1.2rem;
      color: ${theme.colors.black00};
    }

    > input {
      width: 100%;
      border-radius: 4px;
      height: 38px;
      border: 1px solid ${theme.colors.black05};
      padding: 10px 12px 10px 32px;
      background-color: ${theme.colors.black05};
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black00};
      transition: ${theme.transitions.default};

      &::placeholder {
        color: ${theme.colors.black50};
      }

      &:focus {
        border-color: ${theme.colors.black00};
      }
    }
  `};
`;
