import React, { ReactElement, useContext, useState } from 'react';
import { useFormik } from 'formik';

// import { ProductSelectContext } from '../ProductSelectModal';

import {
  Button, Input as InputControl, Modal, Textarea, Loading, OperationStepFormBase,
} from '..';

import { OperationStepsContext } from '../../pages/Operations';

import {
  Container,
  InputSolo,
  SeparatorContainer,
  Separator,
} from './styles';
import { OperationInputContext } from '../../routes/AuthenticatedRoutes';
import { ProductDataProps } from './ProductData.spec';

const ProductData = (): ReactElement => {
  const { selectedProduct } = useContext(OperationInputContext);
  console.log(selectedProduct);
  const { handleStepSubmission } = useContext(OperationStepsContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formik = useFormik<ProductDataProps>({
    initialValues: {
      productDescriptive: selectedProduct?.subtypeText,
    },
    onSubmit: (values) => {
      handleStepSubmission({ ...selectedProduct, values });
    },
  });

  return (
    <OperationStepFormBase handleSubmit={formik.handleSubmit}>
      <Container>
        <InputSolo>
          <InputControl
            label="Produto"
            value={selectedProduct?.name}
            disabled
          />
        </InputSolo>
        <InputSolo>
          <Textarea
            label="Descrição"
            value={selectedProduct?.description}
            disabled
          />
        </InputSolo>
        <SeparatorContainer>
          <Separator />
          <h2>Produto a ser financiado:</h2>
          <Separator />
        </SeparatorContainer>
        <InputSolo>
          <InputControl
            label="Descritivo do produto"
            name="productDescriptive"
            value={formik.values.productDescriptive}
            placeholder="Ex: Equipamento de Radiofrequência"
            onChange={formik.handleChange}
          />
        </InputSolo>
      </Container>
      {isLoading && <Loading />}
    </OperationStepFormBase>
  );
};

export default ProductData;
