import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { CoreFile } from '../../../../@types';
import { InfoMode } from '../../../../@types/InfoMode';
import { UserDTO } from '../../../../@types/User';

export interface UserInfoProps {
  mode: InfoMode;
}

export interface UserContextValue {
  formik: FormikProps<UserDTO>;
  setDocuments: Dispatch<SetStateAction<CoreFile[]>>;
  documents: CoreFile[];
  mode: InfoMode;
}

export function getInitialValues(user: UserDTO): UserDTO {
  return {
    personID: user?.personID || undefined,
    person: user?.person || undefined,
    // partner: user?.partner || '',
    // status: user?.status || '',
    // name: user?.name || '',
    // cpf: user?.cpf || '',
    // civilStatus: user?.civilStatus || '',
    // telephone: user?.telephone || '',
    // phone: user?.phone || '',
    // nationality: user?.nationality || '',
    // birthDate: user?.birthDate || new Date(),
    // gender: user?.gender || '',
    // rg: user?.rg || '',
    // issuingAgency: user?.issuingAgency || '',
    // shippingDate: user?.shippingDate || new Date(),
    // education: user?.education || '',
    // email: user?.email || '',
    // motherName: user?.motherName || '',
    // fatherName: user?.fatherName || '',
    // naturalness: user?.naturalness || '',
    // ufNaturalness: user?.ufNaturalness || '',
    // jurisdiction: user?.jurisdiction || '',
    // address: {
    //   postalCode: user?.address?.postalCode || '',
    //   street: user?.address?.street || '',
    //   number: user?.address?.number || '',
    //   complement: user?.address?.complement || '',
    //   neighborhood: user?.address?.neighborhood || '',
    //   city: user?.address?.city || '',
    //   state: user?.address?.state || '',
    // },
    documents: user?.documents || [],
    accessControl: {
      accessDays: user?.accessControl?.accessDays || [],
      accessTime: user?.accessControl?.accessTime || '',
      accessExternal: user?.accessControl?.accessExternal || '',
      changeRates: user?.accessControl?.changeRates || '',
    },
  };
}
