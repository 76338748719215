import styled, { css } from 'styled-components';

export const NestedContainer = styled.div`
  flex: 1;
  position: fixed;
  min-height: 100%;
  z-index: 9;

  display: flex;
  flex-direction: column;
  
  aside {
    flex: 1;
    min-height: 100%;

    display: flex;
    flex-direction: column;
  }
`;

export const SidebarContent = styled.div`
  ${({ theme }) => css`
    flex: 1;
    min-height: 100%;
    background-color: ${theme.colors.black05};
    position: relative;

    display: flex;
    flex-direction: column;

    > nav {
      flex: 1;
      min-height: 100%;
      display: flex;
      flex-direction: column;

      > ul {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  `};
`;

export const BottomItems = styled.div`
  margin-top: auto;

  display: flex;
  flex-direction: column;

  .ps-menu-label {
    opacity: 1;
  }

  .ps-menu-icon {
    opacity: 1 !important;
  }
`;

export const Badge = styled.span``;

export const SidebarHeader = styled.div`
  padding: 0 20px;
  margin: 0 0 54px 0;

  > img {
    height: 42px;
    width: 42px;
    pointer-events: none;
  }
`;

export const ToggleButton = styled.button<{ collapsed?: boolean }>`
  ${({ theme, collapsed }) => css`
    width: 32px;
    height: 32px;
    border-radius: 3px;
    background-color: ${theme.colors.black10};

    position: absolute;
    right: -16px;
    bottom: 100px;
    z-index: 9;

    > svg {
      font-size: 1.2rem;
      transition: ${theme.transitions.default};
    }

    ${collapsed
    && css`
      > svg {
        transform: rotate(180deg);
      }
    `};
  `};  
`;
