import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import ExcludeIllustration from '../../assets/ExcludeIllustration.svg';

import { LeaveModalProps } from './LeaveModal.spec';

import { Container, Content, ContentButtons } from './styles';

const LeaveModal = ({
  handleCloseModal,
  handleDeleteConfirmation,
}: LeaveModalProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <img src={ExcludeIllustration} alt="ExcludeIllustration" />
        <h3>
          Deseja realmente sair sem
          {' '}
          <br />
          {' '}
          enviar os dados atuais?
        </h3>
        <p>
          Ao sair, os dados preenchidos não serão enviados e nem salvos. Esta
          ação não poderá ser desfeita.
        </p>
        <ContentButtons>
          <button type="button" onClick={handleCloseModal}>
            Cancelar
          </button>
          <button type="button" onClick={handleDeleteConfirmation}>
            Sair sem enviar
          </button>
        </ContentButtons>
      </Content>
    </Container>
  );
};

export default LeaveModal;
