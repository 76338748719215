import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }
  
  html {
    font-size: 62.5%; // 1rem = 10px
    height: 100%;
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: transparent;

    @media (min-width: 1981px) {
      font-size: 80%;
    };
  }
  
  button, input, small {
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
  }
  
  body {
    height: auto;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto;
  }
  
  body, #root {
    min-height: 100vh;
    position: relative;
    
    display: flex;
    flex-direction: column;
  }
  
  #root {
    height: 100%;
  }
  
  input, div, li, button, a, textarea, select {
    -webkit-tap-highlight-color: transparent; 
  }

  textarea {
    border: none;
    resize: none;
    outline: none;
  }
  
  a {
    text-decoration: none;
    background: none;
    cursor: pointer;
    border: 0;
  }
  
  button {
    cursor: pointer;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ul {
    list-style: none;
    padding: 0;
    text-align: left;
  }

  .ps-sidebar-root {
    border: 0 !important;
  }
  .ps-sidebar-container {
    overflow: hidden !important;
    flex: 1;

    display: flex;
    flex-direction: column;
  }
  
  nav.ps-menu-root .ps-menuitem-root .ps-menu-button {
    height: 20px !important;
    .ps-submenu-expand-icon {
      color: #1E1E1E;
      opacity: 0.3;
      transition: opacity 180ms ease-in-out;
    }
    .ps-menu-icon {
      height: 20px;
      color: #1E1E1E;
      opacity: 0.3;
      transition: opacity 180ms ease-in-out;
      > svg {
        font-size: 2rem;
      }
    }
  }
  .ps-menu-label {
    color: #1E1E1E;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 18px;
    opacity: 0.3;
    transition: opacity 180ms ease-in-out;
  }
  .ps-submenu-expand-icon {
    right: 20px !important;
    top: 40% !important;
  }
  .ps-menuitem-root.last {
    margin: 0 !important;
  }
  .ps-submenu-content { 
    background-color: #F4F4F4 !important;
    margin: 14px 0 0 0;
    padding: 8px 0;
    max-width: 160px;
    box-shadow: none !important;
    .ps-menuitem-root {
      margin: 6px 0;
    }
  }
  .ps-menuitem-root {
    margin: 0 0 30px 0;
    &:hover {
      > a.ps-menu-button  {
        .ps-menu-icon {
          opacity: 1 !important;
        }
        .ps-menu-label {
          opacity: 1;
        }
        .ps-submenu-expand-icon {
          opacity: 1;
        }
      }
    }
    &.active {
      > a.ps-menu-button  {
        .ps-menu-icon {
          opacity: 1 !important;
        }
        .ps-menu-label {
          opacity: 1;
        }
        .ps-submenu-expand-icon {
          opacity: 1;
        }
      }
    }
    > a.active {
      .ps-menu-icon {
        opacity: 1 !important;
      }
      
      .ps-menu-label {
        opacity: 1;
      }
    }
  }

  .rc-tooltip {
    opacity: 1;
  }

  .rc-tooltip-inner {
    max-width: 410px;
    text-align: center;
  }

  .rc-tooltip-arrow {
    left: 50% !important;
    margin-left: 0 !important;
  }

  /* .react-datepicker-popper {
    z-index: 99999;
  } */

  .react-select__input-container {
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .Toastify__toast-container {
    width: fit-content;
  }

  .toast-message {
    opacity: 1 !important;
    padding: 0 16px;
    min-width: fit-content !important;

    &.Toastify__toast--error {
     background-color: #F6C9C9;
     color: #701D25;

     .Toastify__close-button {
      color: #701D25;
    }
    }

    &.Toastify__toast--success {
     background-color: #B8F3CC;
     color: #175623;

     .Toastify__close-button {
      color: #175623;
    }
    }

    &.Toastify__toast--info {
     background-color: #CAE6F6;
     color: #014081;

     .Toastify__close-button {
      color: #014081;
    }
    }

    &.Toastify__toast--warning {
     background-color: #F6ECCA;
     color: #836408;

     .Toastify__close-button {
      color: #836408;
    }
    }

    .Toastify__toast-body {
      margin: 0;
      padding: 0 10px 0 0;

      font-family: 'Inter';
      font-style: normal;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 1.8rem;

      .Toastify__toast-icon {
        > svg {
          font-size: 34px;
        }
      }
    }

    .Toastify__close-button {
      align-self: center;
      opacity: 1;
    }
  }

 .ant-picker-header-view {
  display: flex;
  justify-content: center;
 }

 .ant-picker {
  background-color: #F4F4F4;
  color: #1E1E1E;
  border-color: #F4F4F4;
  height: 42px;
 
  input {
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
    font-weight: 500 !important;
  }

  &:hover, &:active {
    border-color: #F4F4F4;
  }

  &.ant-picker-focused {
    box-shadow: none !important;
  }
 }

 .ant-picker-cell-today {
    .ant-picker-cell-inner::before {
      border-color: #1E1E1E !important;
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: #1E1E1E !important;
    }
  }

  .ant-picker-today-btn {
    color: #1E1E1E !important;
  }
`;

export default GlobalStyle;
