import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';

import {
  Navbar,
  Button,
  Breadcrumb,
  Table,
  Header,
} from '../../../components';

import { userService } from '../../../services';
import {
  tableContentKeys,
  tableHeaderTitles,
  userBreadCrumbItems,
} from '../../../mock';

import {
  Container, SubHeader, SearchInput, MainContent,
} from './styles';

import { UserSDTO } from '../../../@types/User';
// import { TableColumn } from '../../../components/ServiceTable/table.spec';
// import TableComponent from '../../../components/ServiceTable';

const Users: React.FC = () => {
  const [users, setUsers] = useState<UserSDTO[]>([]);
  // const userColumns: TableColumn<UserSDTO>[] = [
  //   {
  //     title: 'Nome',
  //     propName: 'person',
  //   },
  //   {
  //     title: 'E-mail',
  //     propName: 'person',
  //   },
  //   {
  //     title: 'Tipo de usuário',
  //     propName: 'personID',
  //   },
  //   {
  //     title: 'Alçada',
  //     propName: 'person',
  //   },
  // ];

  useEffect(() => {
    setUsers(userService.get());
  }, []);

  function handleDeleteUsers(id: number): void {
    users.splice(id, 1);
  }

  return (
    <Container>
      <Header title="Usuários" breadcrumbItems={userBreadCrumbItems} />

      <SubHeader>
        <SearchInput>
          <RiSearchLine />
          <input type="text" placeholder="Buscar usuário" />
        </SearchInput>
        <Link to="/registration/users/create">
          <Button message="Novo usuário" />
        </Link>
      </SubHeader>

      {/* <TableComponent
        service={userService}
        tableColumns={userColumns}
        excludeModalTexts={{
          type: 'user',
          visibleValue: 'name',
        }}
        itemBaseURL="/registration/users"
      /> */}

      <MainContent>
        <Table
          tableHeader={tableHeaderTitles}
          tableKeys={tableContentKeys}
          tableData={users}
          handleDeleteItem={(id: number) => {
            handleDeleteUsers(id);
          }}
          excludeModalTexts={{
            type: 'users',
            visibleValue: 'name',
          }}
          itemBaseURL="/registration/users"
        />
      </MainContent>
    </Container>
  );
};

export default Users;
