import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > label {
      ${theme.texts.paragraphXXS};
      margin: 0 0 8px 0;

      > small {
        color: ${theme.colors.black50};
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
    }
  `}
`;

export const SwitchContent = styled.div`
  ${({ theme }) => css`
  display: flex;
  align-items: center;
   > small {
    ${theme.texts.paragraphXXXS};
    color: ${theme.colors.black00};
    
    &.left {
      margin-right: 8px;
    }

    &.right {
      margin-left: 8px;
    }
   }
  `}
`;
export const SwitchRow = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: inline-block;
    width: 32px;
    height: 18px;

    > input {
      opacity: 0;
      width: 32px;
      height: 18px;
      z-index: 10;
      cursor: pointer;

      clip: auto;
      clip-path: none;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${theme.colors.black30};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 13.71px;
      width: 13.71px;
      left: 3px;
      bottom: 2px;
      background-color: ${theme.colors.white00};
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }

    input:checked + .slider {
      background-color: ${theme.colors.success00};
    }

    input:focus + .slider {
      box-shadow: 0 0 1px ${theme.colors.success00};
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(13.71px);
      -ms-transform: translateX(13.71px);
      transform: translateX(13.71px);
    }
  `}
`;
