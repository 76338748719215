import React, { ReactElement, useContext } from 'react';
import { UserContext } from '../../UserInfo';

import { Container } from './styles';
import { UploadDocument } from '../../../../../components/UploadDocument';

const Documents = (): ReactElement => {
  const {
    setDocuments, documents, formik, mode,
  } = useContext(UserContext);

  return (
    <Container>
      <UploadDocument
        documents={documents}
        handleChange={(file) => {
          formik.setFieldValue('documents', file);
          setDocuments(file);
        }}
        mode={mode}
      />
    </Container>
  );
};

export default Documents;
