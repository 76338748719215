import React, { ReactElement } from 'react';
import { ErrorMessageProps } from './ErrorMessage.spec';
import { Container } from './styles';

const ErrorMessage = ({ message }: ErrorMessageProps): ReactElement => (
  message ? (
    <Container>{message}</Container>
  ) : (<></>)
);

ErrorMessage.defaultProps = {
  message: undefined,
};

export default ErrorMessage;
