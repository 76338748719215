import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import {
  RiCalculatorLine,
  RiCheckLine,
  RiCloseCircleFill,
} from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { Formik, useFormik, useFormikContext } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  INITIAL_VALUES,
  OperationDataFormFields,
  OperationDataProps,
  YUP_SCHEMA,
} from './OperationData.spec';

import {
  CheckboxControl,
  CheckboxItem,
  Container,
  Form,
  InputControlRow,
  PaymentInfoContainer,
  PaymentInfoItem,
  PaymentInfoLabel,
  PaymentInfoValue,
  PaymentInfoActions,
  PaymentInfoFooter,
  Subtitle,
  CheckboxLabel,
} from './styles';
import { OperationStepsContext } from '../../pages/Operations';

import { maskCurrency } from '../../utils/masks';

import {
  Radio,
  DateInput as InputDate,
  InputCurrency,
  Modal,
  Input as InputControl,
  Button,
  Loading,
  Checkbox,
  OperationStepFormBase,
} from '..';
import { OutletContent } from '../../pages/Operations/styles';
import { formatDate, formatMoney } from '../../utils/formatters';
import { OperationInputContext } from '../../routes/AuthenticatedRoutes';

const OperationData = ({ onClose }: OperationDataProps): ReactElement => {
  const { currentStepData, handleStepSubmission } = useContext(
    OperationStepsContext,
  );
  const { selectedProduct, simulateValue } = useContext(OperationInputContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mergedValues = {
    ...(simulateValue?.insertedValue ?? {}),
    ...(simulateValue?.result ?? {}),
  };

  const initialValues = {
    ...(currentStepData?.inputData ?? INITIAL_VALUES),
    ...mergedValues,
    value: mergedValues?.value?.toString(),
  };

  const formik = useFormik<OperationDataFormFields>({
    enableReinitialize: true,
    initialValues,
    validationSchema: YUP_SCHEMA,
    validateOnChange: false,
    onSubmit(values: any) {
      handleStepSubmission(values);
    },
  });

  useEffect(() => {
    const showErrorToast = (): void => {
      const errorMessages = Object.keys(formik.errors).map(
        (key) => formik.errors[key as keyof typeof formik.errors],
      );

      toast.warning(errorMessages.join('.\n '), {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    };

    if (Object.keys(formik.errors).length > 0) {
      showErrorToast();
    }
  }, [formik.errors]);

  return (
    <OperationStepFormBase handleSubmit={formik.handleSubmit}>
      <OutletContent>
        <Container>
          <Form>
            <InputControlRow>
              <InputCurrency
                name="value"
                label="Valor solicitado"
                placeholder="0,00"
                value={formik.values.value}
                onStringChange={(value: any) => formik.setFieldValue('value', value)}
                message={formik.errors.value}
                required
              />

              <InputCurrency
                name="downPayment"
                label="Valor de entrada"
                placeholder="0,00"
                value={formik.values.downPayment}
                onStringChange={(value: any) => formik.setFieldValue('downPayment', value)}
              />
            </InputControlRow>

            <InputControlRow>
              <InputControl
                name="installmentQuantity"
                label="Número de parcelas"
                placeholder="0"
                value={formik.values.installmentQuantity}
                message={formik.errors.installmentQuantity}
                onChange={formik.handleChange}
                required
              />

              <InputCurrency
                name="installmentValue"
                label="Valor das parcelas"
                placeholder="0,00"
                value={simulateValue?.result?.installmentValue}
                onStringChange={(value: any) => formik.setFieldValue('installmentValue', value)}
                message={formik.errors.value}
                disabled
              />
            </InputControlRow>
            <InputControlRow>
              <InputDate
                name="firstInstallmentDueDate"
                label="Data do 1º vencimento"
                selected={formatDate(formik.values.firstInstallmentDueDate)}
                message={formik.errors.firstInstallmentDueDate}
                onChange={(value: any) => {
                  formik.setFieldValue('firstInstallmentDueDate', value);
                }}
                required
                disabledDate={(value: any) => value < moment(formik.initialValues.firstInstallmentDueDate)}
              />

              <InputDate
                name="lastInstallmentDueDate"
                label="Data do último vencimento"
                selected={formik.values.lastInstallmentDueDate}
                message={formik.errors.lastInstallmentDueDate}
                onChange={(value: any) => {
                  formik.setFieldValue(
                    'lastInstallmentDueDate',
                    value,
                  );
                }}
                disabled
                placeholderText="-"
              />
            </InputControlRow>
            <InputControlRow>
              <InputCurrency
                name="valueTAC"
                label="Valor da TAC"
                placeholder="0,00"
                value={formik.values.valueTAC}
                onChange={(value: any) => formik.setFieldValue(
                  'valueTAC',
                  maskCurrency(value.target.value),
                )}
                disabled
              />
              <InputCurrency
                name="operationFee"
                label="Taxa da operação"
                placeholder="0,00"
                value={formik.values.operationFee}
                onChange={(value: any) => formik.setFieldValue(
                  'operationFee',
                  maskCurrency(value.target.value),
                )}
                disabled
              />
            </InputControlRow>
            <InputControlRow>
              <InputCurrency
                name="iofPerDay"
                label="IOF ao dia"
                placeholder="0,00"
                value={formik.values.iofPerDay}
                onChange={(value: any) => formik.setFieldValue(
                  'iofPerDay',
                  maskCurrency(value.target.value),
                )}
                disabled
              />
              <InputCurrency
                name="iofFee"
                label="Taxa do IOF"
                placeholder="0"
                value={formik.values.iofFee}
                onChange={(value: any) => formik.setFieldValue(
                  'iofFee',
                  maskCurrency(value.target.value),
                )}
                disabled
              />
            </InputControlRow>
            <InputControlRow>
              <Radio
                name="inPersonSale"
                label="Venda presencial"
                checked={formik.values.inPersonSale}
                onChangeOne={() => formik.setFieldValue('inPersonSale', true)}
                onChangeTwo={() => formik.setFieldValue('inPersonSale', false)}
              />
            </InputControlRow>
            <Subtitle>Seguro</Subtitle>
            <InputControlRow>
              <InputCurrency
                name="creditLifeInsurancePct"
                label="Seguro prestamista"
                prefix="%"
                placeholder="0,00"
                value={formik.values.creditLifeInsurancePct}
                onStringChange={(value: any) => formik.setFieldValue('creditLifeInsurancePct', value)}
                disabled
              />
              <InputCurrency
                name="creditLifeInsurance"
                label="Valor do seguro prestamista"
                placeholder="0,00"
                prefix="R$"
                value={formik.values.creditLifeInsurance}
                onStringChange={(value: any) => formik.setFieldValue('creditLifeInsurance', value)}
                disabled={!formik.initialValues.creditLifeInsurance}
              />
            </InputControlRow>
            <Subtitle>Financiamento</Subtitle>

            {/* NOT MVP */}
            {/* <CheckboxLabel>Financiar atributos</CheckboxLabel>
            <CheckboxControl>
              <CheckboxItem>
                <Checkbox
                  name="financeIOF"
                  shape="square"
                  checked={formik.values.financeIOF}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'financeIOF',
                      !formik.values.financeIOF,
                    );
                  }}
                  // disabled={checked || readonly}
                />
                IOF
              </CheckboxItem>
              <CheckboxItem>
                <Checkbox
                  name="financeTAC"
                  shape="square"
                  checked={formik.values.financeTAC}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'financeTAC',
                      !formik.values.financeTAC,
                    );
                  }}
                />
                TAC
              </CheckboxItem>
              <CheckboxItem>
                <Checkbox
                  name="financeCreditLifeInsurance"
                  shape="square"
                  checked={formik.values.financeCreditLifeInsurance}
                  onChange={(e) => {
                    formik.setFieldValue(
                      'financeCreditLifeInsurance',
                      !formik.values.financeCreditLifeInsurance,
                    );
                  }}
                />
                Seguro prestamista
              </CheckboxItem>
            </CheckboxControl> */}
          </Form>
          <PaymentInfoFooter>
            <PaymentInfoContainer>
              <PaymentInfoItem>
                <PaymentInfoLabel>Valor da parcela</PaymentInfoLabel>
                <PaymentInfoValue>
                  {formatMoney(simulateValue?.result?.installmentValue)}
                </PaymentInfoValue>
              </PaymentInfoItem>
              <PaymentInfoItem>
                <PaymentInfoLabel>Valor total</PaymentInfoLabel>
                <PaymentInfoValue>
                  {formatMoney(simulateValue?.result?.totalValue)}
                </PaymentInfoValue>
              </PaymentInfoItem>

              {/* NOT MVP */}
              {/* <PaymentInfoItem>
                <PaymentInfoLabel>Valor a ser pago a vista</PaymentInfoLabel>
                <PaymentInfoValue>R$ 10.000,00</PaymentInfoValue>
              </PaymentInfoItem> */}
            </PaymentInfoContainer>
            <PaymentInfoActions>

              {Object.keys(simulateValue).length > 0 && (
                <Button message="Desfazer" variant="secondary" />
              )}
              <Button
                message={Object.keys(simulateValue).length > 0 ? 'Recalcular' : 'Calcular'}
                icon={<RiCalculatorLine />}
              />
            </PaymentInfoActions>
          </PaymentInfoFooter>
        </Container>
        {isLoading && <Loading />}
      </OutletContent>
    </OperationStepFormBase>
  );
};

export default OperationData;
