import React, {
  ReactElement,
  forwardRef,
  useEffect,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { AccountTypeDTO } from '../../../../../@types/AccountType';
import accountType from '../../../../../services/accountType';
import InputControl from '../../../../Input';
import Radio from '../../../../Radio';
import Select from '../../../../Select';
import { Form, InputControlRow } from '../../../styles';
import { FormAccountProps } from './FormAccount.spec';

export const FormAccount = ({
  formik,
}: FormAccountProps): ReactElement => {
  const { t } = useTranslation();

  const [selectAccountType, setSelectAccountType] = useState<AccountTypeDTO[]>();
  const [pixTypes, setPixTypes] = useState<AccountTypeDTO[]>([{ id: 1, uid: '', text: 'CPF' }]);

  useEffect(() => {
    async function getAccountTypes(): Promise<void> {
      const response = (await accountType.getList()) as any;

      setSelectAccountType(response);
    }

    getAccountTypes();
  }, []);

  return (
    <Form>

      <h2>Dados bancários</h2>

      <InputControlRow>
        <Select
          name="accountTypeID"
          label="Tipo de conta"
          options={selectAccountType}
          value={
            formik.values.accountTypeID
              ? selectAccountType?.find(
                (e) => e.id === Number(formik.values.accountTypeID),
              )
              : null
          }
          onChange={(selectedOption: any) => formik.setFieldValue('accountTypeID', selectedOption.id)}
          message={formik.errors.accountTypeID}
        />

        <InputControl
          name="bank"
          label="Banco"
          placeholder="Digite seu banco"
          value={formik.values.bank}
          onChange={formik.handleChange}
          message={formik.errors.bank}
        />

      </InputControlRow>

      <InputControlRow>
        <InputControl
          name="agencyNumber"
          label="Agência sem dígito"
          placeholder="Digite a agência sem dígito"
          value={formik.values.agencyNumber}
          onChange={formik.handleChange}
          message={formik.errors.agencyNumber}
        />

        <InputControl
          name="accountNumber"
          label="Conta com dígito"
          placeholder="Digite a conta com dígito"
          value={formik.values.accountNumber}
          onChange={formik.handleChange}
          message={formik.errors.accountNumber}
        />
      </InputControlRow>

      <InputControlRow>
        <Select
          name="pixTypeID"
          label="Tipo de chave PIX"
          options={pixTypes}
          value={
            formik.values.pixKeyTypeID
              ? pixTypes?.find(
                (e) => e.id === Number(formik.values.pixKeyTypeID),
              )
              : null
          }
          message={formik.errors.pixKeyTypeID}
          onChange={(selectedOption: any) => formik.setFieldValue('pixKeyTypeID', selectedOption.id)}
        />
        <InputControl
          name="pixKey"
          label="Chave PIX"
          placeholder="Chave PIX"
          value={formik.values.pixKey}
          onChange={formik.handleChange}
          message={formik.errors.pixKey}
        />
      </InputControlRow>
      <InputControlRow>
        <Radio
          name="isPrimaryAccount"
          label="Conta principal?"
          checked={formik.values.isPrimaryAccount}
          onChangeOne={() => formik.setFieldValue('isPrimaryAccount', true)}
          onChangeTwo={() => formik.setFieldValue('isPrimaryAccount', false)}
        />
      </InputControlRow>
    </Form>
  );
};
