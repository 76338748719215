import { Dispatch, ReactElement, SetStateAction } from 'react';
import { InfoMode } from '../../@types/InfoMode';

export interface DataCardsListProps {
  item?: any;
  onRemove?: () => void;
  children: ReactElement;
  mode?: InfoMode;
  type?: ListsTypes;
  visibleValue?: string;
  onCancel?: () => void;
  onDuplicate?: () => void;
  onSubmit?: () => void;
  tag?: string;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  title?: string;
  subtitle?: string;
}

export type ListsTypes = 'bankData' | 'VehicleWarranty' | 'companyPartner' | '';

type Messages = {
  [key in ListsTypes]: string;
}

export const submitButtonMessages: Messages = {
  bankData: 'Salvar conta',
  VehicleWarranty: 'Salvar garantia',
  companyPartner: 'Salvar sócio/representante',
  '': 'Salvar',
};
