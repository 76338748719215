import React, {
  ReactElement, useCallback, useEffect, useState,
} from 'react';
import ErrorMessage from '../ErrorMessage';
import InputControl from '../Input';
import { InputCurrencyProps } from './CurrencyInput.spec';

import { Container, Content } from './styles';

const InputCurrency = ({
  label = '',
  prefix = 'R$',
  className,
  message,
  value,
  onStringChange,
  decimalSeparator = ',',
  groupSeparator = '.',
  decimalsLimit = 2,
  name,
  field,
  requiredText,
  ...props
}: InputCurrencyProps): ReactElement => {
  const [internalValue, setInternalValue] = useState<string | undefined>('');
  const [outputValue, setOutputValue] = useState<string>();

  const format = useCallback(
    (val: string | number | undefined, ds = '.', gs = ''): string | undefined => {
      if (!val) {
        return '';
      }

      const stringValue = `${'00'.repeat(decimalsLimit)}${val
        ?.toString()
        .replace(/\D/g, '')}`
        .replace(new RegExp(`0+(\\d{${decimalsLimit + 1},})$`), '$1')
        .replace(new RegExp(`(\\d+)(\\d{${decimalsLimit}})`), `$1${ds}$2`);

      if (!gs) {
        return stringValue;
      }

      const integerPart = stringValue
        .slice(0, -(decimalsLimit + ds.length))
        .split('')
        .map((v, i, { length }) => {
          const reverseIndex = length - i - 1;
          return reverseIndex !== 0 && reverseIndex % 3 === 0 ? `${v}${gs}` : v;
        })
        .join('');

      return integerPart + stringValue.slice(-(decimalsLimit + ds.length));
    },
    [decimalsLimit],
  );

  useEffect(() => {
    const formatAndSetInternalValue = (): void => {
      if (value !== outputValue) {
        const formattedValue = format(
          parseFloat(value?.toString() || '').toFixed(decimalsLimit),
          decimalSeparator,
          groupSeparator,
        );
        setInternalValue(formattedValue);
      }
    };

    formatAndSetInternalValue();
  }, [
    value,
    decimalSeparator,
    groupSeparator,
    format,
    outputValue,
    decimalsLimit,
  ]);

  useEffect(() => {
    const formattedValue = format(internalValue);
    if (formattedValue === outputValue) {
      return;
    }

    setOutputValue(formattedValue);
    onStringChange?.(formattedValue || '', name || '');
  }, [internalValue, onStringChange, format, name, outputValue]);

  return (
    <Container>
      <Content className={message ? 'error' : ''}>
        <p>{prefix}</p>
        <InputControl
          {...field}
          {...props}
          label={label}
          placeholder="0,00"
          onChange={(e) => {
            const formattedValue = format(
              e.target.value,
              decimalSeparator,
              groupSeparator,
            );
            setInternalValue(formattedValue);
          }}
          requiredText={requiredText}
          value={internalValue}
        />
      </Content>
      <ErrorMessage message={message} />
    </Container>
  );
};

export default InputCurrency;
