import React, {
  ReactElement, useEffect, useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Modal from '../Modal';
import { Button, TabNavigation } from '../..';
import {
  ContentModal, Divider, FooterButton,
} from './styles';
import GuarantorData from './GuarantorData';
import { TabElementProps } from '../../../@types/TabNavigation';
import GuarantorAddress from './GuarantorAddress';
import { BankData } from '../../CustomerData/CustomerDataSteps';
import { GuarantorSocial } from './GuarantorSocial';
import { GuarantorDataCore } from '../GuarantorData.spec';
import { UploadDocument } from '../../UploadDocument';
import { CoreFile } from '../../../@types';
import { InfoMode } from '../../../@types/InfoMode';
import { YUP_SCHEMA } from './GuarantorCompanyModal.spec';
import Agent from '../../CustomerData/CustomerDataSteps/Company/Agent';

const taxFreeLabel = 'ISENTO';

const GuarantorCompanyModal = ({
  onClose, onSubmit, initialValues, infoMode,
}: any): ReactElement => {
  const { t } = useTranslation();

  const formik = useFormik<GuarantorDataCore>({
    initialValues,
    validationSchema: YUP_SCHEMA,
    async onSubmit({
      cityTaxID, cityTaxFree, stateTaxID, stateTaxFree, ...allOtherValues
    }) {
      onSubmit({
        ...allOtherValues,
        cityTaxFree,
        stateTaxFree,
        cityTaxID: cityTaxFree ? taxFreeLabel : cityTaxID,
        stateTaxID: stateTaxFree ? taxFreeLabel : stateTaxID,
      });
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  const tabNavigationItems = [
    {
      title: t('guarantorCompanyModal.titleTabNavigationItems.guarantorData'),
      element: <GuarantorData formik={formik} />,
      enabled: true,
    },
    {
      title: t('guarantorCompanyModal.titleTabNavigationItems.address'),
      element: <GuarantorAddress formik={formik} />,
      enabled: true,
    },
    {
      title: t('guarantorCompanyModal.titleTabNavigationItems.agents'),
      element: <Agent agentList={formik.values.agents} setAgentList={(values) => formik.setFieldValue('agents', values)} />,
      enabled: true,
    },
    {
      title: t('guarantorCompanyModal.titleTabNavigationItems.documents'),
      element: <UploadDocument documents={formik.values.attachments} handleChange={(files: CoreFile[]) => formik.setFieldValue('attachments', files)} />,
      enabled: true,
    },
    {
      title: t('guarantorCompanyModal.titleTabNavigationItems.bankData'),
      element: <BankData accountList={formik.values.accounts} setAccountList={(account) => formik.setFieldValue('accounts', account)} />,
      enabled: true,
    },
    {
      title: t('guarantorCompanyModal.titleTabNavigationItems.socialNetwork'),
      element: <GuarantorSocial formik={formik} />,
      enabled: true,
    },
  ] as TabElementProps;

  return (
    <Modal
      title={t('guarantorPersonModal.title') || 'Novo avalista'}
      onClose={onClose}
      maxWidth="900px"
    >
      <ContentModal onSubmit={formik.handleSubmit}>
        <TabNavigation
          tabNavigationItems={tabNavigationItems}
          type="avalista"
        />

        <FooterButton>
          <Button
            message={`${infoMode === InfoMode.CREATE ? 'Adicionar' : 'Editar'} avalista`}
            type="submit"
            onClick={() => formik.handleSubmit()}
          />
        </FooterButton>
        <Divider />
      </ContentModal>
    </Modal>
  );
};

export default GuarantorCompanyModal;
