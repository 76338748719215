import React, { ReactElement, useEffect, useState } from 'react';

import SimulatorModal from './SimulatorModal';

import { ProductSDTO } from '../../@types/Product';

import { Container } from './styles';

interface SimulatorStepsProps {
  onClose: () => void;
}

const SimulatorSteps = ({ onClose }: SimulatorStepsProps): ReactElement => (
  <Container>
    <SimulatorModal onClose={onClose} />
  </Container>
);

export default SimulatorSteps;
