import React, { ReactElement } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  RiHome6Fill,
  RiUser4Fill,
  RiQuestionFill,
  RiLogoutBoxRFill,
  RiArrowLeftSFill,
  RiBankCardFill,
} from 'react-icons/ri';
import {
  Sidebar as Container,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from 'react-pro-sidebar';

import { useTranslation } from 'react-i18next';
import { lightTheme } from '../../styles/themes';

import Logo from '../../assets/Logo.svg';

import {
  SidebarHeader,
  BottomItems,
  SidebarContent,
  ToggleButton,
  NestedContainer,
} from './styles';
import { useAuth } from '../../contexts/AuthContext';

const Sidebar = (): ReactElement => {
  const { t } = useTranslation();
  const routeLocation = useLocation();
  const { Logout } = useAuth();
  const { collapseSidebar, collapsed } = useProSidebar();

  function isStringInPath(searchString: string): boolean {
    return routeLocation.pathname.includes(searchString);
  }

  return (
    <NestedContainer>
      <Container
        width="249px"
        rootStyles={{
          padding: '24px 0 32px 0',
          backgroundColor: lightTheme.colors.black05,
          // overflow: 'hidden',
        }}
        collapsedWidth="85px"
      >
        <SidebarContent>
          <SidebarHeader>
            <img src={Logo} alt="Logo" />
          </SidebarHeader>
          <Menu>
            <MenuItem icon={<RiHome6Fill />} component={<NavLink to="/" />}>
              {t('sidebar.home')}
            </MenuItem>
            <SubMenu
              label={t('sidebar.registration')}
              icon={<RiUser4Fill />}
              className={isStringInPath('registration') ? 'active' : ''}
              defaultOpen={isStringInPath('registration')}
            >
              <MenuItem component={<NavLink to="/registration/users" />}>
                {t('sidebar.users')}
              </MenuItem>
              <MenuItem component={<NavLink to="/registration/customers" />}>
                {t('sidebar.customers')}
              </MenuItem>
            </SubMenu>
            <BottomItems>
              <MenuItem icon={<RiQuestionFill />}>{t('sidebar.help')}</MenuItem>
              <MenuItem
                component={<NavLink to="/" />}
                icon={<RiLogoutBoxRFill />}
                className="last"
                onClick={Logout}
              >
                {t('sidebar.logout')}
              </MenuItem>
            </BottomItems>
          </Menu>
        </SidebarContent>
      </Container>
      <ToggleButton
        type="button"
        onClick={() => collapseSidebar()}
        collapsed={collapsed}
      >
        <RiArrowLeftSFill />
      </ToggleButton>
    </NestedContainer>
  );
};

export default Sidebar;
