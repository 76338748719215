import React, { useEffect, useState } from 'react';
import { RiArrowDownSFill, RiSearchLine } from 'react-icons/ri';

import { SummaryItemProps } from '../../@types';

import {
  Card,
  Button,
  SimulatorSteps,
  Header,
  ProductSelectModal,
} from '../../components';

import { homeBreadCrumbItems } from '../../mock';

import { productService, summaryService } from '../../services';

import {
  Container,
  InfoCardsList,
  InfoCardsSection,
  InfoCardsSubHeader,
  SubHeader,
  SearchInput,
  HeaderButtons,
} from './styles';
import { ProductSDTO } from '../../@types/Product';

const Home: React.FC = () => {
  const [summary, setSummary] = useState<SummaryItemProps[]>([]);
  const [isVisibleOperation, setIsVisibleOperation] = useState<boolean>(false);
  const [isVisibleProductSelectModal, setIsVisibleProductSelectModal] = useState<boolean>(false);

  useEffect(() => {
    getSummary();
  }, []);

  function getSummary(): void {
    const summaryItems = summaryService.get();
    setSummary(summaryItems);
  }

  return (
    <>
      <Container>
        <Header title="Resumo" breadcrumbItems={homeBreadCrumbItems} />
        <SubHeader>
          <SearchInput>
            <RiSearchLine />
            <input type="text" placeholder="Buscar operação" />
          </SearchInput>
          <HeaderButtons>
            <Button
              variant="secondary"
              message="Simular operação"
              onClick={() => setIsVisibleOperation(true)}
            />
            <Button
              message="Nova operação"
              onClick={() => setIsVisibleProductSelectModal(true)}
            />
          </HeaderButtons>
        </SubHeader>

        <InfoCardsSection>
          <InfoCardsSubHeader>
            <small>Estatísticas</small>
            <button type="button">
              Este mês
              <RiArrowDownSFill />
            </button>
          </InfoCardsSubHeader>
          <InfoCardsList>
            {summary.length ? (
              summary.map((item: SummaryItemProps) => (
                <Card
                  key={item.id}
                  title={item.title}
                  number={item.number}
                  value={item.value}
                />
              ))
            ) : (
              <small>Nenhuma informação a exibir no sumário.</small>
            )}
          </InfoCardsList>
        </InfoCardsSection>
      </Container>
      {isVisibleOperation && (
        <SimulatorSteps
          onClose={() => setIsVisibleOperation(!isVisibleOperation)}
        />
      )}
      {isVisibleProductSelectModal && (
        <ProductSelectModal
          handleCloseModal={() => setIsVisibleProductSelectModal(!isVisibleProductSelectModal)}
        />
      )}
    </>
  );
};

export default Home;
