import {
  useEffect, useState, useMemo, useContext,
} from 'react';
import { toast } from 'react-toastify';
import { RiCheckboxCircleFill, RiCloseCircleFill } from 'react-icons/ri';
import { CoreDTO, Page, SortDirection } from '../@types/Core';
import { CoreService } from '../services/coreService';
import { AppContext } from '../App';

type PaginationHelperOptions = {
  initialPageSize?: number;
  filters?: Record<string, any>
}

type PaginationHelperType<I extends CoreDTO> = {
  page: Required<Page<I>>
  previous(): void
  next(): void
  goTo(pageNumber: number): void
  sortBy(column: PageOptions<I>['sort'], direction?: PageOptions<I>['sortDir']): void
  setSize(pageSize: number): void
  setSearchString(searchString: PageOptions<I>['searchString']): void
  setFilters(filters: PageOptions<I>['filters']): void
  remove(id: any): void
  refresh(): Promise<void>
}

type PageOptions<I extends CoreDTO> = {
  pageNumber: number
  pageSize: number
  sort?: keyof I
  sortDir?: SortDirection
  searchString?: string
  filters?: Record<string, any>
}

export function usePaginationHelper<
E extends CoreDTO,
  I extends CoreDTO,
S extends CoreService<E, I>,
>(coreService: S, options?: PaginationHelperOptions): PaginationHelperType<I> {
  const [page, setPage] = useState<Page<I>>();
  const [pageOptions, setPageOptions] = useState<PageOptions<I>>(() => ({
    pageNumber: 0,
    pageSize: options?.initialPageSize || 5,
    filters: options?.filters,
  }));
  const { setLoading } = useContext(AppContext);

  function previous():void {
    setPageOptions((state) => ({
      ...state,
      pageNumber: Math.max(0, state.pageNumber - 1),
    }));
  }

  function next():void {
    setPageOptions((state) => ({
      ...state,
      pageNumber: pageOptions.pageNumber + 1,
    }));
  }

  function goTo(pageNumber: number):void {
    setPageOptions((state) => ({
      ...state,
      pageNumber: Math.max(0, pageNumber),
    }));
  }

  function sortBy(column: PageOptions<I>['sort'], direction = SortDirection.ASC):void {
    setPageOptions((state) => ({
      ...state,
      pageNumber: 0,
      sort: column,
      sortDir: direction,
    }));
  }

  function setSize(pageSize: number):void {
    setPageOptions((state) => ({
      ...state,
      pageNumber: 0,
      pageSize: Math.max(1, pageSize),
    }));
  }

  function setSearchString(searchString: PageOptions<I>['searchString']):void {
    setPageOptions((state) => ({
      ...state,
      pageNumber: 0,
      searchString,
    }));
  }

  function setFilters(filters: PageOptions<I>['filters']):void {
    setPageOptions((state) => ({
      ...state,
      pageNumber: 0,
      filters,
    }));
  }

  async function remove(id: any):Promise<void> {
    setLoading(true);
    try {
      await coreService.delete(id);
      toast.success(
        'Dado deletado com sucesso.', {
          className: 'toast-message',
          icon: RiCheckboxCircleFill,
          containerId: 'close-on',
        },
      );
      fetchPage();
    } catch {
      toast.error(
        'Não foi possível completar a requisição.', {
          className: 'toast-message',
          icon: RiCloseCircleFill,
          containerId: 'close-on',
        },
      );
    } finally {
      setLoading(false);
    }
  }

  async function fetchPage(): Promise<void> {
    setLoading(true);
    try {
      const newPage = await coreService.getPage({
        filters: pageOptions.filters,
        pageNumber: pageOptions.pageNumber,
        pageSize: pageOptions.pageSize,
        searchString: pageOptions.searchString,
        sort: pageOptions.sort ? `${String(pageOptions.sort)},${pageOptions.sortDir ? pageOptions.sortDir : SortDirection.ASC}` : undefined,
      });
      setPage(newPage);
    } catch {
      toast.error(
        'Não foi possível carregar os dados.', {
          className: 'toast-message',
          icon: RiCloseCircleFill,
          containerId: 'close-on',
        },
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchPage();
  }, [coreService, pageOptions, setLoading]);

  const requiredPage = useMemo < Required<Page<I>>>(() => ({
    content: page?.content instanceof Array ? page?.content : [],
    pageable: page?.pageable ?? {},
    totalElements: page?.totalElements ?? 0,
    number: page?.number ?? 0,
    size: page?.size ?? 0,
    numberOfElements: page?.numberOfElements ?? 0,
    totalPages: page?.totalPages ?? 0,
    first: page?.first ?? true,
    last: page?.last ?? true,
  }), [page]);

  return {
    page: requiredPage,
    previous,
    next,
    goTo,
    sortBy,
    setSize,
    setSearchString,
    setFilters,
    remove,
    refresh: fetchPage,
  };
}
