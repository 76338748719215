import React, { ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  RiCheckLine, RiCloseLine, RiDeleteBin6Line, RiExternalLinkLine,
} from 'react-icons/ri';
import {
  Container, Content, ContentSocial, InputControl, InputItem, NewItemContent, NoContent,
} from './styles';
import EmptyTable from '../../assets/EmptyTable.svg';
import SocialsOptions from '../SocialsOptions';

interface SocialNetworkProps {
  socialList: any[],
  setSocialList: (socials: any[]) => void,
}

export function SocialNetwork({ socialList, setSocialList }: SocialNetworkProps): ReactElement {
  const { t } = useTranslation();
  const [newSocial, setNewSocial] = useState<any>(null);

  function addSocial(values: any): void {
    setSocialList([...socialList, {
      ...values,
      url: values.url + values.username,
    }]);
    setNewSocial(null);
  }

  function removeSocial(id: any): void {
    const newList = socialList.filter((social) => social.id !== id);
    setSocialList(newList);
  }

  return (
    <Container>
      <Content>
        {socialList.map((e) => (
          <ContentSocial key={e.id}>
            <p>{e.name}</p>
            <InputItem>
              <InputControl>
                <input
                  defaultValue={e.url}
                  placeholder="usuario"
                  readOnly
                  // disabled={mode === InfoMode.VIEW}
                />
                <Link to={e.url || ''} target="_blank">
                  <RiExternalLinkLine />
                </Link>
              </InputControl>
              <button type="button" onClick={() => removeSocial(e.id)}>
                <RiDeleteBin6Line />
              </button>
            </InputItem>
          </ContentSocial>
        ))}

        {newSocial && (
          <ContentSocial>
            <p>{newSocial.name}</p>
            <InputItem>
              <InputControl>
                {newSocial.url}
                <input
                  value={newSocial.username}
                  placeholder="digite aqui"
                  onChange={(e) => setNewSocial({
                    ...newSocial,
                    url: newSocial.url,
                    username: e.target.value,
                    // personID,
                  })}
                />
              </InputControl>
              <button
                type="button"
                onClick={() => addSocial(newSocial)}
                disabled={!newSocial.username}
              >
                <RiCheckLine />
              </button>

              <button type="button" onClick={() => setNewSocial(undefined)}>
                <RiCloseLine />
              </button>
            </InputItem>
          </ContentSocial>
        )}

        {socialList.length <= 0 && !newSocial && (
          <NoContent>
            <img src={EmptyTable} alt="EmptyTable" />
            <h4>{t('socialNetwork.textNoDataFound')}</h4>
            <p>{t('socialNetwork.textNoDataDisplay')}</p>
          </NoContent>
        )}
      </Content>

      <NewItemContent>
        <SocialsOptions registeredSocials={socialList} handleClick={(social) => setNewSocial(social)} />
      </NewItemContent>
    </Container>
  );
}
