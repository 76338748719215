import { useFormik } from 'formik';
import React, { ReactElement, useContext, useEffect } from 'react';

import { toast } from 'react-toastify';
import { RiCloseCircleFill } from 'react-icons/ri';
import { TabNavigation, OperationStepFormBase } from '../../..';

import { OperationStepsContext } from '../../../../pages/Operations';
import { OperationInputContext } from '../../../../routes/AuthenticatedRoutes';
import { UploadDocument } from '../../../UploadDocument';
import { CustomerDataCore, INITIAL_VALUES } from '../../CustomerData.spec';
import { YUP_SCHEMA_PERSON } from './CustomerDataPerson.spec';
import { BankData } from '../BankData';
import { Address } from '../Address';
import { PrimaryData } from './PrimaryData';
import { formatDate } from '../../../../utils/formatters';

export const CustomerDataPerson = (): ReactElement => {
  const {
    currentStepData,
    handleStepSubmission,
    setCurrentOperationStep,
    valuePreviousStep,
  } = useContext(OperationStepsContext);
  const { selectedCustomerType } = useContext(OperationInputContext);

  const formik = useFormik<CustomerDataCore>({
    enableReinitialize: true,
    validationSchema: YUP_SCHEMA_PERSON,
    validateOnChange: false,
    initialValues:
      valuePreviousStep ?? currentStepData?.inputData ?? INITIAL_VALUES,
    onSubmit: (values) => {
      handleStepSubmission(values);
    },
  });

  useEffect(() => {
    const showErrorToast = (): void => {
      const errorMessages = Object.keys(formik.errors).map(
        (key) => formik.errors[key as keyof typeof formik.errors],
      );

      toast.warning(errorMessages.join('.\n '), {
        className: 'toast-message',
        icon: RiCloseCircleFill,
        containerId: 'close-on',
      });
    };

    if (Object.keys(formik.errors).length > 0) {
      showErrorToast();
    }
  }, [formik.errors]);

  const tabs = [
    {
      title: 'Dados primários',
      element: <PrimaryData formik={formik} />,
      enabled: true,
    },
    {
      title: 'Endereço',
      element: <Address formik={formik} />,
      enabled: true,
    },
    {
      title: 'Documentos',
      element: (
        <UploadDocument
          documents={formik.values.attachments}
          handleChange={(files) => formik.setFieldValue('attachments', files)}
        />
      ),
      enabled: false,
    },
    {
      title: 'Dados bancários',
      element: (
        <BankData
          accountList={formik.values.accounts}
          setAccountList={(accounts) => formik.setFieldValue('accounts', accounts)}
        />
      ),
      enabled: false,
    },
  ];

  return (
    <OperationStepFormBase handleSubmit={formik.handleSubmit}>
      <TabNavigation
        tabNavigationItems={tabs}
        disabledText="Indisponível no momento"
      />
    </OperationStepFormBase>
  );
};
