import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Content = styled.div<{ collapsed?: boolean }>`
  ${({ theme, collapsed }) => css`
    /* padding: 0 36px 0 36px; */
    width: 100%;
    margin-left: 249px;
    flex: 1;

    display: flex;
    flex-direction: column;

    transition: ${theme.transitions.default};

    ${collapsed
    && css`
      margin-left: 85px;
    `};
  `};
`;
