import React, { Children, ReactElement, useEffect } from 'react';
import { RiCloseFill, RiArrowLeftLine } from 'react-icons/ri';

import { ModalProps } from './Modal.spec';

import {
  Container,
  MainContent,
  Header,
} from './styles';

const Modal = ({
  title, onClose, children, hasPrev, maxWidth, elementHeader,
}: ModalProps): ReactElement => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  function handleModalClose(): void {
    onClose();
    document.body.style.overflow = 'auto';
  }

  return (
    <Container>
      <MainContent maxWidth={maxWidth}>
        <Header>
          {hasPrev && (
            <button type="button">
              <RiArrowLeftLine />
            </button>
          )}
          <h2>{title}</h2>

          <div>
            {elementHeader || null}
            <button type="button" onClick={handleModalClose}>
              <RiCloseFill />
            </button>
          </div>
        </Header>
        {children}
      </MainContent>
    </Container>
  );
};

export default Modal;
