import styled, { css } from 'styled-components';

export const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.2);
  min-height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: visible;

  z-index: 10001;
`;

export const MainContent = styled.div<{ maxWidth?: string }>`
  ${({ theme, maxWidth }) => css`
    width: 100%;
    max-width: ${maxWidth || '627px'};
    padding: 32px;
    background-color: ${theme.colors.white00};
    position: absolute;
    top: 32px;
    bottom: 32px;
    border-radius: 4px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar {
      display: none;
    }
  `};
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    width: 100%;
    justify-content: space-between;

    > h2 {
      ${theme.texts.headingSM};
      color: ${theme.colors.black00};
    }

    > div{
      display: flex;
      gap: 16px;
      align-items: center;

      > button:last-child {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 40px;
      width: 40px;

      border: 1px solid ${theme.colors.black10};
      border-radius: 4px;
      background-color: transparent;
      transition: ${theme.transitions.default};
      cursor: pointer;
      :hover {
        border-color: ${theme.colors.black00};
      }
      > svg {
        font-size: 2rem;
      }
      &:last-child {
        margin-left: auto;
      }
    }
    }
  `};
`;
