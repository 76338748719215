import * as Yup from 'yup';
import { GuarantorDataCore } from '../GuarantorData.spec';

export const selectRelationship = [{
  id: 1,
  text: 'Cônjuge',
}];

export const YUP_SCHEMA = Yup.object<GuarantorDataCore>().shape({
  // guarantor data
  documentNumber: Yup.string().required('"CNPJ" é um campo obrigatório'),
  legalName: Yup.string().required('"Razão Social" é um campo obrigatório'),
  tradeName: Yup.string().required('"Nome Fantasia" é um campo obrigatório'),
  stateTaxFree: Yup.boolean(),
  stateTaxID: Yup.string().when('stateTaxFree', {
    is: false,
    then: (schema) => schema.required('"Inscrição Estadual" é um campo obrigatório'),
    otherwise: (schema) => schema,
  }),
  cityTaxFree: Yup.boolean(),
  cityTaxID: Yup.string().when('cityTaxFree', {
    is: false,
    then: (schema) => schema.required('"Inscrição Municipal" é um campo obrigatório'),
    otherwise: (schema) => schema,
  }),
  incorporationDate: Yup.string().required('"Data de Abertura" é um campo obrigatório'),
  equity: Yup.string().required('"Patrimônio Líquido" é um campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('"E-mail" é um campo obrigatório'),
  mobilePhoneNumber: Yup.string().required('"Telefone" é um campo obrigatório'),
  relationshipTypeID: Yup.string().required('Tipo de vínculo é um campo obrigatório'),
  businessTypeID: Yup.string().required('"Ramo de Atividade" é um campo obrigatório'),

  postalCode: Yup.string().required('"CEP" é um campo obrigatório'),
  line1: Yup.string().required('"Endereço" é um campo obrigatório'),
  houseNumber: Yup.string().required('"Número" é um campo obrigatório'),
  neighborhood: Yup.string().required('"Bairro" é um campo obrigatório'),
  level2AdminDivID: Yup.string().required('"Cidade" é um campo obrigatório'),
  level1AdminDivID: Yup.string().required('"Estado" é um campo obrigatório'),
  countryID: Yup.string().required('"País" é um campo obrigatório'),
});
