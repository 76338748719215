import styled, { css } from 'styled-components';

export const PrevButton = styled.button`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 1px solid ${theme.colors.black10};
    border-radius: 4px;
    color: ${theme.colors.black00};
    font-size: 1.6rem;
    margin: 0 12px 0 0;

    transition: ${theme.transitions.default};

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border-color: ${theme.colors.black00};
    }
  `};
`;

export const Container = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    background-color: ${theme.colors.white00};

    position: sticky;
    top: 0;
    z-index: 9;

    padding: 24px;

    > h1 {
      color: ${theme.colors.black00};
      letter-spacing: -0.01em;
      line-height: 3rem;
      font-size: 2.4rem;
      font-weight: 600;

      display: flex;
      flex-direction: column;

      > small {
        font-weight: 400;
        opacity: 0.5;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }

      > span {
        display: flex;
        align-items: center;

        > button {
          margin: 0 12px 0 0;
        }
      }
    }

    > button {
      border: 1px solid rgba(20, 18, 30, 0.1);
      border-radius: 4px;
      height: 36px;
      color: ${theme.colors.black00};
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.6rem;
      padding: 10px 18px;
      background-color: transparent;
      margin-top: auto;
    }
  `};
`;
