import React, { ReactElement, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiCheckLine } from 'react-icons/ri';

import { useFormikContext } from 'formik';

import { Button, LeaveModal } from '..';

import { OperationStepsContext } from '../../pages/Operations';

import { FooterProps } from './footer.spec';

import {
  Container, StepsNavigation, StepsList, FooterButtons,
} from './styles';
import { OperationInputContext } from '../../routes/AuthenticatedRoutes';

const Footer = ({
  steps = 0,
  currentStep = 1,
  buttonMessage = '',
  buttonLoadingMessage = '',
  isLoading = false,
  disabled = false,
  handleClick,
}: FooterProps): ReactElement => {
  const routerHistory = useNavigate();
  const [visibleLeaveConfirmation, setVisibleLeaveConfirmation] = useState<boolean>(false);
  const { setSimulateValue } = useContext(OperationInputContext);

  const renderElements = (count: number): ReactElement[] => Array.from({ length: count }, (_: undefined, i: number) => {
    const activeStep: boolean = i === currentStep - 1;
    return (
      <span className={activeStep ? 'active' : ''} key={i + 1}>
        {currentStep > i + 1 ? <RiCheckLine /> : i + 1}
      </span>
    );
  });

  function handleLeaveOperation(): void {
    routerHistory('/');
  }

  return (
    <Container>
      <StepsNavigation>
        <small>Etapas:</small>
        <StepsList>{renderElements(steps)}</StepsList>
      </StepsNavigation>
      <FooterButtons>
        <button
          type="button"
          onClick={() => setVisibleLeaveConfirmation(!visibleLeaveConfirmation)}
        >
          Fechar
        </button>
        <Button
          isLoading={isLoading}
          type="submit"
          message={buttonMessage}
          disabled={isLoading || disabled}
          loadingMessage={buttonLoadingMessage}
          onClick={handleClick}
        />
      </FooterButtons>
      {visibleLeaveConfirmation && (
        <LeaveModal
          handleCloseModal={() => setVisibleLeaveConfirmation(!visibleLeaveConfirmation)}
          handleDeleteConfirmation={() => { handleLeaveOperation(); setSimulateValue({}); }}
        />
      )}
    </Container>
  );
};

export default Footer;
