import { faker } from '@faker-js/faker';
import { AgentDTO, AgentSDTO } from '../@types';

// Table fields header & content
export const partnerTableHeaderTitles = ['Avatar', 'Nome', 'E-mail', 'Tipo de agente'];
export const partnerTableContentKeys = ['avatar', 'fullName', 'email', 'type'];

export const listSelect = ['Matriz', 'Filial'];
export const avatarList = ['ZE', 'KR', 'LS', 'PN'];

export const partnerItems: AgentSDTO[] = [];
export const getOne: AgentDTO = createRandomPartner();

export function createRandomPartnerItem(): any {
  return {
    avatar: faker.helpers.arrayElement(avatarList),
    fullName: faker.name.fullName(),
    email: faker.internet.email(),
    type: faker.helpers.arrayElement(listSelect),
  };
}

Array.from({ length: 4 }).forEach(() => {
  partnerItems.push(createRandomPartnerItem());
});

export function createRandomPartner(): any {
  return {

    id: faker.datatype.uuid(),
    typeUser: faker.helpers.arrayElement(listSelect),
    status: faker.helpers.arrayElement(listSelect),
    cnpj: faker.phone.number('9999999999'),
    companyName: faker.name.fullName(),
    tradeName: faker.name.fullName(),
    descriptionActivities: faker.helpers.arrayElement(listSelect),
    inc: faker.helpers.arrayElement(listSelect),
    email: faker.internet.email(),
    telephone: faker.phone.number('9999999999'),
    phone: faker.phone.number('9999999999'),
    constitutionDate: faker.date.birthdate(),
    socialCapital: faker.finance.amount(),
    netWorth: faker.finance.amount(),
    numberEmployees: faker.helpers.arrayElement(listSelect),
    registrationStatus: faker.helpers.arrayElement(listSelect),
    inscriptionState: faker.datatype.number(),
    observation: faker.commerce.productDescription(),

    address: {
      postalCode: faker.address.zipCode(),
      street: faker.address.street(),
      number: faker.address.buildingNumber(),
      complement: faker.address.street(),
      neighborhood: faker.address.stateAbbr(),
      city: faker.address.city(),
      state: faker.address.state(),
    },
    businessPartners: [
      {
        id: faker.datatype.uuid(),
        name: faker.name.fullName(),
        cpf: faker.phone.number('9999999999'),
        civilStatus: faker.helpers.arrayElement(listSelect),
        telephone: faker.phone.number(),
        phone: faker.phone.number(),
        nationality: faker.helpers.fake('Brasileiro'),
        birthDate: faker.date.birthdate(),
        gender: faker.helpers.arrayElement(listSelect),
        rg: faker.phone.number('9999999999'),
        issuingAgency: faker.helpers.fake('SSI'),
        shippingDate: faker.date.birthdate(),
        education: faker.helpers.arrayElement(listSelect),
        email: faker.internet.email(),
        naturalness: faker.helpers.fake('Brasileiro'),
        ufNaturalness: faker.helpers.fake('RS'),
        responsability: faker.helpers.arrayElement(listSelect),
        address: {
          postalCode: faker.address.zipCode(),
          street: faker.address.street(),
          number: faker.address.buildingNumber(),
          complement: faker.address.street(),
          neighborhood: faker.address.stateAbbr(),
          city: faker.address.city(),
          state: faker.address.state(),
        },
        description: faker.commerce.productDescription(),
      },
    ],
    documents: [
      {
        id: faker.datatype.number(),
        filename: faker.system.fileName(),
        size: faker.datatype.number(),
        mimeType: faker.system.mimeType(),
      },
    ],
    accessControl: {
      accessDays: faker.helpers.arrayElement(listSelect),
      accessTime: faker.helpers.arrayElement(listSelect),
      accessExternal: faker.helpers.arrayElement(listSelect),
      changeRates: faker.helpers.arrayElement(listSelect),
    },

    social: {
      urlCompany: faker.internet.url(),
      urlFacebook: faker.internet.url(),
      urlInstagram: faker.internet.url(),
      urlLinkedIn: faker.internet.url(),
    },
  };
}

export default partnerItems;
