import { FormikProps } from 'formik';
import * as Yup from 'yup';
import { createContext } from 'react';
import { AgentDTO, CoreFile } from '../../../../../@types';
import { DynamicTagsProps } from '../../../../../@types/DynamicTags';
import { format } from '../../../../../utils/masks';

export function getInitialValues(agent: AgentDTO, agencyOfficeID: number): AgentDTO {
  return {
    agencyID: agent?.agencyID,
    agencyOfficeID: agent?.agencyOfficeID || agencyOfficeID,
    personID: agent?.personID,
    person: {
      email: agent?.person?.email,
      fullName: agent?.person?.fullName,
      displayName: agent?.person?.displayName,
      documentNumber: agent?.person?.documentNumber,
      mobilePhoneNumber: agent?.person?.mobilePhoneNumber,
      landlinePhoneNumber: agent?.person?.landlinePhoneNumber,
      mothersFullName: agent?.person?.mothersFullName,
      fathersFullName: agent?.person?.fathersFullName,
      nationality: agent?.person?.nationality,
      birthplace: agent?.person?.birthplace,
      birthdate: agent?.person?.birthdate,
      civilStatusID: agent?.person?.civilStatusID,
      educationLevelID: agent?.person?.educationLevelID,
      hasAuth: agent?.person?.hasAuth || false,
    },
  };
}

export interface AgentCore {
  id: string;
  responsability?: string;
  documentNumber: string;
  fullName: string;
  birthdate: string;
  civilStatusID: string;
  sexID: string;
  mobilePhoneNumber: string;
  email: string;
  netWorth: string;
  professionID: string;
  income: string;
  companyDocumentNumber: string;
  companyLegalName: string;
  companyEmail: string;
  companyPercent: string;
  observations: string;
  attachments: CoreFile[];
  relationshipTypeID: string;
}

export const INITIAL_VALUES: AgentCore = {
  id: '',
  responsability: '',
  documentNumber: '',
  fullName: '',
  birthdate: '',
  civilStatusID: '',
  sexID: '',
  mobilePhoneNumber: '',
  email: '',
  netWorth: '',
  professionID: '',
  income: '',
  companyDocumentNumber: '',
  companyLegalName: '',
  companyEmail: '',
  companyPercent: '',
  observations: '',
  attachments: [],
  relationshipTypeID: '',
};

export interface AgentProps {
  agentList: AgentCore[];
  setAgentList: (agentList: AgentCore[]) => void;
}

export const AGENT_YUP_SCHEMA = Yup.object<AgentCore>().shape({
  documentNumber: Yup.string().required('"CPF" é um campo obrigatório'),
  fullName: Yup.string().required('"Nome completo" é um campo obrigatório'),
  birthdate: Yup.string().required('"Data de nascimento" é um campo obrigatório'),
  civilStatusID: Yup.string().required('"Estado civil" é um campo obrigatório'),
  sexID: Yup.string().required('"Sexo" é um campo obrigatório'),
  mobilePhoneNumber: Yup.string().required('"Celular" é um campo obrigatório'),
  email: Yup.string().required('"E-mail" é um campo obrigatório'),
  netWorth: Yup.string().required('"Patrimônio" é um campo obrigatório'),
  professionID: Yup.string().required('"Profissão" é um campo obrigatório'),
  income: Yup.string().required('"Renda" é um campo obrigatório'),
  companyDocumentNumber: Yup.string().required('"CNPJ" é um campo obrigatório'),
  companyLegalName: Yup.string().required('"Razão social" é um campo obrigatório'),
  companyPercent: Yup.string().required('"Percentual" é um campo obrigatório'),
});

interface AgentContextValue {
  formik: FormikProps<AgentCore>;
}

export const AgentContext = createContext({} as AgentContextValue);

export const dinymacTags: DynamicTagsProps[] = [
  {
    title: '',
    field: 'customerTypeID',
    bgColor: '#EDF7FC',
  },
  {
    title: 'Taxa:',
    field: 'fee',
    bgColor: '#EDFCF2',
    suffix: '%',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'TAC:',
    field: 'tac',
    bgColor: '#EDFCF2',
    suffix: '%',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'Parc. mín:',
    field: 'minInstallmentQuantity',
    bgColor: '#FCEDED',
  },
  {
    title: 'Parc. máx:',
    field: 'maxInstallmentQuantity',
    bgColor: '#FCEDED',
  },
  {
    title: 'Valor mín:',
    field: 'minValue',
    prefix: 'R$',
    bgColor: '#FCF9ED',
    labelFormatter: (value: any) => format(value),
  },
  {
    title: 'Valor máx:',
    field: 'maxValue',
    prefix: 'R$',
    bgColor: '#FCF9ED',
    labelFormatter: (value: any) => format(value),
  },
];
