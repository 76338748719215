import React, { ReactElement } from 'react';

import { cnpjMask, phoneMask9 } from '../../../utils/masks';

import {
  DateInput as InputDate,
  Input as InputControl,
  MaskedInput as InputMask,
} from '../..';

import {
  InputControlRow,
} from './styles';

const LegalPerson = (): ReactElement => (
  <>
    <InputControlRow>
      <InputMask
        name="cpnj"
        label="CNPJ"
        placeholder="00.000.0000/0000-00"
        guide={false}
        mask={cnpjMask}
        // value={formikProps.values.cpnj}
        // onChange={({ target: { value } }) => formikProps.setFieldValue(
        //   'cpnj',
        //   value?.replace(/\D/g, ''),
        // )}
        required
      />
      <InputControl
        name="corporaName"
        label="Razão social"
        placeholder="Digite a razão social da empresa"
        // value={formikProps.values.corporateName}
        // onChange={formikProps.handleChange}
        required
      />
    </InputControlRow>
    <InputControlRow>
      <InputMask
        name="phone"
        label="Celular"
        placeholder="(99) 9 9999 9999"
        guide={false}
        mask={phoneMask9}
        // value={formikProps.values.phone}
        // onChange={({ target: { value } }) => formikProps.setFieldValue(
        //   'phone',
        //   value?.replace(/\D/g, ''),
        // )}
        required
      />
      <InputControl
        name="email"
        type="email"
        label="E-mail"
        placeholder="seuemail@exemplo.com"
        // value={formikProps.values.email}
        // onChange={formikProps.handleChange}
        required
      />
    </InputControlRow>
  </>
);

export default LegalPerson;
