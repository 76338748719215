import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 24px;
`;

export const SeparatorContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    max-width: 100%;

    > h2 {
      width: 100%;
      max-width: fit-content;
      ${theme.colors.black00}
      ${theme.texts.headingXXXS}
    }
  `}
`;

export const Separator = styled.hr`
    ${({ theme }) => css`
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.black10};
      border: none;
    `}
`;

export const InputSolo = styled.div`
  display: flex;
  /* justify-content: space-between; */
  /* flex: 1; */

  margin-bottom: 24px;

  > div {
    width: 100%;

    > textarea {
      height: 100px;
    }
  }
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.black10};
    width: 100%;
    position: absolute;
    bottom: 90px;
    left: 0;
  `}
`;

export const Bottom = styled.div`
  ${({ theme }) => css`
    padding: 16px 0 0 0;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    > button {
      padding: 10px 20px;
      max-width: fit-content;
      ${theme.texts.buttonXS};
    }
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto 0 0 0;
    padding: 24px 0 0 0;
    border-top: 1px solid ${theme.colors.black10};
  `};
`;

export const StepsNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > small {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
    }
  `};
`;

export const StepsList = styled.div`
  ${({ theme }) => css`
    display: flex;

    margin: 0 0 0 2px;

    > span {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black00};
      padding: 3.5px 12px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:not(:last-child) {
        border-right: 0.5px solid ${theme.colors.black10};
      }

      > svg {
        font-size: 1.4rem;
        color: ${theme.colors.success00};
      }
    }
  `};
`;

export const FooterButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      height: 42px;

      &:first-child {
        background-color: transparent;
        margin: 0 6px 0 0;
        padding: 10px 20px;
        ${theme.texts.buttonXS};
      }
    }
  `};
`;
