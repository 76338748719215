import { BaseService } from './baseService';
import { ProfessionDTO, ProfessionSDTO } from '../@types/Profession';

class ProfessionService extends BaseService<
  ProfessionDTO,
  ProfessionSDTO
> {
  baseURL = '/professions';
}

export default new ProfessionService();
