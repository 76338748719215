import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { CoreCustomer, CoreFile } from '../../../../@types';
import { InfoMode } from '../../../../@types/InfoMode';

export interface CustomerInfoProps {
  mode: InfoMode;
}

export interface CustomerContextValue {
  formik: FormikProps<CoreCustomer>;
  setDocuments: Dispatch<SetStateAction<CoreFile[]>>;
  documents: CoreFile[];
  mode: InfoMode;
}

export function getInitialValues(customer: CoreCustomer): CoreCustomer {
  return {
    id: customer?.id || '',
    typeUser: customer?.typeUser || '',
    status: customer?.status || '',
    name: customer?.name || '',
    cpf: customer?.cpf || '',
    civilStatus: customer?.civilStatus || '',
    phone: customer?.phone || '',
    birthDate: customer?.birthDate || new Date(),
    email: customer?.email || '',
    occupation: customer?.occupation || '',
    income: customer?.income || '',
    netWorth: customer?.netWorth || '',
    ownCompany: customer?.ownCompany || false,
    address: {
      postalCode: customer?.address?.postalCode || '',
      street: customer?.address?.street || '',
      number: customer?.address?.number || '',
      complement: customer?.address?.complement || '',
      neighborhood: customer?.address?.neighborhood || '',
      city: customer?.address?.city || '',
      state: customer?.address?.state || '',
    },
    documents: customer?.documents || [],
    guarantors: customer?.guarantors?.map?.((item) => ({
      id: item?.id || '',
      name: item?.name || '',
      cpf: item?.cpf || '',
      civilStatus: item?.civilStatus || '',
      phone: item?.phone || '',
      birthDate: item?.birthDate || new Date(),
      email: item?.email || '',
      occupation: item?.occupation || '',
      income: item?.income || '',
      netWorth: item?.netWorth || '',
      spouse: item?.spouse || false,
    })) || [],

  };
}
