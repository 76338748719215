import { useFormik } from 'formik';
import React, { ReactElement, useContext } from 'react';
import { RiCheckLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useSteps } from 'react-step-builder';
import { cepMask } from '../../utils/masks';

import { OperationStepsContext } from '../../pages/Operations';

import {
  Button,
  Modal,
  Loading,
  MaskedInput as InputMask,
  Input as InputControl,
  OperationStepFormBase,
} from '..';

import { initialValues } from '../OnboardingModal/Onboarding.spec';

import {
  Container,
  Form,
  InputControlRow,
  Footer,
  StepsNavigation,
  StepsList,
  FooterButtons,
} from './styles';

const Address = (): ReactElement => {
  const { setCurrentOperationStep, handleStepSubmission } = useContext(OperationStepsContext);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues,
    onSubmit(values: any) {
      handleStepSubmission(values);
    },
  });

  return (
    <OperationStepFormBase handleSubmit={formik.handleSubmit}>
      <Container>
        <Form onSubmit={formik.handleSubmit}>
          <InputControlRow>
            <InputMask
              name="postalCode"
              label="CEP"
              placeholder="Digite seu CEP"
              guide={false}
              mask={cepMask}
              value={formik.values.postalCode}
              onChange={({ target: { value } }) => formik.setFieldValue('postalCode', value?.replace(/\D/g, ''))}
              required
            />
            <InputControl
              name="street"
              label="Endereço"
              placeholder="Digite seu endereço"
              value={formik.values.street}
              onChange={formik.handleChange}
              required
            />
          </InputControlRow>
          <InputControlRow>
            <InputControl
              name="number"
              type="number"
              label="Número"
              placeholder="Digite seu número"
              value={formik.values.number}
              onChange={formik.handleChange}
              required
            />
            <InputControl
              name="complement"
              label="Complemento"
              placeholder="Digite seu complemento"
              value={formik.values.complement}
              onChange={formik.handleChange}
            />
          </InputControlRow>
          <InputControlRow>
            <InputControl
              name="neighborhood"
              label="Bairro"
              placeholder="Digite seu bairro"
              value={formik.values.neighborhood}
              onChange={formik.handleChange}
              required
            />
            <InputControl
              name="city"
              label="Cidade"
              placeholder="Digite a sua cidade"
              value={formik.values.city}
              onChange={formik.handleChange}
              required
            />
          </InputControlRow>
          <InputControlRow>
            <InputControl
              name="state"
              label="Estado"
              placeholder="Digite o seu estado"
              value={formik.values.state}
              onChange={formik.handleChange}
              required
            />

            <InputControl
              name="country"
              label="País"
              placeholder="Digite o seu país"
              value={formik.values.country}
              onChange={formik.handleChange}
              required
            />
          </InputControlRow>
        </Form>
      </Container>
    </OperationStepFormBase>
  );
};

export default Address;
