import React, { createContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';
import Routes from './routes';
import './locales/i18n';

import * as allThemes from './styles/themes';
import GlobalStyles from './styles/globalStyles';
import useAppTheme from './contexts/ThemeContext';
import Loading from './components/Loading';

export const AppContext = createContext({} as any);

const App: React.FC = () => {
  const { currentTheme } = useAppTheme();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <ThemeProvider theme={allThemes[currentTheme]}>
      <AppContext.Provider value={{
        loading,
        setLoading,
      }}
      >
        <AuthProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          <GlobalStyles />
        </AuthProvider>
        {loading && (
          <Loading />
        )}
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;
