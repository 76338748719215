import { FormikProps } from 'formik';
import * as Yup from 'yup';

export interface DataPreReviewPersonProps {
  parentFormik: FormikProps<PersonForm>;
}

export interface PersonForm {
  documentNumber: string;
  fullName: string;
  birthdate: string | Date;
  mobilePhoneNumber: string;
  email: string;
}

export interface CompanyForm {
  documentNumber: string;
  legalName: string;
  mobilePhoneNumber: string;
  email: string;
}

export const PERSON_VALUES: PersonForm = {
  documentNumber: '',
  fullName: '',
  birthdate: '2000-01-01',
  mobilePhoneNumber: '',
  email: '',
};

export const PersonYupSchema = Yup.object<PersonForm>().shape({
  documentNumber: Yup.string().required('"CPF" é um campo obrigatório'),
  fullName: Yup.string().required('"Nome completo" é um campo obrigatório'),
  birthdate: Yup.string().required('"Data de nascimento" é um campo obrigatório'),
  mobilePhoneNumber: Yup.string().required('"Celular" é um campo obrigatório'),
  email: Yup.string().required('"E-mail" é um campo obrigatório').email('"E-mail" deve ter um e-mail válido'),
});

export const COMPANY_VALUES: CompanyForm = {
  documentNumber: '',
  legalName: '',
  mobilePhoneNumber: '',
  email: '',
};

export const CompanyYupSchema = Yup.object<CompanyForm>().shape({
  documentNumber: Yup.number().required('"CNPJ" é um campo obrigatório'),
  legalName: Yup.string().required('"Razão social" é um campo obrigatório'),
  mobilePhoneNumber: Yup.number().required('"Celular" é um campo obrigatório'),
  email: Yup.string().required('"E-mail" é um campo obrigatório').email('"E-mail" deve ter um e-mail válido'),
});

export const INITIAL_VALUES = {
  PERSON: PERSON_VALUES,
  COMPANY: COMPANY_VALUES,
};

export const YUP_SCHEMAS = {
  PERSON: PersonYupSchema,
  COMPANY: CompanyYupSchema,
};
