import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > label {
      ${theme.texts.paragraphXXS};
      margin: 0 0 8px 0;

      > small {
        color: ${theme.colors.black50};
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
    }

    .ant-picker {
      &.ant-picker-disabled {
        background: ${theme.colors.black20};
        cursor: default;
        border: none;
        border-radius: 4px;

        .ant-picker-input {
          color: ${theme.colors.black00};

          > input {
          color: ${theme.colors.black00};
          cursor: default;

          }
          .ant-picker-suffix {
            display: none;
          }
        }
      }

      .ant-picker-input {
         
          .ant-picker-suffix {
            color: ${theme.colors.black00};
          }
        }
    }
  `};
`;
