import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  margin-bottom: 32px;
`;

export const CheckContent = styled.div<{
  checked?: boolean;
  required?: boolean;
}>`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    padding: 10px;
    margin-bottom: 8px;
    border-radius: 6px;

    > svg {
      font-size: 1.4rem;
    }

    > h3 {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black00};
      margin: 0 auto 0 10px;
    }
  `}

  ${({ checked, required }): any => {
    if (checked) {
      return css`
        background-color: ${({ theme }) => theme.colors.success10};
      `;
    }
    if (required) {
      return css`
        background-color: ${({ theme }) => theme.colors.alert10};
      `;
    }
    return css`
      background-color: ${({ theme }) => theme.colors.white00};
    `;
  }}
`;

export const Circle = styled.div<{ checked?: boolean; required?: boolean }>`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      font-size: 1.6rem;
    }
  `}

  ${({ checked, required }): any => {
    if (checked) {
      return css`
        > svg {
          color: ${({ theme }) => theme.colors.success00};
          background-color: ${({ theme }) => theme.colors.white00};
          border-radius: 50%;
        }
      `;
    }
    if (required) {
      return css`
        > svg {
          color: ${({ theme }) => theme.colors.alert00};
          background-color: ${({ theme }) => theme.colors.white00};
          border-radius: 50%;
        }
      `;
    }
    return css`
      width: 100%;
      max-width: 14px;
      height: 14px;
      margin: 1px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.white00};
      border: 1px solid ${({ theme }) => theme.colors.black00};
    `;
  }}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.black10};

    position: absolute;
    left: 0;
    bottom: 90px;
  `};
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto 0 0 0;
    padding: 24px 0 0 0;
    border-top: 1px solid ${theme.colors.black10};
  `};
`;

export const StepsNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > small {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
    }
  `};
`;

export const StepsList = styled.div`
  ${({ theme }) => css`
    display: flex;

    margin: 0 0 0 2px;

    > span {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black30};
      padding: 3.5px 12px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:not(:last-child) {
        border-right: 0.5px solid ${theme.colors.black10};
      }

      > svg {
        font-size: 1.4rem;
        color: ${theme.colors.success00};
      }

      &.active {
        color: ${theme.colors.black00};
      }
    }
  `};
`;

export const FooterButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      height: 42px;

      &:first-child {
        background-color: transparent;
        margin: 0 6px 0 0;
        padding: 10px 20px;
        ${theme.texts.buttonXS};
      }
    }
  `};
`;
