import { css } from 'styled-components';

export const headingXXXL = css`
  font-weight: 600;
  font-size: 5.6rem;
  line-height: 6.4rem;
  letter-spacing: -1.3px;
`;

export const headingXXL = css`
  font-weight: 600;
  font-size: 4.2rem;
  line-height: 4.4rem;
  letter-spacing: -1.3px;
`;

export const headingXL = css`
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 3.4rem;
  letter-spacing: -1px;
`;

export const headingLG = css`
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 3.6rem;
  letter-spacing: -0.9px;
`;

export const headingMD = css`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3rem;
  letter-spacing: -0.5px;
`;

export const headingSM = css`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.4rem;
`;

export const headingXS = css`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
`;

export const headingXXS = css`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

export const headingXXXS = css`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

export const paragraphXXXL = css`
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 4.4rem;
  letter-spacing: -0.2px;
`;

export const paragraphXXL = css`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.4rem;
  letter-spacing: -0.2px;
`;

export const paragraphXL = css`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

export const paragraphLG = css`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

export const paragraphMD = css`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.2rem;
`;

export const paragraphSM = css`
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.2rem;
`;

export const paragraphXS = css`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

export const paragraphXXS = css`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

export const paragraphXXXS = css`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6rem;
`;

export const buttonXXXL = css`
  font-weight: 600;
  font-size: 3.2rem;
  line-height: 4rem;
  letter-spacing: -0.2px;
`;

export const buttonXXL = css`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.2rem;
  letter-spacing: -0.2px;
`;

export const buttonXL = css`
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.4rem;
  letter-spacing: -0.2px;
`;

export const buttonLG = css`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.2rem;
  letter-spacing: -0.2px;
`;

export const buttonMD = css`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.2rem;
`;

export const buttonSM = css`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2.2rem;
`;

export const buttonXS = css`
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2.2rem;
`;

export const buttonXXS = css`
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2.2rem;
`;

export const buttonXXXS = css`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

export const textXXXL = css`
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 4.4rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const textXXL = css`
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const textXL = css`
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 3.6rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const textLG = css`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.2rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const textMD = css`
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const textSM = css`
font-weight: 500;
font-size: 1.6rem;
line-height: 2.4rem;
letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const textXS = css`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const textXXS = css`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const textXXXS = css`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.6rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;
