import { CoreFile } from '../../@types';
import { CustomerType } from '../../@types/CustomerData';
import { AgentCore } from '../CustomerData/CustomerDataSteps/Company/Agent/Agent.spec';
import { selectRelationshipCompany } from './GuarantorCompanyModal/GuarantorData';
import { selectRelationship } from './GuarantorPersonModal/GuarantorPersonModal.spec';

export type GuarantorType = 'PERSON' | 'COMPANY';

export const removeEmptyFields = (obj: any): any => Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== ''));

export function getRelationshipByCustomerType(customerType: CustomerType | undefined, relationshipTypeID: string): string | undefined {
  return customerType === 'PERSON'
    ? selectRelationship.find(({ id }) => Number(relationshipTypeID) === id)?.text
    : selectRelationshipCompany.find(({ id }) => Number(relationshipTypeID) === id)?.text;
}
export interface GuarantorDataCore {

  // Guarantor Data
  fullName: string,
  documentNumber: string,
  birthdate: string,
  civilStatusID: string,
  mobilePhoneNumber: string,
  email: string,
  professionID: string,
  income: string,
  netWorth: string,
  relationshipTypeID: string,
  sexID: string,
  hasCompany: boolean,

  // Company Data
  companyDocumentNumber: string,
  legalName: string,
  tradeName: string,
  landlinePhoneNumber: string,
  companyBranch: string,
  stateTaxID: string,
  cityTaxID: string,
  municipalInscription: string,
  incorporationDate: string,
  companyRelationship: string,
  equity: string,
  description: string,
  shareCapital: string,
  companyRegistrationStatusID: string,
  employeeCountRangeID: string,
  businessTypeID: string,
  companyRelationships: number[],
  stateTaxFree: boolean,
  cityTaxFree: boolean,

  // Address data
  postalCode: string,
  line1: string,
  houseNumber: string,
  complement: string,
  neighborhood: string,
  level2AdminDivID: string,
  level1AdminDivID: string,
  countryID: string,

  // others
  attachments: CoreFile[],
  agents: AgentCore[],
  accounts: any[],
  socialNetworks: any[],
  customerType?: 'PERSON' | 'COMPANY',
}

export const INITIAL_VALUES: GuarantorDataCore = {
  // Guarantor Data
  documentNumber: '',
  fullName: '',
  birthdate: '',
  civilStatusID: '',
  mobilePhoneNumber: '',
  email: '',
  professionID: '',
  income: '',
  netWorth: '',
  relationshipTypeID: '',
  sexID: '',
  hasCompany: false,

  // Company Data
  companyDocumentNumber: '',
  legalName: '',
  tradeName: '',
  landlinePhoneNumber: '',
  companyBranch: '',
  stateTaxID: '',
  businessTypeID: '',
  cityTaxID: '',
  municipalInscription: '',
  incorporationDate: '',
  companyRelationship: '',
  equity: '',
  description: 'testeteste',
  shareCapital: '10000000',
  companyRegistrationStatusID: '10',
  employeeCountRangeID: '1',
  companyRelationships: [],
  stateTaxFree: false,
  cityTaxFree: false,

  // Address Data
  postalCode: '',
  line1: '',
  houseNumber: '',
  complement: '',
  neighborhood: '',
  level2AdminDivID: '',
  level1AdminDivID: '',
  countryID: '',

  // others
  attachments: [],
  accounts: [],
  socialNetworks: [],
  agents: [],
};
