import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: rgba(255, 193, 7, 0.5);
    color: ${theme.colors.black00};
    font-size: 1rem;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    margin: 0 auto;

    display: flex;
    justify-content: center;
    align-items: center;
  `};
`;

export const RoundedContainer = styled.span`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: ${theme.colors.info20};
    color: ${theme.colors.black00};
    font-size: 1.1rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;

export const StatusTagContainer = styled.div<{ bgColor?: string }>`
  ${({ theme, bgColor }) => css`
    background-color: ${bgColor};
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 500;
    ${theme.texts.paragraphXXXS};
    color: ${theme.colors.black00};
    padding: 6px 10px;
    height: 28px;
    border-radius: 2px;
    width: fit-content;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;
