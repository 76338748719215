import * as Yup from 'yup';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { ProductSDTO } from '../../../@types/Product';

export interface SimulatorModalProps {
  onClose?: () => void;
}

export interface SimulatorInitialValuesProps {
  productID: number | null;
  value: string;
  installmentQuantity: number | undefined;
  firstInstallmentDueDate: Moment | null | string;
  simplifiedTax: boolean;
}

export const simulatorInitialValues = (): SimulatorInitialValuesProps => ({
  productID: null,
  value: '',
  installmentQuantity: undefined,
  firstInstallmentDueDate: moment().add(1, 'month'),
  simplifiedTax: false,
});

export const validationSchema = Yup.object().shape({
  productID: Yup.number().required(
    'A seleção de produto é obrigatória',
  ),
  value: Yup.number().moreThan(0, 'O valor deve ser maior que 0').required('O valor é obrigatório'),
  installmentQuantity: Yup.number().moreThan(0, 'A quantidade de parcelas deve ser maior que 0').required('A quantidade de parcelas é obrigatória'),
  firstInstallmentDueDate: Yup.date().required('Data de 1º Vencimento é obrigatório'),
});

export type CustomerTypeTypes = 'PERSON' | 'COMPANY';

export interface ProductTypes {
  id: number;
  name: string;
  subtypeID: boolean;
  subtypeText: string;
  typeID: number;
  typeText: string;
  description: string;
  enabled: boolean;
  workflowID: number;
  createdAt: Date;
  updatedAt: Date;
}
