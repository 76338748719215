import styled, { css } from 'styled-components';
import { Form } from 'formik';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  > form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const StepsGuide = styled.div`
  display: flex;
`;

export const StepNumber = styled.div``;

export const OutletContent = styled.div`
  padding: 0 24px;
  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const FormContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
