import React, { ReactElement, useContext } from 'react';
import {
  RiBodyScanFill,
  RiCheckboxCircleFill,
  RiGroupFill,
  RiInformationFill,
  RiInformationLine,
  RiMapPin2Fill,
  RiMoneyDollarCircleFill,
  RiProfileFill,
  RiUser2Fill,
  RiCheckLine,
} from 'react-icons/ri';

import { useSteps } from 'react-step-builder';

import documentsItems from '../../mock/documents';

import Button from '../Button';
import Modal from '../Modal';
import { OperationStepsContext } from '../../pages/Operations';
// import Tooltip from '../Tooltip';

import {
  Container,
  Content,
  CheckContent,
  Circle,
} from './styles';

const DocumentsChecklist = (): ReactElement => {
  const { setCurrentOperationStep } = useContext(OperationStepsContext);
  const { next, prev, jump } = useSteps();

  const iconsList = [
    <RiMapPin2Fill />,
    <RiProfileFill />,
    <RiBodyScanFill />,
    <RiMoneyDollarCircleFill />,
    <RiUser2Fill />,
    <RiGroupFill />,
  ];
  return (
    <>
      <Container>
        <Content>
          {documentsItems.map((item, index) => (
            <CheckContent
              key={item?.id}
              checked={item.checked}
              required={item.required}
            >
              {iconsList[index]}
              <h3>{item.description}</h3>
              <Circle checked={item.checked} required={item.required}>
                {item.checked && <RiCheckboxCircleFill />}
                {!item.checked && item.required && (
                  <RiInformationFill id="dataTooltip" />
                )}
              </Circle>
            </CheckContent>
          ))}
        </Content>
      </Container>
    </>
  );
};

export default DocumentsChecklist;
