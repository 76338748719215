import { CoreCustomer } from '../@types';
import customerItems, { customerMe } from '../mock/customer';

export default {
  get(): any {
    try {
      return customerItems;
    } catch (error) {
      return console.log('Unable to list customers items!');
    }
  },

  async getMe(): Promise<any> {
    try {
      return await customerMe;
    } catch (error) {
      return console.log('Unable to get user informations!');
    }
  },

  edit(value: Partial<CoreCustomer>): any {
    try {
      return console.log(value);
    } catch (error) {
      return console.log('Unable edit customer item!');
    }
  },
};
