import React, { ReactElement } from 'react';
import Select, {
  components,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from 'react-select';

import { RiCloseLine, RiSearchLine } from 'react-icons/ri';
import { SearchableSelectProps } from './SearchableSelect.spec';

import { Container } from './styles';

const SearchableSelect = ({
  label = '',
  options = [],
  selectedProduct,
  onChange,
}: SearchableSelectProps): ReactElement => {
  const ClearIndicator = (propsDrop: ClearIndicatorProps): ReactElement => (
    <components.ClearIndicator {...propsDrop}>
      <RiCloseLine />
    </components.ClearIndicator>
  );

  const DropdownIndicator = (
    propsDrop: DropdownIndicatorProps,
  ): ReactElement => (
    <components.SelectContainer {...propsDrop}>
      <RiSearchLine />
    </components.SelectContainer>
  );

  return (
    <Container>
      <label>
        {label}
        {' '}
        <small>(obrigatório)</small>
      </label>
      <Select
        name="productID"
        isClearable
        options={options}
        className="react-select-container"
        classNamePrefix="react-select"
        placeholder="Buscar produto"
        components={{ DropdownIndicator, ClearIndicator }}
        noOptionsMessage={() => 'Não há opções'}
        value={selectedProduct}
        onChange={onChange}
        getOptionLabel={(option: any) => `${option.name}`}
        getOptionValue={(option: any) => option.id}
      />
    </Container>
  );
};

export default SearchableSelect;
