import React, {
  ReactElement, useEffect, useState,
} from 'react';
import { FormVehicleWarrantyProps } from './FormVehicleWarranty.spec';

import TabNavigation from '../../TabNavigation';
import { CoreFile } from '../../../@types';
import { WarrantyInfoForm } from './FormVehicleWarrantyTabs/WarrantyInfoForm';
import { Container } from '../styles';
import { UploadDocument } from '../../UploadDocument';

const FormVehicleWarranty = ({
  index, vehicleWarranty, formik,
}: FormVehicleWarrantyProps): ReactElement => {
  const tabs = [
    {
      title: 'Dados da garantia',
      element: <WarrantyInfoForm index={index} vehicleWarranty={vehicleWarranty} formik={formik} />,
      enabled: true,
    },
    {
      title: 'Documentos',
      element: <UploadDocument
        documents={formik.values.collateralVehicles[index].documents || []}
        handleChange={(documents) => formik.setFieldValue(`collateralVehicles[${index}].documents`, documents)}
      />,
      enabled: true,
    },
  ];

  return (
    <Container>
      <TabNavigation tabNavigationItems={tabs} />
    </Container>
  );
};

export default FormVehicleWarranty;
