import React from 'react';
import MaskedInput from 'react-text-mask';
import { RiSearchLine } from 'react-icons/ri';

import { InputMaskProps } from './MaskedInput.spec';
import {
  Container, CustomInput, LoadingCircle, Circle,
} from './styles';
import ErrorMessage from '../ErrorMessage';

const QueryInput = ({
  label = '',
  required,
  className,
  message,
  field,
  isLoading,
  ...inputProps
}: InputMaskProps): any => (
  <Container className={className}>
    <label>
      {label}
      {' '}
      {required && <small>(obrigatório)</small>}
    </label>
    <CustomInput disabled={inputProps?.disabled}>
      <MaskedInput inputMode="numeric" {...inputProps} {...field} />
      {isLoading ? (
        <LoadingCircle>
          <Circle />
        </LoadingCircle>
      ) : (
        <RiSearchLine />
      )}
    </CustomInput>
    {message && <ErrorMessage message={message} />}
  </Container>
);

QueryInput.defaultProps = {
  field: {},
};

export default QueryInput;
