import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoxLeft = styled.div`
  ${({ theme }) => css`
    > h2 {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
      margin-bottom: 8px;
    }

    > p {
      ${theme.texts.paragraphXXXS};
      color: ${theme.colors.black50};

      > span {
        color: ${theme.colors.black00};
      }
    }
  `}
`;

export const BoxRight = styled.div`
  ${({ theme }) => css`
    > button {
      height: 42px;
      padding: 10px 40.5px 10px 20px;

      border-radius: 4px;
      ${theme.texts.buttonXS};
    }

    > div {
       button {
      height: 42px;
      padding: 10px 40.5px 10px 20px;

      border-radius: 4px;
      ${theme.texts.buttonXS};
    }
    }
  `}
`;

export const NoContent = styled.div`
  ${({ theme }) => css`
  width: 100%;
  max-width: 412px;
  margin: 58px auto 0;
  text-align: center;

    > h2 {
      ${theme.texts.buttonLG};
      color: ${theme.colors.black00};
      margin: 24px 14px 14px 14px;
    }

    > p {
      ${theme.texts.paragraphMD};
      color: ${theme.colors.black00};
    }
  `}
`;
