import { faker } from '@faker-js/faker';
import { WorkflowTableProps } from '../@types';

// Table fields header & content
export const workflowTableHeaderTitles = ['Nome do workflow', 'Produtos vinculados', 'Criado em'];
export const workflowTableContentKeys = ['title', 'stepsQuantity', 'createdAt'];
const workflowPresets = [
  'Produtos com análise PEP',
  'Financiamento de veículo',
  'Financiamento de máquinas',
];

export const workflowTableItems: WorkflowTableProps[] = [];
export const getOne: WorkflowTableProps = createRandomWorkflow();

export function createRandomWorkflowItem(): WorkflowTableProps {
  return {
    id: faker.datatype.uuid(),
    title: faker.helpers.arrayElement(workflowPresets),
    stepsQuantity: faker.datatype.number({ min: 1, max: 30 }),
    createdAt: '03/03/2022 às 14:03',
  };
}

Array.from({ length: 4 }).forEach(() => {
  workflowTableItems.push(createRandomWorkflowItem());
});

export function createRandomWorkflow(): WorkflowTableProps {
  return {
    id: faker.datatype.uuid(),
    title: faker.helpers.arrayElement(workflowPresets),
    stepsQuantity: faker.datatype.number({ min: 1, max: 30 }),
    createdAt: faker.date.past(),
  };
}

export default workflowTableItems;
