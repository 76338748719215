import * as Yup from 'yup';
import { GuarantorDataCore } from '../GuarantorData.spec';

export const selectRelationship = [{
  id: 1,
  text: 'Cônjuge',
}];

export const YUP_SCHEMA = Yup.object<GuarantorDataCore>().shape({
  // guarantor data
  documentNumber: Yup.string().required('"CPF" é um campo obrigatório'),
  fullName: Yup.string().required('"Nome completo" é um campo obrigatório'),
  birthdate: Yup.string().required('"Data de nascimento" é um campo obrigatório'),
  mobilePhoneNumber: Yup.string().required('"Celular" é um campo obrigatório'),
  email: Yup.string().required('"E-mail" é um campo obrigatório').email('"E-mail" deve ter um e-mail válido'),
  professionID: Yup.string().required('"Profissão" é um campo obrigatório'),
  income: Yup.string().required('"Renda" é um campo obrigatório'),
  netWorth: Yup.string().required('"Patrimônio líquido" é um campo obrigatório'),
  sexID: Yup.string().required('"Sexo" é um campo obrigatório'),
  civilStatusID: Yup.string().required('"Estado civil" é um campo obrigatório'),
  relationshipTypeID: Yup.string().required('"Tipo de vínculo" é um campo obrigatório'),
  postalCode: Yup.string().required('"CEP" é um campo obrigatório'),
  line1: Yup.string().required('"Endereço" é um campo obrigatório'),
  houseNumber: Yup.string().required('"Número" é um campo obrigatório'),
  neighborhood: Yup.string().required('"Bairro" é um campo obrigatório'),
  level2AdminDivID: Yup.string().required('"Cidade" é um campo obrigatório'),
  level1AdminDivID: Yup.string().required('"Estado" é um campo obrigatório'),
  countryID: Yup.string().required('"País" é um campo obrigatório'),
});
