import React, { ReactElement, useEffect, useState } from 'react';
import ErrorMessage from '../ErrorMessage';

import { CheckboxInputProps } from './CheckboxInput.spec';
import { Container, Content, ContentCheckbox } from './styles';
import Checkbox from '../Checkbox';

function CheckboxInput({
  label = '',
  type = 'text',
  placeholder = '',
  className,
  message,
  isDisabledInput,
  field,
  required,
  onCheck,
  check,
  ...inputProps
}: CheckboxInputProps): ReactElement {
  return (
    // <Container className={message ? 'error' : ''}>
    <Container>
      <label>
        {label}
        {' '}
        {required && <small>(obrigatório)</small>}
      </label>
      <Content>
        <input
          type={type}
          placeholder={placeholder}
          disabled={check || isDisabledInput}
          {...field}
          {...inputProps}
        />
        <ContentCheckbox>
          <Checkbox checked={check} onChange={(e: any) => onCheck(!check)} disabled={isDisabledInput} />
          Isento
        </ContentCheckbox>
      </Content>
      <ErrorMessage message={message} />
    </Container>
  );
}

export default CheckboxInput;
