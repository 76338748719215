import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > label {
      ${theme.texts.paragraphXXS};
      margin: 0 0 8px 0;

      > small {
        color: ${theme.colors.black50};
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
    }
  `};
`;

export const CheckboxControl = styled.div<{ readonly?: boolean }>`
  ${({ theme }) => css`
    ${theme.texts.paragraphSM};

    display: flex;
    align-items: center;

    &.readonly {
      opacity: 0.5;
    }

    > label {
      width: 14px;

      > svg {
        width: 14px;
        height: 14px;
      }
      &:nth-child(2) {
        margin-left: 24px;
      }
    
    }
      
  `}
`;
