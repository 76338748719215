import _ from 'lodash';
import { FindOptions, Page } from '../@types/Core';
import { PersonSocialNetworkDTO, PersonSocialNetworkSDTO } from '../@types/PersonSocialNetwork';
import { I18nService } from './i18n';

class PersonSocialNetworkService extends I18nService<PersonSocialNetworkDTO, PersonSocialNetworkSDTO> {
  baseURL = '/person-social-networks';

  async getPageByPersonID(
    personID: number,
    findOptions?: FindOptions,
  ): Promise<Page<PersonSocialNetworkSDTO>> {
    return super.getPage({
      filters: _.merge(
        {
          personID: {
            $eq: personID,
          },
        },
        findOptions,
      ),
    });
  }
}

export default new PersonSocialNetworkService();
