import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  RiAddFill,
  RiCheckLine, RiCloseLine, RiDeleteBin6Line, RiExternalLinkLine,
} from 'react-icons/ri';
import Button from '../../../../Button';
import { usePaginationHelper } from '../../../../../hooks/paginationHelper';
import { PersonSocialNetworkDTO, PersonSocialNetworkSDTO } from '../../../../../@types/PersonSocialNetwork';
import { personSocialNetworkService, socialNetworkService } from '../../../../../services';
import {
  Container, Content, ContentSocial, Dropdown, DropdownItem, InputControl, InputItem, NewItemContent, NoContent,
} from './styles';
import { InfoMode } from '../../../../../@types/InfoMode';
import EmptyTable from '../../../../../assets/EmptyTable.svg';

export function SocialNetwork({ personID, mode }: any): ReactElement {
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [socialList, setSocialList] = useState<any[]>([]);
  const [newSocial, setNewSocial] = useState<any>([]);

  useEffect(() => {
    fetchSocial();
  }, []);

  const {
    page: { content: personSocialList },
  } = usePaginationHelper<
    PersonSocialNetworkDTO,
    PersonSocialNetworkSDTO,
    typeof personSocialNetworkService
  >(personSocialNetworkService, {
    initialPageSize: 999,
    filters: {
      personID: {
        $eq: personID ? +personID : undefined,
      },
    },
  });

  async function fetchSocial(): Promise<void> {
    try {
      const content = await socialNetworkService.getList();
      setSocialList(content);
    } catch (error) {
      console.log(error);
    }
  }

  async function addSocial(values: any): Promise<void> {
    try {
      if (personID && values) {
        await personSocialNetworkService.create(values);
      }
      // refresh();
      setNewSocial(undefined);
    } catch (error) {
      console.log(error);
    }
  }

  async function removeSocial(id: any): Promise<void> {
    try {
      if (id) {
        await personSocialNetworkService.delete(id);
      }
      // refresh();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      <Content>
        {personSocialList.map((e) => (
          <ContentSocial key={e.id}>
            {/* Back needs to bring the name of the social network in the personSocialList */}
            <p>Nome da Rede Social</p>
            <InputItem>
              <InputControl disabled={mode === InfoMode.VIEW}>
                <input
                  defaultValue={e.url}
                  placeholder="usuario"
                  readOnly
                  disabled={mode === InfoMode.VIEW}
                />
                {mode === InfoMode.VIEW && (
                  <Link to={e.url || ''} target="_blank">
                    <RiExternalLinkLine />
                  </Link>
                )}
              </InputControl>
              {mode !== InfoMode.VIEW && (
                <button type="button" onClick={() => removeSocial(e.id)}>
                  <RiDeleteBin6Line />
                </button>
              )}
            </InputItem>
          </ContentSocial>
        ))}

        {newSocial && (
          <ContentSocial>
            <p>Nome da Rede Social</p>
            <InputItem>
              <InputControl>
                {newSocial.url}
                <input
                  value={newSocial.username}
                  placeholder="digite aqui"
                  onChange={(e) => setNewSocial({
                    ...newSocial,
                    url: newSocial.url,
                    username: e.target.value,
                    personID,
                  })}
                />
              </InputControl>
              <button
                type="button"
                onClick={() => addSocial(newSocial)}
                disabled={!newSocial.username}
              >
                <RiCheckLine />
              </button>

              <button type="button" onClick={() => setNewSocial(undefined)}>
                <RiCloseLine />
              </button>
            </InputItem>
          </ContentSocial>
        )}

        {personSocialList.length <= 0 && !newSocial && (
          <NoContent>
            <img src={EmptyTable} alt="EmptyTable" />
            <h4>{t('socialNetwork.textNoDataFound')}</h4>
            <p>{t('socialNetwork.textNoDataDisplay')}</p>
          </NoContent>
        )}
      </Content>

      {mode !== InfoMode.VIEW && (
        <NewItemContent>
          <Button
            message={t('socialNetwork.buttonAddSocialNetwork')}
            icon={<RiAddFill />}
            variant="secondary"
            onClick={() => setOpenDropdown(!openDropdown)}
          />

          {openDropdown && (
            <Dropdown>
              {socialList?.map((e) => (
                <DropdownItem
                  key={e.id}
                  className={
                    personSocialList.filter((i) => i.socialNetworkID === e.id)
                      .length > 0
                      ? 'disabled'
                      : ''
                  }
                  onClick={() => {
                    setNewSocial({
                      url: `${e.urlTemplate.slice(0, -8)}`,
                      socialNetworkID: e.id,
                    });
                    setOpenDropdown(false);
                  }}
                >
                  {e.name}
                </DropdownItem>
              ))}
            </Dropdown>
          )}
        </NewItemContent>
      )}
    </Container>
  );
}
