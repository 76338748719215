import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Timer = styled.div`
  ${({ theme }) => css`
    border: 1px solid rgba(20, 18, 30, 0.1);
    border-radius: 4px;
    padding: 10px 18px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.2rem;
    color: ${theme.colors.black00};
    max-height: 42px;

    display: flex;
    align-items: center;

    > svg {
      font-size: 1.4rem;
      margin-right: 10px;
    }

    > b {
      margin-left: 10px;
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 500;
    }
  `};
`;

export const Notification = styled.button`
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid rgba(20, 18, 30, 0.1);
  margin: 0 16px;
`;

export const Calendar = styled.span`
  ${({ theme }) => css`
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2.2rem;
    color: ${theme.colors.black00};

    display: flex;
    align-items: center;

    > svg {
      margin-right: 10px;
      font-size: 1.6rem;
    }

    &::after {
      content: '';
      display: block;
      width: 1.5px;
      height: 12px;
      background-color: ${theme.colors.black00};
      opacity: 0.1;
      margin-left: 14px;
    }
  `};
`;

export const UserInfos = styled.small`
  ${({ theme }) => css`
    color: ${theme.colors.black00};
    font-size: 1.3rem;
    line-height: 2.2rem;
    font-weight: 400;
    margin-left: 14px;

    display: flex;
    align-items: center;

    > b {
      margin-left: 4px;
    }

    > svg {
      font-size: 1.2rem;
      margin-left: 8px;
    }
  `};
`;

export const UserImage = styled.div`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    background-color: ${theme.colors.black00};
    border-radius: 50%;
    margin-left: 14px;
    ${theme.texts.buttonSM};
    color: ${theme.colors.white00};

    display: flex;
    align-items: center;
    justify-content: center;
  `};
`;
