import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 400px;
    background-color: ${theme.colors.white00};
    padding: 32px;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      max-width: 110px;
      width: 100%;
      margin: 0 0 24px 0;
    }

    > h1 {
      ${theme.texts.headingSM};
      letter-spacing: -0.5px;
      color: ${theme.colors.black00};
      margin: 0 0 16px 0;
      text-align: center;
    }

    > p {
      text-align: center;
      ${theme.texts.paragraphXS};
    }
  `};
`;

export const Buttons = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 24px 0 0 0;

    display: flex;
    flex-direction: column;

    > button {
      width: 100%;
      max-width: 100%;
      height: 46px;

      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin: 0 0 0 0;
        ${theme.texts.buttonXS};
      }

      &:last-child {
        background-color: transparent;
        color: ${theme.colors.black00};
        ${theme.texts.buttonXS};
      }
    }
  `};
`;
