import React, { ReactElement } from 'react';

import { Container, RoundedContainer, StatusTagContainer } from './styles';

import {
  ProductBrandProps,
  ResponsibleCircleProps,
  StatusTagProps,
} from './tableComponents.spec';

export const ProductBrand = ({
  icon = '',
}: ProductBrandProps): ReactElement => <Container>{icon}</Container>;

export const ResponsibleCircle = ({
  text = '',
}: ResponsibleCircleProps): ReactElement => (
  <RoundedContainer>{text}</RoundedContainer>
);

export const StatusTag = ({ status = '', bgColor = '' }: StatusTagProps): ReactElement => (
  <StatusTagContainer bgColor={bgColor}>{status}</StatusTagContainer>
);
