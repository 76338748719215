// import { UserDTO, UserSDTO } from '../@types/User';
import userItems from '../mock/user';
// import { BaseService } from './baseService';

// class UserService extends BaseService<UserDTO, UserSDTO> {
//   baseURL = '/users';
// }

// export default new UserService();

export default {
  get(): any {
    try {
      return userItems;
    } catch (error) {
      return console.log('Unable to list users items!');
    }
  },
};
