import summaryItems from '../mock/summary';

export default {
  get(): any {
    try {
      return summaryItems;
    } catch {
      return console.log('Unable to list summary items!');
    }
  },
};
