import styled, { css } from 'styled-components';

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white00};
    border-radius: 4px;
    padding: 32px;
    max-width: 315px;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    position: fixed;
    bottom: 11.5%;
    right: 4.2%;
    filter: drop-shadow(-4px 32px 13px rgba(49, 48, 64, 0.01)) drop-shadow(-2px 18px 11px rgba(49, 48, 64, 0.03)) drop-shadow(-1px 8px 8px rgba(49, 48, 64, 0.04)) drop-shadow(0px 2px 4px rgba(49, 48, 64, 0.05)) drop-shadow(0px 0px 0px rgba(49, 48, 64, 0.05));

    display: flex;
    align-items: center;
    flex-direction: column;

    &:after {
      content: '';
      position: absolute;

      /* position tooltip correctly */
      left: 50%;
      margin-left: -5px;

      /* vertically center */
      bottom: 0%;
      transform: translateY(100%);

      /* the arrow */
      border: 10px solid #fff;
      border-color: #fff transparent transparent transparent;
    }

    > img {
      max-width: 90px;
      margin: 0 auto 24px auto;
    }

    > h3 {
      color: ${theme.colors.black00};
      ${theme.texts.headingSM};
      /* max-width: 260px; */
      width: 100%;
      margin: 0 0 16px 0;
      white-space: pre;
      text-align: center;
    }

    > p {
      max-width: 260px;
      width: 100%;
      ${theme.texts.paragraphXS};
    }
  `}
`;

export const ContentButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 12px;

    width: 100%;
    margin: 24px 0 0 0;

    > button {
      border-radius: 4px;
      height: 46px;
      /* width: 100%; */
      flex: 1;
      background-color: transparent;
      border: 1px solid ${theme.colors.black20};
      ${theme.texts.buttonXS};

      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }

      &:first-child {
        &:hover {
          border-color: ${theme.colors.black00};
          opacity: 1;
        }
      }

      &:last-child {
        background-color: ${theme.colors.error00};
        border-color: ${theme.colors.error00};
        color: ${theme.colors.white00};
      }
    }
  `};
`;

export const AnalysisTable = styled.div`
  width: 100%;
  margin: 24px 0;
`;

export const AnalysisTableItem = styled.div`
  ${({ theme }) => css`
    border: 0.5px solid ${theme.colors.black20};
    height: 38px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    padding: 10px;

    > b,
    small {
      color: ${theme.colors.black00};
    }

    > b {
      ${theme.texts.buttonXXXS};
    }

    > small {
      ${theme.texts.paragraphXXS};
    }

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:first-child {
      border-radius: 2px 2px 0px 0px;
    }

    &:last-child {
      border-radius: 0px 0px 2px 2px;
    }
  `};
`;

export const Total = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    > h4 {
      color: ${theme.colors.black00};
      ${theme.texts.buttonSM};
    }
  `};
`;

export const TotalTexts = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > b {
      color: ${theme.colors.black00};
      ${theme.texts.buttonSM};
    }

    > small {
      color: ${theme.colors.black50};
      ${theme.texts.paragraphXXS};
    }
  `};
`;
