import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white00};
    flex: 1;

    transition: 0.5s ease-out;
  `};
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1 {
      color: ${theme.colors.black00};
      letter-spacing: -0.01em;
      line-height: 3rem;
      font-size: 2.4rem;
      font-weight: 600;

      display: flex;
      flex-direction: column;

      > small {
        font-weight: 400;
        opacity: 0.5;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }
    }

    > button {
      border: 1px solid rgba(20, 18, 30, 0.1);
      border-radius: 4px;
      height: 36px;
      color: ${theme.colors.black00};
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.6rem;
      padding: 10px 18px;
      background-color: transparent;
      margin-top: auto;
    }
  `};
`;

export const InfoCardsList = styled.div`
  margin: 24px 0;

  display: flex;
  flex-wrap: wrap;
  gap: 18px;
`;

export const InfoCardsSection = styled.div`
  padding: 0 24px;
`;

export const InfoCardsSubHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 24px 0 0 0;
    color: ${theme.colors.black00};
    ${theme.texts.paragraphSM};
    opacity: 0.5;

    > button {
      background-color: transparent;

      > svg {
        margin: 0 0 0 4px;
      }
    }
  `};
`;

export const MainTable = styled.div`
  margin-top: 26px;
`;

export const MainTableHeader = styled.div`
  ${({ theme }) => css`
    background: rgba(20, 18, 30, 0.05);
    border-radius: 4px;
    height: 64px;
    color: ${theme.colors.black00};
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  `};
`;

export const MainTableHeaderItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const MainTableContent = styled.div``;

export const SubHeader = styled.div`
  ${({ theme }) => css`
    /* margin: 36px 0 0 0; */
    flex: 1 0;
    max-height: 46px;
    height: 100%;
    width: 100%;
    padding: 0 24px;
    /* margin: 24px 0 0 0; */

    display: flex;
    align-items: center;
  `};
`;

export const SearchInput = styled.div`
  ${({ theme }) => css`
    flex: 1;
    margin: 0 12px 0 0;

    position: relative;
    display: flex;
    align-items: center;

    > svg {
      position: absolute;
      left: 18px;
      font-size: 1.6rem;
    }

    > input {
      background-color: ${theme.colors.black05};
      border-radius: 4px;
      width: 100%;
      border: 0;
      height: 46px;
      padding: 12px 18px 12px 40px;
      ${theme.texts.paragraphMD};
      border: 1px solid ${theme.colors.black05};

      transition: ${theme.transitions.default};

      &:focus {
        border-color: ${theme.colors.black00};
      }
    }
  `};
`;

export const MainTableContentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0 0 0;
`;

export const MainTableContentRowItem = styled.div`
  text-align: center;
  flex: 1;
`;

export const HeaderButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 12px;

    button {
      min-width: 140px;
      width: fit-content;
      ${theme.texts.buttonXS};
      max-height: 46px;
      height: 100%;
      border-radius: 4px;
    }
  `};  
`;
