import React, { ReactElement } from 'react';
import { Container } from './styles';
import { TextareaProps } from './Textarea.spec';

const Textarea = ({
  label = '', required, field, ...props
}: TextareaProps):ReactElement => (
  <Container>
    <label>
      {label}
      {' '}
      {required && (
      <small>
        (obrigatório)
      </small>
      )}

    </label>
    <textarea {...field} {...props} />
  </Container>
);

export default Textarea;
