import React, { ReactElement } from 'react';
// import DatePicker from 'react-datepicker';
import { DatePicker, DatePickerProps } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import dayjs, { Dayjs } from 'dayjs';

import ErrorMessage from '../ErrorMessage';
import { InputDateProps } from './DateInput.spec';
import 'react-datepicker/dist/react-datepicker.css';
import 'dayjs/locale/pt-br';

import { Container } from './styles';

const InputDate = ({
  label = '',
  required,
  className,
  message,
  onChange,
  name,
  selected,
  disabled,
  disabledDate,
  placeholderText,
  datePickerProps,
}: InputDateProps): ReactElement => {
  const handleDateChange = (value: Dayjs | null): void => {
    const formattedDate = value ? value.format('YYYY-MM-DD') : '';
    onChange?.(formattedDate);
  };

  const defaultDisabledDate = (current: Dayjs | null): boolean => (current ? current.isBefore(dayjs().endOf('day')) : false);

  const finalDisabledDate = disabledDate || defaultDisabledDate;

  return (
    <Container className={className}>
      <label>
        {label}
        {' '}
        {required && (
        <small>
          (obrigatório)
        </small>
        )}

      </label>
      <DatePicker
        {...datePickerProps}
        format="DD/MM/YYYY"
        onChange={handleDateChange}
        value={selected ? dayjs(selected) : undefined}
        name={name}
        disabled={disabled}
        disabledDate={finalDisabledDate}
        placeholder={placeholderText}
      />
      <ErrorMessage message={message} />
    </Container>
  );
};
export default InputDate;
