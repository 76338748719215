import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { CoreDTO } from '../@types/Core';
import { CoreService } from '../services/coreService';
import { AppContext } from '../App';
import { ListType } from '../@types/ListType';

interface UseItemType {
  item?: ListType[],
  refetch: () => Promise<void>,
}

export function useListItem<
  S extends CoreService<any, any>,
>(coreService: S): UseItemType {
  const { setLoading } = useContext(AppContext);
  const [item, setItem] = useState<any[]>();

  const fetch = useCallback(async (err?: (u: unknown) => void): Promise<void> => {
    try {
      setLoading(true);
      const data = await coreService.getList();
      setItem(data);
    } catch (error) {
      err?.(error);
    } finally {
      setLoading(false);
    }
  }, [coreService]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    item,
    refetch: fetch,
  };
}
