import _ from 'lodash';
import { AgentDTO, AgentSDTO } from '../@types';
import { FindOptions, Page } from '../@types/Core';

import { BaseService } from './baseService';

class AgentService extends BaseService<
  AgentDTO,
  AgentSDTO
> {
  baseURL = '/agents';

  async getPageByAgencyOfficeID(
    agencyOfficeID: number,
    findOptions?: FindOptions,
  ): Promise<Page<AgentSDTO>> {
    return super.getPage({
      filters: _.merge(
        {
          agencyOfficeID: {
            $eq: agencyOfficeID,
          },
        },
        findOptions,
      ),
    });
  }
}

export default new AgentService();
