import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import InputControl from '../../../../../components/Input';
import InputMask from '../../../../../components/MaskedInput';
import InputDate from '../../../../../components/DateInput';
import {
  cepMask,
  cnpjMask,
  cpfMask,
  phoneMask9,
} from '../../../../../utils/masks';

import { Container, InputRow, ContentForm } from './styles';
import Select from '../../../../../components/Select';
import { CustomerContext } from '../../CustomerInfo';
import { UFList } from '../../../../../@types/Address';
import { selectService } from '../../../../../services';
import { InfoMode } from '../../../../../@types/InfoMode';
import { InputCurrency } from '../../../../../components';
import Radio from '../../../../../components/Radio';

const PrimaryData = (): ReactElement => {
  const { t } = useTranslation();

  const { formik, mode } = useContext(CustomerContext);

  const customer = formik?.values;

  const [selectGender, setSelectGender] = useState();

  useEffect(() => {
    setSelectGender(selectService.getGender());
  }, []);

  const modeView = mode === InfoMode.VIEW;

  return (
    <Container>
      <ContentForm>
        <h2>{t('customerPrimaryData.titleUser') || ''}</h2>
        <InputRow>
          <Select
            name="typeUser"
            label={t('customerPrimaryData.labelInputTypeUser') || ''}
            options={selectGender}
            value={customer.typeUser}
            onChange={(selectedOption: any) => formik.setFieldValue('typeUser', selectedOption)}
            isDisabled={modeView}
          />

          <Select
            name="status"
            label={t('customerPrimaryData.labelInputStatus') || ''}
            options={selectGender}
            value={customer.status}
            onChange={(selectedOption: any) => formik.setFieldValue('status', selectedOption)}
            isDisabled={modeView}
          />
        </InputRow>
      </ContentForm>
      <ContentForm>
        <h2>{t('customerPrimaryData.titlePersonalData') || ''}</h2>
        <InputRow>
          <InputControl
            name="name"
            label={t('customerPrimaryData.labelInputName') || ''}
            placeholder={t('customerPrimaryData.placeholderInputName') || ''}
            value={customer.name}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <InputMask
            name="cpf"
            label={t('customerPrimaryData.labelInputCPF') || ''}
            placeholder="000.000.000-00"
            mask={cpfMask}
            guide={false}
            value={customer.cpf}
            onChange={({ target: { value } }) => formik.setFieldValue('cpf', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <Select
            name="civilStatus"
            label={t('customerPrimaryData.labelInputCivilStatus') || ''}
            options={selectGender}
            value={customer.civilStatus}
            onChange={(selectedOption: any) => formik.setFieldValue('civilStatus', selectedOption)}
            isDisabled={modeView}
          />

          <InputDate
            name="birthDate"
            label={t('customerPrimaryData.labelInputBirthDate') || ''}
            selected={customer.birthDate}
            onChange={(value: any) => {
              formik.setFieldValue('birthDate', value);
            }}
            disabled={modeView}
          />
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>{t('customerPrimaryData.titleContacts')}</h2>
        <InputRow>
          <InputMask
            name="phone"
            label={t('customerPrimaryData.labelInputPhone') || ''}
            placeholder="+55 51 9 9999 9999"
            mask={phoneMask9}
            guide={false}
            value={customer.phone}
            onChange={({ target: { value } }) => formik.setFieldValue('phone', value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <InputControl
            name="email"
            label={t('customerPrimaryData.labelInputEmail') || ''}
            placeholder={t('customerPrimaryData.placeholderInputEmail') || ''}
            type="email"
            value={customer.email}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputRow>
      </ContentForm>

      <ContentForm>
        <h2>{t('customerPrimaryData.titleOffice')}</h2>
        <InputRow>
          <Select
            name="occupation"
            label={t('customerPrimaryData.labelInputOccupation') || ''}
            options={selectGender}
            value={customer.occupation}
            onChange={(selectedOption: any) => formik.setFieldValue('occupation', selectedOption)}
            isDisabled={modeView}
          />

          <InputCurrency
            name="income"
            label={t('customerPrimaryData.labelInputIncome') || ''}
            value={customer.income}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
          />

          <InputCurrency
            name="netWorth"
            label={t('customerPrimaryData.labelInputNetWorth') || ''}
            value={customer.netWorth}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
          />

          <Radio
            name="ownCompany"
            label={t('customerPrimaryData.labelInputOwnCompany') || ''}
            checked={customer.ownCompany}
            onChangeOne={() => formik.setFieldValue('ownCompany', true)}
            onChangeTwo={() => formik.setFieldValue('ownCompany', false)}
            readonly={modeView}
          />
        </InputRow>
      </ContentForm>

      {customer?.ownCompany && (
        <ContentForm>
          <h2>{t('customerPrimaryData.titleMyCompany')}</h2>
          <InputRow>
            <InputMask
              name="cpnj"
              label={t('customerPrimaryData.labelInputCNPJ') || ''}
              placeholder="00.000.000/0000-00"
              mask={cnpjMask}
              guide={false}
              value={customer.cnpj}
              onChange={({ target: { value } }) => formik.setFieldValue('cnpj', value?.replace(/\D/g, ''))}
              disabled={modeView}
            />

            <InputControl
              name="companyName"
              label={t('customerPrimaryData.labelInputCompanyName') || ''}
              placeholder={
                t('customerPrimaryData.placeholderInputCompanyName') || ''
              }
              value={customer.companyName}
              onChange={formik.handleChange}
              disabled={modeView}
            />
          </InputRow>
        </ContentForm>
      )}

      <ContentForm>
        <h2>{t('customerPrimaryData.titleAddress')}</h2>
        <InputRow>
          <InputMask
            name="address.postalCode"
            label={t('customerPrimaryData.labelInputCEP') || ''}
            placeholder="00000-000"
            mask={cepMask}
            guide={false}
            value={customer?.address?.postalCode}
            onChange={({ target: { value } }) => formik.setFieldValue(
              'address.postalCode',
              value?.replace(/\D/g, ''),
            )}
            disabled={modeView}
          />

          <InputControl
            name="address.street"
            label={t('customerPrimaryData.labelInputAddress') || ''}
            placeholder={t('customerPrimaryData.placeholderInputAddress') || ''}
            value={customer?.address?.street}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="address.number"
            label={t('customerPrimaryData.labelInputNumber') || ''}
            placeholder={t('customerPrimaryData.placeholderInputNumber') || ''}
            value={customer?.address?.number}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="address.complement"
            label={t('customerPrimaryData.labelInputComplement') || ''}
            placeholder={
              t('customerPrimaryData.placeholderInputComplement') || ''
            }
            value={customer?.address?.complement}
            onChange={formik.handleChange}
            disabled={modeView}
          />
        </InputRow>

        <InputRow>

          <InputControl
            name="address.neighborhood"
            label={t('customerPrimaryData.labelInputNeighborhood') || ''}
            placeholder={
              t('customerPrimaryData.placeholderInputNeighborhood') || ''
            }
            value={customer?.address?.neighborhood}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <InputControl
            name="address.city"
            label={t('customerPrimaryData.labelInputCity') || ''}
            placeholder={t('customerPrimaryData.placeholderInputCity') || ''}
            value={customer?.address?.city}
            onChange={formik.handleChange}
            disabled={modeView}
          />
          <Select
            name="address.state"
            label={t('customerPrimaryData.labelInputUF') || ''}
            placeholder={t('customerPrimaryData.placeholderInputUF') || ''}
            options={UFList}
            value={customer?.address?.state}
            onChange={(selectedOption: any) => formik.setFieldValue('address.state', selectedOption)}
            isDisabled={modeView}
          />
        </InputRow>
      </ContentForm>
    </Container>
  );
};

export default PrimaryData;
