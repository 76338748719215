import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    > span {
      ${theme.texts.paragraphXS};
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      cursor: pointer;

      > svg {
        margin-right: 6px;
        font-size: 1.4rem;
      }
    }

    input { 
      height: 48px !important;
    }
  `};
`;

export const Form = styled.form`
  flex: 1;

  display: flex;
  flex-direction: column;

  > button {
    margin-top: 24px;
  }
`;

export const InputControlRow = styled.div`
  display: flex;
  margin-bottom: 24px;

  > div {
    width: 49%;

    :first-child {
      margin-right: 12px;
    }

    :last-child {
      margin-right: 0;
    }
  }

  > button:first-child {
    margin-right: 12px;
  }
`;

export const Tabs = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 24px;
    position: relative;

    &::after {
      display: block;
      width: 100%;
      content: '';
      border-bottom: 2px solid ${theme.colors.black10};
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0;
    }

    > button {
      ${theme.texts.headingXXS};
      padding-bottom: 16px;
      background-color: transparent;
      color: ${theme.colors.black30};
      padding: 0 2px 16px 2px;

      transition: ${theme.transitions.default};

      position: relative;

      &.active {
        color: ${theme.colors.black00};
        transition: ${theme.transitions.default};

        &::after {
          display: block;
          width: 100%;
          content: '';
          border-bottom: 2px solid ${theme.colors.black00};
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1;
        }
      }

      &:first-child {
        margin: 0 24px 0 0;
      }
    }
  `};
`;

export const Content = styled.div`
  > div {
    margin-bottom: 12px;
  }
`;

export const AnalyzingLoader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(255, 255, 255, 0.9);

    z-index: 99;

    left: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      ${theme.texts.paragraphXS};
      margin: 0 0 4px 0;
    }
    > b {
      color: ${theme.colors.black00};
      ${theme.texts.headingXS};
    }
  `};
`;

export const EngineRotate = styled.div`
  ${() => css`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 24px auto;

    > img {
      flex: 1;
      max-width: 50px;
      animation: ${gearRotate} 2.5s linear infinite;
    }
  `}
`;

const gearRotate = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(-360deg); 
  }
`;

const gearRotateLeft = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

export const RightEngine = styled.span`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 -4px;

  > img {
    animation: ${gearRotateLeft} 2.5s linear infinite;

    &:first-child {
      max-width: 37.77px;
      margin: 0 0 -1px 0;
    }

    &:last-child {
      max-width: 30.12px;
    }
  }
`;

export const FeedbackNotice = styled.span<{ success?: boolean }>`
  ${({ theme, success }) => css`
    padding: 24px 16px 24px 24px;
    margin: 12px 0 0 0;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    max-height: 76px;
    ${theme.texts.headingXS};
    background-color: ${theme.colors.success10};
    color: ${theme.colors.success00};

    > svg {
      font-size: 2.8rem;
      margin: 0 12px 0 0;
    }

    ${!success
    && css`
      background-color: ${theme.colors.error10};
      color: ${theme.colors.error00};
    `};
  `};
`;
