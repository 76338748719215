import React, { ReactElement, useState } from 'react';

import { RiLock2Fill } from 'react-icons/ri';

import { Loading } from '..';

import {
  Container,
  TabNavigationHeader,
  TabNavigationHeaderItem,
  TabNavigationContent,
} from './styles';

import {
  TabNavigationProps,
  TabElementProps,
} from '../../@types/TabNavigation';

import TooltipComponent from '../Tooltip';

const TabNavigation = ({
  tabNavigationItems,
  type = 'produto',
  disabledText,
}: TabNavigationProps): ReactElement => {
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleNextTab(index: number): void {
    setCurrentTabIndex(index);
  }

  return (
    <Container>
      <TabNavigationHeader className="tabNavigationHeader">
        {tabNavigationItems.map((item: TabElementProps, index: number) => (
          <TabNavigationHeaderItem
            onClick={() => (item.enabled ? handleNextTab(index) : {})}
            key={item?.title}
            className={currentTabIndex === index ? 'active' : ''}
          >
            {item?.enabled ? (
              <p>{item?.title}</p>
            ) : (
              <>
                <TooltipComponent
                  text={disabledText || `Crie o ${type} para ativar e realizar modificações nesta aba.`}
                  tooltip={(
                    <>
                      {item.title}
                      {' '}
                      <RiLock2Fill />
                    </>
                  )}
                />
              </>
            )}
          </TabNavigationHeaderItem>
        ))}
        <p />
      </TabNavigationHeader>
      <TabNavigationContent>
        {tabNavigationItems[currentTabIndex]?.element ?? (
          <h2>Nenhum conteúdo..</h2>
        )}
      </TabNavigationContent>
      {isLoading && <Loading />}
    </Container>
  );
};

export default TabNavigation;
