import React, { ReactElement } from 'react';
import { SelectContainer } from './styles';
import { GuarantorType } from '../GuarantorData.spec';

interface TypeOfGuarantorSelectProps {
  handleSelect: (option: GuarantorType) => void;
}

export function TypeOfGuarantorSelect({ handleSelect }: TypeOfGuarantorSelectProps): ReactElement {
  return (
    <SelectContainer>
      <button type="button" onClick={() => handleSelect('PERSON')}>Pessoa física</button>
      <button type="button" onClick={() => handleSelect('COMPANY')}>Pessoa jurídica</button>
    </SelectContainer>
  );
}
