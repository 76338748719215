import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import {
  RiCheckFill,
  RiDeleteBin5Line,
  RiEdit2Line,
  RiFileCopyLine,
  RiMoneyDollarCircleLine,
} from 'react-icons/ri';

import {
  Container,
  DynamicItem,
  HeaderItem,
  ActionsButton,
  BodyItem,
  PrimaryTitle,
  SecondaryTitle,
  SecondaryButton,
  Flag,
  FormFooter,
  BoxLeft,
  BoxRight,
  DeleteButton,
} from './styles';
import Button from '../Button';
import { DataCardsListProps, submitButtonMessages } from './DataCardsList.spec';
import ExcludeModal from '../ExcludeModal';

const DataCardsList = ({
  onRemove,
  onDuplicate,
  onSubmit,
  onOpen,
  onClose,
  mode,
  type = '',
  visibleValue,
  open,
  title,
  subtitle,
  tag,
  children,
}: DataCardsListProps): ReactElement => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [removeItem, setRemoveItem] = useState<boolean>(false);

  const openSelf = (): void => {
    setIsVisible(true);
    onOpen?.();
  };

  useEffect(() => {
    if (typeof open === 'boolean') {
      setIsVisible(open);
    }
  }, [open]);

  function renderIcon(): ReactElement | null {
    const icons: { [key: string]: ReactElement } = {
      bankData: <RiMoneyDollarCircleLine />,
    };

    return icons[type] ?? null;
  }

  function renderFormFooterActions(): ReactElement {
    return (
      <>
        <BoxLeft>
          <DeleteButton
            type="button"
            onClick={() => onClose?.()}
          >
            Excluir
          </DeleteButton>
        </BoxLeft>
        <BoxRight>
          <SecondaryButton type="button" onClick={() => onClose?.()}>
            Cancelar
          </SecondaryButton>
          <Button
            type="button"
            message={submitButtonMessages[type]}
            onClick={() => { onSubmit?.(); }}
            icon={<RiCheckFill />}
          />
        </BoxRight>
      </>
    );
  }
  return (
    <Container>
      <DynamicItem isVisible={isVisible}>
        <HeaderItem>
          <div>
            {renderIcon()}
            <div>
              <PrimaryTitle>{title}</PrimaryTitle>
              <SecondaryTitle>
                {subtitle}
              </SecondaryTitle>
            </div>
          </div>
          <div>
            {tag ? <Flag>{tag}</Flag> : <></>}
            <ActionsButton>
              {!isVisible && (
                <>
                  <Button
                    type="button"
                    message=""
                    variant="secondary"
                    onClick={() => {
                      openSelf();
                    }}
                    icon={<RiEdit2Line />}
                  />
                  {
                    onDuplicate && (
                      <Button
                        className="parameterization"
                        type="button"
                        message=""
                        variant="secondary"
                        onClick={() => {
                          onDuplicate?.();
                        }}
                        icon={<RiFileCopyLine />}
                      />
                    )
                  }
                  <Button
                    type="button"
                    message=""
                    icon={<RiDeleteBin5Line />}
                    className="delete"
                    onClick={() => setRemoveItem(true)}
                  />
                </>
              )}
            </ActionsButton>

          </div>
        </HeaderItem>

        {isVisible && (
          <>
            <BodyItem>
              {children}
              <FormFooter>
                {renderFormFooterActions()}
              </FormFooter>
            </BodyItem>

          </>
        )}

      </DynamicItem>

      {removeItem && (
        <ExcludeModal
          type={type}
          visibleValue={visibleValue}
          handleCloseModal={() => setRemoveItem(false)}
          handleDeleteConfirmation={() => {
            onRemove?.();
            setRemoveItem(false);
          }}
        />
      )}
    </Container>
  );
};

export default DataCardsList;
