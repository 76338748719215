import React, { ReactElement } from 'react';
import {
  RiCalendarLine,
} from 'react-icons/ri';

import {
  Container,
  Calendar,
  UserInfos,
  UserImage,
} from './styles';

const Navbar = (): ReactElement => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const todayDate = new Date();

  return (
    <Container>
      <UserInfos>
        Olá,
        {' '}
        <b>João</b>
        <UserImage>
          J
        </UserImage>
      </UserInfos>
    </Container>
  );
};

export default Navbar;
