import styled, { css } from 'styled-components';

export const Container = styled.section``;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ContentSocial = styled.div`
  ${({ theme }) => css`
    width: 49.5%;

    margin-bottom: 24px;

    :nth-child(2n) {
      /* margin-left: 12px; */
    }

    > p {
      ${theme.texts.paragraphXS};

      margin-bottom: 8px;

      color: ${theme.colors.black00};
    }
  `}
`;

export const InputControl = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    background-color: ${disabled ? theme.colors.black20 : theme.colors.black05};
    color: ${theme.colors.black00};
    border-radius: 4px;
    padding: 12px 16px;

    width: 100%;

    ${theme.texts.paragraphXS};

    display: flex;
    align-items: center;

    > input {
      ${theme.texts.paragraphXS};
      width: 100%;
      border-radius: 4px;
      border: 0;

      transition: ${theme.transitions.default};
      background-color: ${theme.colors.black05};
      color: ${theme.colors.black00};

      &::placeholder {
        color: ${theme.colors.black30};
      }

      &:disabled {
        background-color: ${theme.colors.black20};
      }
    }

    > a {
      color: ${theme.colors.black00};
      font-size: 1.4rem;

      height: 14px;
    }
  `}
`;

export const InputItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      padding: 0;
      height: 42px;
      min-width: 42px;
      margin-left: 8px;

      border-radius: 4px;
      border: 1px solid ${theme.colors.black20};
      background-color: ${theme.colors.white00};

      transition: ${theme.transitions.default};

      :hover {
        border-color: ${theme.colors.black00};
      }

      :disabled {
        border-color: ${theme.colors.black20};
        cursor: not-allowed;
      }
    }
  `}
`;

export const NoContent = styled.div`
  ${({ theme }) => css`
    margin: 18px auto 0;
    padding: 44px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      max-width: 148px;
      width: 100%;
      margin: 0 0 24px 0;
    }

    > h4 {
      margin: 0 0 8px 0;
      ${theme.texts.buttonLG};
      color: ${theme.colors.black00};
      letter-spacing: -0.2px;
    }

    > p {
      ${theme.texts.paragraphMD};
      color: ${theme.colors.black00};
    }
  `}
`;

export const NewItemContent = styled.div`
  ${({ theme }) => css`
    position: relative;
    > button {
      padding: 10px 40px 10px 20px;
    }
  `}
`;

export const Dropdown = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 200px;
    padding: 12px 14px;

    position: absolute;
    top: 72px;

    border-radius: 4px;
    border: 1px solid ${theme.colors.black20};
    background-color: ${theme.colors.white00};
    z-index: 10;
  `}
`;

export const DropdownItem = styled.div`
  ${({ theme }) => css`
    ${theme.texts.paragraphXS};
    padding: 10px;

    border-radius: 4px;
    color: ${theme.colors.black00};
    transition: ${theme.transitions.default};
    cursor: pointer;

    &.disabled {
      color: ${theme.colors.black30};
      cursor: default;

      :hover {
        color: ${theme.colors.black30};
        background-color: ${theme.colors.white00};
      }
    }

    :not(:last-child) {
      margin-bottom: 4px;
    }
    :hover {
      color: ${theme.colors.black00};
      background-color: ${theme.colors.black10};
    }
  `}
`;
