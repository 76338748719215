const moneyFormatterBRL = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export function formatMoney(value?: string | number): string {
  return moneyFormatterBRL.format(+(value || 0));
}

export function formatDateWithHour(value: string | undefined | Date): string {
  return value ? `${new Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(new Date(value))} às ${new Intl.DateTimeFormat('pt-BR', {
    hour: 'numeric', minute: 'numeric',
  }).format(new Date(value))}` : '';
}

export function formatDate(date?: string): any {
  if (date) {
    return new Date(`${date}T00:00:00.000`);
  }
  return new Date();
}
