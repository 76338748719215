import { FormikProps, useFormik } from 'formik';
import React, {
  ReactElement,
  useEffect, useState,
} from 'react';
import { RiAddFill } from 'react-icons/ri';
import { AccountDTO } from '../../../../@types/Account';
import HandshakeIcon from '../../../../assets/HandshakeIcon.svg';
import Button from '../../../Button';
import { ACCOUNT_YUP_SCHEMA, AccountCore, NEW_ACCOUNT_INITIAL_VALUES } from './BankData.spec';
import DataCardsList from '../../../DataCardsList';
import { FormAccount } from './FormAccount';
import {
  BoxLeft, BoxRight,
  Container, HeaderBox,
  NoContent,
} from './styles';
import { CustomerDataCore } from '../../CustomerData.spec';

interface BankProps {
  // formik: FormikProps<CustomerDataCore>;
  accountList: any[];
  setAccountList: (accountList: any[]) => void;
}

export const BankData = ({ accountList, setAccountList }: BankProps): ReactElement => {
  const [newListItem, setNewListItem] = useState<AccountDTO>();
  // const [accountList, setAccountList] = useState<any[]>(formik.values.accounts ?? []);

  const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false);
  const [openItemIndex, setOpenItemIndex] = useState<number | undefined>(undefined);

  const newAccountFormik = useFormik<AccountCore>({
    enableReinitialize: true,
    initialValues: NEW_ACCOUNT_INITIAL_VALUES,
    validationSchema: ACCOUNT_YUP_SCHEMA,
    onSubmit: (values: any) => {
      const filteredAccounts = accountList.filter((item, index) => index !== openItemIndex);

      setAccountList([...filteredAccounts, { ...values }]);
      setOpenItemIndex(undefined);
      setIsCreatingAccount(false);
      setNewListItem(undefined);
      newAccountFormik.resetForm();
    },
  });

  const removeAccount = async (accountIndex: number | undefined): Promise<void> => {
    setAccountList(accountList.filter((item, index) => index !== accountIndex));
    setOpenItemIndex(undefined);
  };

  const handleDuplicate = (account: AccountCore): void => {
    setAccountList([...accountList, { ...account }]);
  };

  useEffect(() => {
    if (openItemIndex !== undefined && !isCreatingAccount) {
      console.log('openItemIndex', openItemIndex);
      newAccountFormik.setValues(accountList[openItemIndex]);
    } else {
      newAccountFormik.resetForm();
    }
  }, [openItemIndex]);

  return (
    <Container>
      <HeaderBox>
        <BoxLeft>
          <h2>Lista de contas</h2>
          <p>
            <span>{accountList?.length || 0}</span>
            {' '}
            conta(s) encontrada(s)
            {' '}
          </p>
        </BoxLeft>
        <BoxRight>
          <Button
            type="button"
            message="Nova conta"
            icon={<RiAddFill />}
            onClick={() => setIsCreatingAccount(true)}
            disabled={!!newListItem}
          />
        </BoxRight>
      </HeaderBox>

      {isCreatingAccount && (
        <DataCardsList
          item={newListItem}
          visibleValue={newListItem?.holderName || 'Conta'}
          type="bankData"
          title="Banco"
          subtitle="Conta com digito"
          open
          onSubmit={newAccountFormik.handleSubmit}
          onClose={() => { setIsCreatingAccount(false); }}
        >
          <FormAccount
            formik={newAccountFormik}
          />
        </DataCardsList>
      )}

      {
        accountList?.length > 0 && (
          <>
            {accountList?.map((account: any, index: number) => (
              <DataCardsList
                key={account.id || index}
                type="bankData"
                item={account}
                onRemove={() => removeAccount(index)}
                open={openItemIndex === index}
                onOpen={() => setOpenItemIndex(index)}
                title="Banco"
                subtitle="Conta com digito"
                tag={account.isPrimaryAccount ? 'Conta primária' : ''}
                onClose={() => { setOpenItemIndex(undefined); }}
                onSubmit={newAccountFormik.handleSubmit}
                onDuplicate={() => handleDuplicate(account)}
              >
                <FormAccount
                  formik={newAccountFormik}
                />
              </DataCardsList>
            ))}
          </>
        )
      }

      {
        !isCreatingAccount && !accountList.length && (
          <NoContent>
            <img src={HandshakeIcon} alt="Handshake Icon" />
            <h2>Nenhuma conta cadastrada</h2>
            <p>Comece adicionando alguma conta através do botão a sua direita.</p>
          </NoContent>
        )
      }

    </Container>
  );
};
