import styled, { css } from 'styled-components';

export const Container = styled.section`
`;

export const ContentForm = styled.div`
  ${({ theme }) => css`
  `}
`;

export const InputRow = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  margin-bottom: 24px;

  > div {
    width: 49%;

    :not(:last-child) {
      margin-right: 12px;
    }
  }
`;
