import React, {
  ReactNode,
  ReactElement,
} from 'react';

import { useProSidebar } from 'react-pro-sidebar';

import { Container, Content } from './styles';

import Sidebar from '../../components/Sidebar';

interface DashboardLayoutProps {
  children: ReactNode;
}

export default function DashboardLayout({
  children,
}: DashboardLayoutProps): ReactElement {
  const { collapsed } = useProSidebar();

  return (
    <Container>
      <Sidebar />
      <Content collapsed={collapsed}>{children}</Content>
    </Container>
  );
}
