import {
  headingXXXL,
  headingXXL,
  headingXL,
  headingLG,
  headingMD,
  headingSM,
  headingXS,
  headingXXS,
  headingXXXS,
  paragraphXXXL,
  paragraphXXL,
  paragraphXL,
  paragraphLG,
  paragraphMD,
  paragraphSM,
  paragraphXS,
  paragraphXXS,
  paragraphXXXS,
  buttonXXXL,
  buttonXXL,
  buttonXL,
  buttonLG,
  buttonMD,
  buttonSM,
  buttonXS,
  buttonXXS,
  buttonXXXS,
  textXXXL,
  textXXL,
  textXL,
  textLG,
  textMD,
  textSM,
  textXS,
  textXXS,
  textXXXS,
} from './mixins';

const lightTheme: any = {
  colors: {
    black00: '#1E1E1E',
    black90: '#353535',
    black80: '#4B4B4B',
    black70: '#626262',
    black60: '#787878',
    black50: '#8F8F8F',
    black40: '#A5A5A5',
    black30: '#BBBBBB',
    black20: '#D0D0D2',
    black10: '#E8E8E8',
    black05: '#F4F4F4',
    white00: '#FFFFFF',
    white90: '#E8E8E8',
    white80: '#D2D2D2',
    white70: '#BBBBBB',
    white60: '#A5A5A5',
    white50: '#8E8E8E',
    white40: '#787878',
    white30: '#616161',
    white20: '#4B4B4B',
    white10: '#343434',
    white05: '#292929',
    alert00: '#E0C04E',
    alert90: '#E3C660',
    alert80: '#E6CD71',
    alert70: '#E9D383',
    alert60: '#ECD995',
    alert50: '#EFDFA7',
    alert40: '#F3E6B8',
    alert30: '#F6ECCA',
    alert20: '#F9F2DC',
    alert10: '#FCF9ED',
    alert05: '#FDFCF6',
    error00: '#E24A4A',
    error90: '#E55C5C',
    error80: '#E86E6E',
    error70: '#EB8080',
    error60: '#EE9292',
    error50: '#F0A4A4',
    error40: '#F3B7B7',
    error30: '#F6C9C9',
    error20: '#F9DBDB',
    error10: '#FCEDED',
    error05: '#FEF6F6',
    info00: '#4EABE0',
    info90: '#60B4E3',
    info80: '#71BCE6',
    info70: '#83C5E9',
    info60: '#95CDEC',
    info50: '#A7D5EF',
    info40: '#B8DEF3',
    info30: '#CAE6F6',
    info20: '#DCEEF9',
    info10: '#EDF7FC',
    info05: '#F6FBFD',
    success00: '#4EE080',
    success90: '#60E38C',
    success80: '#71E699',
    success70: '#83E9A6',
    success60: '#95ECB3',
    success50: '#A7EFBF',
    success40: '#B8F3CC',
    success30: '#CAF6D9',
    success20: '#DCF9E6',
    success10: '#EDFCF2',
    success05: '#F6FDF9',
  },
  texts: {
    headingXXXL,
    headingXXL,
    headingXL,
    headingLG,
    headingMD,
    headingSM,
    headingXS,
    headingXXS,
    headingXXXS,
    paragraphXXXL,
    paragraphXXL,
    paragraphXL,
    paragraphLG,
    paragraphMD,
    paragraphSM,
    paragraphXS,
    paragraphXXS,
    paragraphXXXS,
    buttonXXXL,
    buttonXXL,
    buttonXL,
    buttonLG,
    buttonMD,
    buttonSM,
    buttonXS,
    buttonXXS,
    buttonXXXS,
    textXXXL,
    textXXL,
    textXL,
    textLG,
    textMD,
    textSM,
    textXS,
    textXXS,
    textXXXS,
  },
  transitions: {
    default: '180ms ease-in-out',
  },
};

export default lightTheme;
