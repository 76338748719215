import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  > div {
    padding: 0px 
  }
`;

export const HeaderBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    padding: 0 0 24px 0;

    position: sticky;
    top: 110px;
    background-color: ${theme.colors.white00};
  `}
`;

export const BoxLeft = styled.div`
  ${({ theme }) => css`
    > h2 {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
      margin-bottom: 8px;
    }

    > p {
      ${theme.texts.paragraphXXXS};
      color: ${theme.colors.black50};

      > span {
        color: ${theme.colors.black00};
      }
    }
  `}
`;

export const BoxRight = styled.div`
  ${({ theme }) => css`
    > button {
      height: 42px;
      padding: 10px 40.5px 10px 20px;

      border-radius: 4px;
      ${theme.texts.buttonXS};
    }
  `}
`;

export const NoContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 412px;
    margin: 58px auto;
    text-align: center;

    > h2 {
      ${theme.texts.buttonLG};
      color: ${theme.colors.black00};
      margin: 24px 14px 14px 14px;
    }

    > p {
      ${theme.texts.paragraphMD};
      color: ${theme.colors.black00};
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.black10};
    width: 100%;
    position: absolute;
    bottom: 90px;
    left: 0;
  `}
`;

export const Bottom = styled.div`
  ${({ theme }) => css`
    padding: 16px 0 0 0;

    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    > button {
      max-width: 102px;
      padding: 10px 20px;

      ${theme.texts.buttonXS};
    }
  `}
`;

export const Footer = styled.footer`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: auto 0 0 0;
    padding: 24px 0 0 0;
    border-top: 1px solid ${theme.colors.black10};
  `};
`;

export const StepsNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > small {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
    }
  `};
`;

export const StepsList = styled.div`
  ${({ theme }) => css`
    display: flex;

    margin: 0 0 0 2px;

    > span {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black30};
      padding: 3.5px 12px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:not(:last-child) {
        border-right: 0.5px solid ${theme.colors.black10};
      }

      > svg {
        font-size: 1.4rem;
        color: ${theme.colors.success00};
      }

      &.active {
        color: ${theme.colors.black00};
      }
    }
  `};
`;

export const FooterButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      height: 42px;

      &:first-child {
        background-color: transparent;
        margin: 0 6px 0 0;
        padding: 10px 20px;
        ${theme.texts.buttonXS};
      }
    }
  `};
`;
