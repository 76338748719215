import _ from 'lodash';
import api from './api';
import { BaseDTO, Page, FindOptions } from '../@types/Core';
import { ProductDTO, ProductSDTO } from '../@types/Product';
import { BaseService } from './baseService';

class ProductService<E extends BaseDTO> extends BaseService<
  ProductDTO,
  ProductSDTO
> {
  baseURL = '/products';

  async simulate(id: E['id'], dto: any): Promise<any> {
    return (await api.post(`${this.baseURL}/${id}/simulate`, dto))?.data;
  }

  async executeWorkflow(id: E['id'], dto: any): Promise<any> {
    return (await api.post(`${this.baseURL}/${id}/executeWorkflow?agencyOfficeID=137`, dto))?.data;
  }

  async getProductByCustomerType(
    customerType: string,
    searchString?: string,
  ): Promise<Page<ProductSDTO>> {
    return super.getPage({
      filters: _.merge(
        {
          '@productVariants': {
            customerType: {
              $eq: customerType,
            },
          },
          enabled: {
            $eq: true,
          },
        },
      ),
      searchString,
    });
  }
}

export default new ProductService();
