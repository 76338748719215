import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer';
import { OperationStepsContext } from '../../pages/Operations';
import { OperationStepFormBaseProps } from './OperationStepFormBase.spec';
import Header from '../Header';
import { newOperationBreadCrumbItems } from '../../mock';
import { OutletContent } from '../../pages/Operations/styles';

function OperationStepFormBase({ handleSubmit, children }: OperationStepFormBaseProps): ReactElement {
  const { t } = useTranslation();
  const {
    totalSteps, handlePreviousStep, currentStepData,
  } = useContext(OperationStepsContext);

  if (!currentStepData) return <></>;
  return (
    <>
      <Header
        title={t(`headerTitle.${currentStepData.type}`)}
        breadcrumbItems={newOperationBreadCrumbItems}
        hasPrev={currentStepData.prevIndex !== null}
        handlePrev={handlePreviousStep}
      />
      {children}
      <Footer
        steps={totalSteps + 1}
        currentStep={currentStepData.stepIndex + 1}
        handleClick={handleSubmit}
        buttonMessage={t(`buttonText.${currentStepData.type}`)}
      />
    </>
  );
}

export default OperationStepFormBase;
