import styled, { css } from 'styled-components';

export const Options = styled.div`
  ${({ theme }) => css`

  position: absolute;
  border: 1px solid ${theme.colors.black20};
  background-color: ${theme.colors.white00};
  border-radius: 4px;
  padding: 4px 0;
  width: 100%;

    > button {
      width: 100%;
      padding: 8px 14px;
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black00};
      display: block;
      align-items: initial;
      text-align: left;
      background-color: transparent;
    }

    > button:disabled {
      color: ${theme.colors.black20};
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    max-width: 200px;
    width: fit-content;
    position: relative;
  `};
  > button {
    width: 100%;
    margin-bottom: 10px;
    padding: 12px 40px 12px 20px
  }
`;
