import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 24px;
  `};
`;

export const TabNavigationRow = styled.div``;

export const TabNavigationHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 24px;

    position: relative;
    background-color: ${theme.colors.white00};
    z-index: 8;

    > p {
      position: absolute;
      bottom: 0;
      z-index: 1;

      width: 100%;

      border-bottom: 2px solid ${theme.colors.black10};
    }
  `};
`;

export const TabNavigationHeaderItem = styled.div`
  ${({ theme }) => css`
    ${theme.texts.headingXXS};
    color: ${theme.colors.black50};
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid transparent;

    cursor: pointer;

    transition: ${theme.transitions.default};

    :not(:last-child) {
      margin-right: 24px;
    }

    &.active {
      z-index: 2;
      color: ${theme.colors.black00};
      border-color: ${theme.colors.black00};
      transition: ${theme.transitions.default};
    }

    > svg {
      font-size: 1.4rem;
      margin-left: 8px;
    }
  `};
`;

export const TabNavigationContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0 8px 0;
`;
