import { BaseService } from './baseService';
import { ProfessionDTO, ProfessionSDTO } from '../@types/Profession';
import { AccountDTO } from '../@types/Account';
import { AccountTypeDTO, AccountTypeSDTO } from '../@types/AccountType';
import { ListType, ListTypeItem } from '../@types/ListType';

class FuelTypeService extends BaseService<
  ListType,
  ListTypeItem
> {
  baseURL = '/fuel-types';
}

export default new FuelTypeService();
