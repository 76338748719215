import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { TRANSLATIONS_EN } from './en';
import { TRANSLATIONS_PT_BR } from './pt-br';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      ptBr: {
        translation: TRANSLATIONS_PT_BR,
      },
    },
    lng: 'ptBr',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
