import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 24px 0;
`;

export const BoxLeft = styled.div`


  button {
    padding-left: 10px 20px 10px 0;
    height: 42px;
  }
`;

export const BoxRight = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  button {
    padding: 10px 20px;
  }

  button:last-child {
    padding: 10px 40.5px 10px 20px;
  }
`;

export const DeleteButton = styled.button`
  ${({ theme }) => css`
    padding: 10px 20px;
    height: 42px;
    background-color: transparent;
    color: ${theme.colors.error00};
    padding-left: 0;
  `}
`;

export const DynamicItem = styled.div<{ isVisible?: boolean }>`
  ${({ theme, isVisible }) => css`
    padding: 18px;
    border: 1px solid ${theme.colors.black20};
    border-radius: 4px;

    ${isVisible
    && css`
      border-color: ${theme.colors.black00};
    `};
  `}
`;

export const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
       button {
        ${theme.texts.buttonXS};
      }
  `};
`;

export const SecondaryButton = styled.button`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.black00}; 
`;
export const Flag = styled.span`
  ${({ theme }) => css`
    ${theme.texts.paragraphXXXS};
    background-color: ${theme.colors.info10};
    border-radius: 4px;
    padding: 3px 6px;
    margin-right: 18px;

    &.alert {
      background-color: ${theme.colors.alert10};
    }

  `} 
`;

export const HeaderItem = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

   
     > div:first-child {
      display: flex;
      align-items: center;

      > svg {
      font-size: 18px;
      margin-right: 18px;
    }
     }
     > div:last-child {
      display: flex;

      align-items: center;
     }
  `}
`;

export const PrimaryTitle = styled.h2`
  ${({ theme }) => css`
    ${theme.texts.paragraphMD};
    color: ${theme.colors.black00};
  `}
`;

export const SecondaryTitle = styled.span`
  ${({ theme }) => css`
    ${theme.texts.paragraphXXS};
    color: ${theme.colors.black50};
  `}
`;

export const TagContent = styled.div`
  ${({ theme }) => css`
    margin-top: 10px;
    > span {
      ${theme.texts.paragraphXXXS};

      padding: 3px 6px;
      border-radius: 4px;
      color: ${theme.colors.black00};

      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  `}
`;

export const ActionsButton = styled.div`
  ${({ theme }) => css`
    margin-left: auto;
    display: flex;
    align-items: center;

    .cancel {
      background-color: transparent;
      color: ${theme.colors.black00};
      width: 100%;
      max-width: 56px;
      margin-right: 18px;

      ${theme.texts.buttonXS};
    }

    > p {
      ${theme.texts.paragraphXXXS};
      color: ${theme.colors.black00};
      margin-right: 18px;
    }

    > button {
      height: 30px;
      width: 30px;
      padding: 9px;

      border-radius: 4px;

      &.delete {
        background-color: ${theme.colors.error00};
        margin-left: 6px;
      }

      &.parameterization {
        margin-left: 6px;
      }

      > div {
        right: 9px;

        > svg {
          font-size: 1.2rem;
        }
      }
    }

    > svg {
      margin: 0 6px 0 12px;
      font-size: 18px;

      cursor: pointer;
    }
  `}
`;

export const BodyItem = styled.div`
margin-top: 24px;
`;

export const TagItem = styled.span<{ bgColor?: string }>`
  ${({ bgColor }) => css`
    background-color: ${bgColor};
  `};
`;
