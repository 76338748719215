import React, { ReactElement, useMemo } from 'react';
import { RiArrowDropLeftFill, RiArrowDropRightFill } from 'react-icons/ri';

import { Pagination } from '../../@types/Pagination';

import { Container, LeftContent, RightContent } from './styles';

type PaginationPage = Pick<Pagination, 'page' | 'pageSize'>;

interface Props {
  pagination: Pagination;
  onChange: (pagination: PaginationPage) => void;
}

const TablePagination = ({ pagination, onChange }: Props): ReactElement => {
  const {
    page, pageSize, pageCount, total,
  } = pagination;
  const change = (newPage: Partial<PaginationPage>): void => onChange?.({
    page,
    pageSize,
    ...newPage,
  });

  const itemsPerPage = page === pageCount ? total - pageSize * (pageCount - 1) : 0;

  const paginationSize = 7;

  const pageNumbers = useMemo((): Array<number> => {
    if (paginationSize >= pageCount) {
      return new Array(pageCount).fill(null).map((_, index) => index + 1);
    }
    if (page < paginationSize - 3) {
      const numbers = new Array(paginationSize)
        .fill(null)
        .map<number>((_, index) => index + 1);
      numbers[numbers.length - 2] *= -1;
      numbers[numbers.length - 1] = pageCount;
      return numbers;
    }
    if (page > pageCount - paginationSize + 3) {
      const numbers = new Array(paginationSize)
        .fill(null)
        .map<number>((_, index) => index + pageCount - paginationSize + 1);
      numbers[0] = 1;
      numbers[1] *= -1;
      return numbers;
    }
    const middlePages = paginationSize - 4;
    const startPage = page - Math.floor(middlePages / 2);

    const numbers = [
      1,
      -2,
      ...new Array(middlePages).fill(null).map((_, index) => index + startPage),
      1 - pageCount,
      pageCount,
    ];
    return numbers;
  }, [pageCount, page]);

  return (
    <Container>
      <LeftContent>
        Exibindo
        <p>{itemsPerPage}</p>
        resultado(s) de
        <p>{total}</p>
        ao todo
      </LeftContent>
      <RightContent>
        <button
          type="button"
          onClick={() => change({ page: page - 1 })}
          disabled={page <= 1}
        >
          <RiArrowDropLeftFill />
        </button>
        {pageNumbers.map((num) => (num > 0 ? (
          <button
            type="button"
            key={num}
            onClick={() => change({ page: num })}
            className={`${page === num ? 'active' : ''}`}
            disabled={page === num}
          >
            {num}
          </button>
        ) : (
          <span key={num}>…</span>
        )))}
        <button
          type="button"
          onClick={() => change({ page: page + 1 })}
          disabled={page >= pageCount}
        >
          <RiArrowDropRightFill />
        </button>
      </RightContent>
    </Container>
  );
};

export default TablePagination;
