import api from './api';
import { BaseDTO } from '../@types/Core';
import { WorkflowDTO, WorkflowSDTO } from '../@types/Workflow';
import { BaseService } from './baseService';

class WorkflowService<E extends BaseDTO> extends BaseService<
  WorkflowDTO,
  WorkflowSDTO
> {
  baseURL = '/workflows';

  async firstStepInfo(id: E['id']): Promise<any> {
    return (await api.get(`${this.baseURL}/${id}/firstStepInfo`))?.data;
  }

  async execute(id: E['id'], dto: any): Promise<E> {
    return (await api.post(`${this.baseURL}/${id}/execute`, dto))?.data;
  }
}

export default new WorkflowService();
