import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999999;

  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white00};
    border-radius: 4px;
    padding: 32px;
    max-width: 430px;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: column;

    > img {
      max-width: 90px;
      margin: 0 auto 24px auto;
    }

    > h3 {
      color: ${theme.colors.black00};
      ${theme.texts.headingSM};
      /* max-width: 260px; */
      width: 100%;
      margin: 0 0 16px 0;
      white-space: pre;
      text-align: center;
    }
    
    > p {
      max-width: 304px;
      width: 100%;
      ${theme.texts.paragraphXS};
      font-weight: 500;
    }
  `}
`;

export const ContentButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 12px;

    width: 100%;
    margin: 24px 0 0 0;

    > button {
      border-radius: 4px;
      height: 46px;
      /* width: 100%; */
      flex: 1;
      background-color: transparent;
      border: 1px solid ${theme.colors.black20};
      ${theme.texts.buttonXS};
      
      transition: ${theme.transitions.default};

      &:hover {
        opacity: 0.8;
      }

      &:first-child {
        &:hover {
          border-color: ${theme.colors.black00};
          opacity: 1;
        }
      }

      &:last-child {
        background-color: ${theme.colors.error00};
        border-color: ${theme.colors.error00};
        color: ${theme.colors.white00};
      }
    }
  `};
`;
