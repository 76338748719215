import React, { ReactElement, Dispatch, SetStateAction } from 'react';

import { Container, TabNavigationItem } from './styles';

interface TabNavigationProps {
  customerType: 'PERSON' | 'COMPANY';
  changeCustomerType: (customerProps: 'PERSON' | 'COMPANY') => void;
}

const TabNavigation = ({ customerType, changeCustomerType }: TabNavigationProps): ReactElement => (
  <Container>
    <TabNavigationItem
      active={customerType === 'PERSON'}
      onClick={() => changeCustomerType('PERSON')}
    >
      Pessoa física
    </TabNavigationItem>
    <TabNavigationItem
      active={customerType === 'COMPANY'}
      onClick={() => changeCustomerType('COMPANY')}
    >
      Pessoa jurídica
    </TabNavigationItem>
  </Container>
);

export default TabNavigation;
