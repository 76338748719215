import React, { ReactElement, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { RiUpload2Line } from 'react-icons/ri';

import Button from '../Button';

import { Props } from './UploadDocument.spec';

import {
  Container,
  UploadDocumentContent,
  ChooseFile,
  ChooseOptions,
  ListDocumentContent,
} from './styles';
import { CoreFile } from '../../@types';
import ListDocument from './ListDocument';
import { InfoMode } from '../../@types/InfoMode';
import Select from '../Select';
import InputDate from '../DateInput';
import { formatDate } from '../../utils/formatters';

export const UploadDocument = ({
  documents,
  mode,
  handleChange,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [valueFile, setValueFile] = useState<any>();
  const [selectID, setSelectID] = useState();
  const [dueDate, setDueDate] = useState<string>();

  const modeView = mode !== InfoMode.VIEW;

  const categories = [
    { id: 1, text: 'Comprovante de renda' },
    { id: 2, text: 'Comprovante de residência' },
  ];

  const removeFile = (userFile: CoreFile): void => {
    if (userFile.id < 0) {
      handleChange(documents.filter((file) => file.id !== userFile.id));
    }
  };

  const editFile = (userFile: CoreFile): void => {
    const newDocuments = documents.map((file) => {
      if (file.id === userFile.id) {
        return userFile;
      }
      return file;
    });

    handleChange(newDocuments);
  };

  const submit = (): void => {
    if (!valueFile || !selectID) return;

    console.log(valueFile, selectID, dueDate);
    handleChange([...documents, {

      id: -Math.random(),
      filename: valueFile[0]?.name,
      size: valueFile[0]?.size,
      mimeType: valueFile[0]?.type,
      dueDate: dueDate ?? new Date().toISOString().slice(0, 10),
      file: valueFile[0],
      categoryText: categories.find((e) => e.id === selectID)?.text,
      categoryID: selectID,
    }]);
  };

  return (
    <Container>
      {modeView && (
        <>
          <p>{t('uploadDocumentComponent.titleDocument')}</p>
          <UploadDocumentContent>
            <ChooseFile>
              <label htmlFor="file-upload" className="custom-file-upload">
                {' '}
                Escolher arquivo
                <RiUpload2Line />
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={(e) => {
                  if (e) {
                    setValueFile(e?.target?.files);
                  }
                }}
              />

              {valueFile && valueFile.length ? (
                <p>
                  {' '}
                  {valueFile[0]?.name}
                </p>
              ) : (
                <p>Nenhum arquivo escolhido.</p>
              )}
            </ChooseFile>

            <ChooseOptions>
              <Select
                label="Categoria"
                options={categories}
                value={
                  selectID
                    ? categories?.find(
                      (e) => e.id === selectID,
                    )
                    : ''
                }
                onChange={(e: any) => {
                  setSelectID(e.id);
                  console.log(e.id);
                }}
                required
              />
              <InputDate
                label="Data de vencimento"
                selected={formatDate(dueDate)}
                onChange={(value) => setDueDate(value?.toISOString().slice(0, 10))}
              />
              <Button message="Adicionar anexo" onClick={() => submit()} type="button" disabled={!valueFile || !selectID} />
            </ChooseOptions>
          </UploadDocumentContent>
        </>
      )}

      <p>{t('uploadDocumentComponent.titleListDocument')}</p>
      <ListDocumentContent>
        {documents.length > 0 ? (
          <>
            {documents?.map((file: CoreFile) => (
              <ListDocument
                key={file?.id}
                file={file}
                onRemove={removeFile}
                onEdit={editFile}
                mode={mode || InfoMode.CREATE}
              />
            ))}
          </>
        ) : (
          <p>{t('uploadDocumentComponent.textNoAttachment')}</p>
        )}
      </ListDocumentContent>
    </Container>
  );
};
