import { CoreFile } from '../../@types';
import { AccountCore } from './CustomerDataSteps/BankData/BankData.spec';

export interface CustomerDataCore {
  // PRIMARY INFO
  fullName: string;
  documentNumber: string;
  birthdate?: string;
  civilStatusID: number;
  sexID: number;
  mobilePhoneNumber: string;
  email: string;
  professionID: number;
  income: string;
  netWorth: string;
  ownCompany: boolean;

  attachments: CoreFile[],

  // ADDRESS INFO
  postalCode: string;
  countryID: number;
  houseNumber: string;
  neighborhood: string;
  line1: string;
  complement: string;
  city: string;
  state: string;

  // Account info
  accounts: AccountCore[];

  // Company info
  companyDocumentNumber: string;
  companyLegalName: string;
  companyTradeName: string;
  companyBusiness: string;
  companyInscriptionState: string;
  companyInscriptionMunicipal: string;
  companyOpeningDate?: string | undefined;
  companyRelationship?: any;
  telephone: string
  partners: any[];
  socials: any[];
  companyBond: string;
  companyPercent: string;
  companyRole: string;
  companySalary: string;
  companyAdmissionDate: string | undefined;
  optingNationalSample: boolean;
}

export const INITIAL_VALUES: CustomerDataCore = {
  ownCompany: false,

  // PRIMARY DATA
  fullName: '',
  documentNumber: '',
  birthdate: '',
  civilStatusID: 0,
  sexID: 0,
  mobilePhoneNumber: '',
  email: '',
  professionID: 0,
  income: '',
  netWorth: '',

  // CORPORATE DATA
  companyDocumentNumber: '',
  companyLegalName: '',
  companyBond: '',
  companyPercent: '',
  companyBusiness: '',
  companyRole: '',
  companySalary: '',
  companyAdmissionDate: '',
  companyInscriptionMunicipal: '',
  companyInscriptionState: '',
  companyTradeName: '',
  companyOpeningDate: '',
  companyRelationship: {},
  optingNationalSample: false,
  partners: [],
  socials: [],
  telephone: '',

  // ADDRESS
  city: '',
  complement: '',
  neighborhood: '',
  houseNumber: '',
  postalCode: '',
  state: '',
  line1: '',
  countryID: 0,

  // OTHERS
  attachments: [],
  accounts: [],
};

export const COUNTRIES_OPTIONS = [
  {
    id: 1,
    text: 'Brasil',
  },
];

export const MARITAL_STATUS = [
  {
    id: 1,
    text: 'Solteiro(a)',
  }, {
    id: 2,
    text: 'Casado(a)',
  },
  {
    id: 3,
    text: 'Separado(a)',
  },
  {
    id: 4,
    text: 'Divorciado(a)',
  },
  {
    id: 5,
    text: 'Viúvo(a)',
  },
];

export const SEX_OPTIONS = [
  {
    id: 1,
    text: 'Masculino',
  }, {
    id: 2,
    text: 'Feminino',
  },
];
