import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > label {
      ${theme.texts.paragraphXS};
      font-weight: 400;
      margin: 0px 0px 8px;
      > small {
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: ${theme.colors.black50};
      }
    }

    .react-select__clear-indicator {
      > svg {
        font-size: 1.8rem !important;
      }
    }

    .react-select-container {
      height: 42px;
      z-index: 1000;

      .react-select__control {
        height: 100%;
        background-color: ${theme.colors.black05};
        box-shadow: none;
        border-color: ${theme.colors.black05};
        padding: 0 16px;

        .react-select__value-container {
          padding: 0;
          color: ${theme.colors.black00};
        }

        .react-select__indicators {
          svg {
            font-size: 1.4rem;
          }
        }

        .react-select__placeholder {
          color: ${theme.colors.black30};
          ${theme.texts.paragraphXS};
          /* font-weight: 400 !important; */
        }

        &:hover {
          border-color: none;
        }
      }

      .react-select__indicator-separator {
        display: none;
      }

      .react-select__single-value {
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: ${theme.colors.black00};
      }

      .react-select__option {
        background-color: transparent;
        border-radius: 4px;
        height: 38px;
        color: ${theme.colors.black00};
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.8rem;

        display: flex;
        align-items: center;
        cursor: pointer;
        transition: ${theme.transitions.default};

        &:not(:last-child) {
          margin: 0 0 4px 0 !important;
        }

        &.react-select__option--is-focused.react-select__option--is-selected {
          background-color: ${theme.colors.black10} !important;
          transition: ${theme.transitions.default};
          &:hover {
            background-color: ${theme.colors.black10} !important;
          }
        }

        &:hover {
          background-color: ${theme.colors.black10} !important;
        }
      }

      .react-select__menu-list {
        padding: 12px !important;
      }
    }
  `};
`;
