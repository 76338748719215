import React from 'react';
import { RiCarFill } from 'react-icons/ri';
import { faker } from '@faker-js/faker';
import { OperationItemProps, OperationStatusPropsItem } from '../@types';

import {
  ProductBrand,
  ResponsibleCircle,
  StatusTag,
} from '../components/TableComponents';

import { formatCurrency } from '../utils';

const getRandom = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min;

export const operationTableHeaderTitles = [
  'Operação',
  'Cliente',
  'Valor',
  'Status',
  'Agente Resp.',
  'Data',
];

export const operationTableKeys = [
  'operation',
  'customer',
  'value',
  'status',
  'responsible',
  'date',
];

export const operationStatus = [
  {
    title: 'Apr. alçada',
    bgColor: 'rgba(0, 219, 191, 0.5)',
  },
  {
    title: 'Pré-aprovado',
    bgColor: 'rgba(76, 228, 117, 0.5)',
  },
  {
    title: 'Pago',
    bgColor: 'rgba(20, 204, 69, 0.5)',
  },
  {
    title: 'Bancarização',
    bgColor: 'rgba(0, 128, 249, 0.2)',
  },
  {
    title: 'Em análise',
    bgColor: 'rgba(0, 200, 255, 0.3)',
  },
  {
    title: 'Pendente',
    bgColor: 'rgba(250, 158, 74, 0.5)',
  },
  {
    title: 'Em digitação',
    bgColor: 'rgba(255, 193, 7, 0.5)',
  },
  {
    title: 'Rec. alçada',
    bgColor: 'rgba(253, 127, 110, 0.5)',
  },
  {
    title: 'Não pago',
    bgColor: 'rgba(251, 94, 80, 0.5)',
  },
  {
    title: 'Cancelada',
    bgColor: 'rgba(179, 179, 179, 0.5)',
  },
] as Array<OperationStatusPropsItem>;

export const operationItems: OperationItemProps[] = [];

export function createRandomOperationItem(): OperationItemProps {
  const randomStatus = getRandom(0, 10);

  return {
    id: faker.datatype.uuid(),
    product: <ProductBrand icon={<RiCarFill />} />,
    operation: `#${faker.datatype.number({
      min: 0,
      max: 1000,
    })}`,
    customer: faker.name.fullName(),
    value: formatCurrency(Number(faker.finance.amount())),
    status: (
      <StatusTag
        bgColor={operationStatus[randomStatus]?.bgColor}
        status={operationStatus[randomStatus]?.title}
      />
    ),
    responsible: <ResponsibleCircle text={faker.name.firstName()?.charAt(0)} />,
    date: new Date(faker.date.past()).toLocaleString(),
  };
}

Array.from({ length: 1 }).forEach(() => {
  operationItems.push(createRandomOperationItem());
});

export default operationItems;
