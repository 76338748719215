import styled, { css } from 'styled-components';

export const BoxDocument = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background-color: ${theme.colors.white};

    border: 1px solid ${theme.colors.black20};
    border-radius: 6px;

    display: flex;
    align-items: center;

    padding: 14px 18px;
    margin-bottom: 14px;

    > svg {
      margin-right: 18px;
      font-size: 1.8rem;
    }

    .red-icon {
      color: ${theme.colors.error00};
    }

    .blue-icon {
      color: ${theme.colors.info00};
    }

    .green-icon {
      color: ${theme.colors.success00};
    }
  `}
`;

export const BoxDocumentContent = styled.div`
  ${({ theme }) => css`
    > small {
      ${theme.texts.paragraphXXXS};
      color: ${theme.colors.black50};
    }
  `}
`;

export const EditName = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > input {
      ${theme.texts.paragraphMD};

      border: none;
      border-bottom: 1px solid ${theme.colors.black20};

      width: 100%;
      max-width: 250px;
    }

    > p {
      ${theme.texts.paragraphMD};
      color: ${theme.colors.black00};

      border-bottom: 1px solid transparent;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      width: 100%;
      max-width: 250px;
    }

    > svg {
      font-size: 1.4rem;
      margin-left: 8px;
      cursor: pointer;
    }
  `}
`;

export const ActionsButtons = styled.div`
  ${({ theme }) => css`
    margin-left: auto;

    display: flex;
    align-items: center;
    gap: 19.5px;
    > div {
      > div {
        > svg {
          font-size: 18px;
          color: ${theme.colors.alert00};
        }
      }
    }

    & > div:last-child {
      display:  flex;
      align-items: center;
      gap: 6px;

      > button {
      height: 30px;
      width: 30px;
      padding: 9px;

      border-radius: 4px;

      &.delete {
        background-color: ${theme.colors.error00};
      }

      &.view {
        background-color: transparent;
        border: 1px solid ${theme.colors.black20};
        color: ${theme.colors.black00};
      }

      > div {
        right: 7px;

        > svg {
          font-size: 1.2rem;
        }
      }
    }
    }

   
  `}
`;

export const Flag = styled.span`
  ${({ theme }) => css`
    ${theme.texts.paragraphXXXS};
    background-color: ${theme.colors.info10};
    border-radius: 4px;
    padding: 3px 6px;
    margin-right: 18px;

    &.alert {
      background-color: ${theme.colors.alert10};
    }

  `}

    

`;
