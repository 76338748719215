import React, { ReactElement } from 'react';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { formatCurrency } from '../../utils';

import {
  Container,
  CardHeader,
  CardContent,
} from './styles';

interface CardProps {
  title: string;
  number: number;
  value: string;
}

const Card = ({ title = '', number = 0, value = '' }: CardProps): ReactElement => (
  <Container>
    <CardHeader>
      <h3>{title}</h3>
      {number}
    </CardHeader>

    <CardContent>
      <RiMoneyDollarCircleLine />
      <small>{formatCurrency(Number(value))}</small>
    </CardContent>
  </Container>
);

export default Card;
