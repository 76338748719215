import styled, { css } from 'styled-components';

export const Container = styled.footer`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.white00};
    z-index: 10;

    position: sticky;
    bottom: 0;

    margin: auto 0 0 0;
    padding: 16px 24px;
    border-top: 1px solid ${theme.colors.black10};
  `};
`;

export const StepsNavigation = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > small {
      ${theme.texts.paragraphXXS};
      color: ${theme.colors.black00};
    }
  `};
`;

export const StepsList = styled.div`
  ${({ theme }) => css`
    display: flex;

    margin: 0 0 0 2px;

    > span {
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black30};
      width: 32px;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      transition: ${theme.transitions.default};

      &:not(:last-child) {
        border-right: 0.5px solid ${theme.colors.black10};
      }

      > svg {
        font-size: 1.4rem;
        color: ${theme.colors.success00};
      }

      &.active {
        color: ${theme.colors.black00};
      }
    }
  `};
`;

export const FooterButtons = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    > button {
      height: 42px;
      width: fit-content;
      ${theme.texts.buttonXS};

      &:first-child {
        background-color: transparent;
        margin: 0 6px 0 0;
        padding: 10px 20px;
      }
    }
  `};
`;
