import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import { RiAddFill } from 'react-icons/ri';
import Button from '../Button';
import { Options, Container } from './styles';
import { socialsTypes, SocialsOptionsProps } from './SocialsOptions.spec';

function SocialsOptions({ registeredSocials, handleClick }: SocialsOptionsProps): ReactElement {
  const [showOptions, setShowOptions] = useState(false);
  const checkIfAlreadyRegistered = (id: number): boolean => registeredSocials.find((social) => social.id === id) !== undefined;

  return (
    <Container>
      <Button message="Adicionar novo" type="button" variant="secondary" icon={<RiAddFill />} onClick={() => setShowOptions(!showOptions)} />
      {
        showOptions && (
          <Options>
            {socialsTypes.map((social) => (
              <button
                key={social.id}
                type="button"
                onClick={() => {
                  handleClick(social);
                  setShowOptions(false);
                }}
                disabled={checkIfAlreadyRegistered(social.id)}
              >
                {social.name}
              </button>
            ))}
          </Options>
        )
      }
    </Container>
  );
}

export default SocialsOptions;
