import styled, { css, keyframes } from 'styled-components';

export const Container = styled.section`
  width: 100%;
  min-height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255, 0.8) !important;
  z-index: 99999;
  
  left: 0px;
  @media (max-width: 480px) {
  }
`;

export const LoadingCircle = styled.div`
  ${({ theme }) => css`

    border-radius: 50%;
    display: inline-block;

    border: 4px solid transparent;
    border-top: 4px solid ${theme.colors.primary};

    animation: ${spinAnimation} 0.8s ease-in-out infinite;
    width: 86px;
    height: 86px;
    position: relative;
  `}
`;

export const Circle = styled.div`
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`;
