import api from './api';
import { BaseDTO } from '../@types/Core';
import { WorkflowDTO, WorkflowSDTO } from '../@types/Workflow';
import { BaseService } from './baseService';

class WorkflowService<E extends BaseDTO> extends BaseService<
  WorkflowDTO,
  WorkflowSDTO
> {
  baseURL = '/workflow-executions';

  async stepInfo(id: E['id'], index: E['index']): Promise<E> {
    return (await api.get(`${this.baseURL}/${id}/stepInfo/${index}`))?.data;
  }

  async execute(id: E['id'], index: E['index'], dto: any): Promise<E> {
    return (await api.post(`${this.baseURL}/${id}/execute/${index}`, dto))?.data;
  }
}

export default new WorkflowService();
