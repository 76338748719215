import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    > p {
      ${theme.texts.paragraphXXS};
      margin-bottom: 14px;
    }
  `}
`;

export const UploadDocumentContent = styled.div`
  ${({ theme }) => css`
    margin-bottom: 24px;
    padding: 24px;
    border: 1px solid ${theme.colors.black10};
    border-radius: 6px;
  `}
`;

export const ChooseFile = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-bottom: 24px;
    > input {
      display: none;
    }

    .custom-file-upload {
      display: inline-block;
      padding: 10px 16px;
      cursor: pointer;
      background-color: ${theme.colors.black00};
      color: ${theme.colors.white00};
      border-radius: 4px;

      display: flex;
      align-items: center;

      ${theme.texts.buttonXXXS};

      > svg {
        font-size: 14px;
        margin-left: 6px;
      }
    }

    > p {
      ${theme.texts.paragraphSM};
      color: ${theme.colors.black30};

      padding: 10px 14px;

      width: 244px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  `}
`;

export const ChooseOptions = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: flex-end;
    
    > div {
      margin-right: 12px;
      width: 100%;
      max-width: 100%;
    }

    > button {
      ${theme.texts.buttonXXXS};
      padding: 12px 16px;
      border-radius: 4px;
      width: 320px;
      display: block;
    }
  `}
`;

export const ListDocumentContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;

    > p {
      ${theme.texts.paragraphMD};
      color: ${theme.colors.black50};
      margin: 0 auto 14px;
    }
  `}
`;
