import React, { ReactElement } from 'react';
import ProductListModal from '../ProductListModal';

import { Container } from './styles';

interface ProductSelectModalProps {
  handleCloseModal?: () => void;
}

const ProductSelectModal = ({ handleCloseModal }: ProductSelectModalProps): ReactElement => (
  <Container>
    <ProductListModal handleCloseModal={handleCloseModal} />
  </Container>
);

export default ProductSelectModal;
