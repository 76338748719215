import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  .rc-tooltip-arrow {
    left: 50% !important;
    margin-left: 0 !important;
  }
`;

export const Form = styled.form`
  > button {
    width: 100%;
  }
`;

export const InputControlRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  gap: 12px;

  :last-child {
    margin-bottom: 0;
  }

  > div {
    flex: 1;
    max-width: 50%;
  }
`;

export const Result = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResultTitle = styled.div`
  ${({ theme }) => css`
    text-align: center;
    position: relative;
    margin: 0 0 24px 0;

    > h3 {
      ${theme.texts.headingXXS};

      width: auto;
      display: inline-block;
      z-index: 3;
      padding: 0 20px 0 20px;
      position: relative;

      margin: 0;
    }

    &::before {
      content: '';
      width: 100%;
      max-width: 197.5px;
      border-bottom: solid 1px ${theme.colors.black10};
      position: absolute;
      left: 0;
      top: 50%;
      z-index: 1;
    }

    &::after {
      content: '';
      width: 100%;
      max-width: 197.5px;
      border-bottom: solid 1px ${theme.colors.black10};
      position: absolute;
      right: 0;
      top: 50%;
      z-index: 1;
    }
  `};
`;

export const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  button {
    flex: 1;
  }
`;

export const CheckBoxControl = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    ${theme.texts.paragraphSM};
    display: flex;
    align-items: center;
    margin: 0 0 24px 0;

    transition: ${theme.transitions.default};

    > div {
      display: flex;
      align-items: center;
      margin: 0 0 0 8px;

      > div {
        display: flex;
        align-items: center;

        > svg {
          font-size: 1.4rem;
          cursor: pointer;
        }
      }
    }

    ${disabled
    && css`
      opacity: 0.4;
      cursor: not-allowed;
    `};
  `};
`;

export const DatePickerController = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    label {
      line-height: unset;
      ${theme.texts.paragraphXS};
      color: ${theme.colors.black00};
      margin: 0px 0px 8px;

      > small {
        color: ${theme.colors.black50};
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
    }
  `}
`;
