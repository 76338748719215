import styled, { css } from 'styled-components';

export const ContentModal = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    
    > button {
      margin-left: auto;
      border-radius: 4px;
    }

    > div:first-child {
      padding-left: 0px;
      padding-right: 0px;
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    width: 900px;
    height: 1px;
    background-color: ${theme.colors.black10};

    position: relative;
    left: -32px;
    bottom: 66px;
  `};
`;

export const FooterButton = styled.div`
  padding-top: 32px;
  > button {
    margin-left: auto;
    border-radius: 4px;
    height: 42px;
  }
`;

export const ContainerButton = styled.div`
  > button {
  margin-left: auto;
  border-radius: 4px;
  height: 42px;
}
`;
