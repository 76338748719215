export const TRANSLATIONS_EN = {
  buttonLoadingMessage: 'Loading...',
  sidebar: {
    home: 'Página Inicial',
    registration: 'Registration',
    users: 'Users',
    agencyOffice: 'Agency Office',
    representatives: 'Representatives',
    products: 'Products',
    customers: 'Customers',
    funds: 'Funds',
    operations: 'Operations',
    management: 'Management',
    workflows: 'Workflows',
    help: 'Help',
    logout: 'Logout',
  },
  signIn: {
    back: 'Back to homepage',
    title: 'Login to your account',
    subTitle: 'Log in and manage your credit intelligence.',
    show: 'Show',
    email: 'Corporate email',
    emailPlaceholder: 'name@company.com',
    password: 'Password',
    accessText: 'Sign In',
    supportText: "Don't have access? Ask the administrator.",
    keepConnected: 'Keep connected',
    forgotPassword: 'Forgot your password?',
  },
  forgotPassword: {
    back: 'Back to login',
    title: 'Forgot your password?',
    subTitle: "Don't worry. We will send a link to your registered email with instructions on how to reset your password.",
    email: 'Corporate email',
    emailPlaceholder: 'name@company.com',
    button: 'Reset password',
  },
  newPassword: {
    back: 'Back to login',
    title: 'New password',
    subTitle: 'Create your new password to login. The password must be different from the currently used password.',
    button: 'Create new password',
    label: 'New password',
    repeatLabel: 'Repeat new password',
  },
  userPrimaryData: {
    labelInputName: 'Name full',
    labelInputCPF: 'CPF',
    labelInputCivilStatus: 'Estado civil',
    labelInputCEP: 'CEP',
    labelInputAddress: 'Endereço',
    labelInputNumber: 'Número',
    labelInputComplement: 'Complemento',
    labelInputNeighborhood: 'Bairro',
    labelInputCity: 'Cidade',
    labelInputUF: 'UF',
    labelInputTelephone: 'Telefone',
    labelInputPhone: 'Celular',
    labelInputNationality: 'Nacionalidade',
    labelInputBirthDate: 'Data de nascimento',
    labelInputGender: 'Sexo',
    labelInputRG: 'RG',
    labelInputIssuingAgency: 'Órgão expedidor',
    labelInputShippingDate: 'Data de expedição',
    labelInputEducation: 'Escolaridade',
    labelInputEmail: 'E-mail',
    labelInputMotherName: 'Nome da mãe',
    labelInputFatherName: 'Nome do pai',
    labelInputNaturalness: 'Naturalidade',
    labelInputUFNaturalness: 'UF (Naturalidade)',
    placeholderInputName: 'Digite seu nome completo',
    placeholderInputCPF: 'Digite seu CPF',
    placeholderInputCivilStatus: 'Selecione',
    placeholderInputCEP: 'Digite seu CEP',
    placeholderInputAddress: 'Digite seu endereço',
    placeholderInputNumber: 'Digite o número',
    placeholderInputComplement: 'Apto',
    placeholderInputNeighborhood: 'Digite o bairro',
    placeholderInputCity: 'Digite a cidade',
    placeholderInputUF: 'Selecione',
    placeholderInputTelephone: 'Digite o telefone',
    placeholderInputPhone: 'Digite o celular',
    placeholderInputNationality: 'Digite a nacionalidade',
    placeholderInputBirthDate: 'Digite a data de nascimento',
    placeholderInputGender: 'Selecione',
    placeholderInputRG: 'Digite o RG',
    placeholderInputIssuingAgency: 'Digite o órgão expedidor',
    placeholderInputShippingDate: 'Digite a data de expedição',
    placeholderInputEducation: 'Selecione',
    placeholderInputEmail: 'Digite o e-mail',
    placeholderInputMotherName: 'Digite o nome da mãe',
    placeholderInputFatherName: 'Digite o nome do pai',
    placeholderInputNaturalness: 'Digite a naturalidade',
    placeholderInputUFNaturalness: 'Selecione',
    titlePersonalData: 'Dados Pessoais',
    titleJurisdiction: 'Alçada',
    titleAddress: 'Endereço',
    titleContacts: 'Contatos',
    titleFamily: 'Família',
  },
  simulatorModal: {
    title: 'Simulação de parcelas',
    labelInputProduct: 'Produto',
    placeholderInputProduct: 'Selecione o produto desejado',
    labelInputValueNominal: 'Valor nominal',
    labelInputNumberOfInstallments: 'Número de parcelas',
    labelInputFirstDueDate: '1º Vencimento',
    labelInputValueReleased: 'Valor liberado',
    labelInputNumberOfDays: 'Número de dias para o 1º vencimento',
    labelInputLastDueDate: 'Último vencimento',
    labelInputInstallmentValue: 'Valor da parcela',
    buttonSimulate: 'Simular',
    lastInstallmentDueDate: 'Último vencimento',
    // buttonRemakeSimulate: 'Refazer simulação',
    // buttonContinueWithData: 'Continuar com os dados acima',
  },
  excludeModal: {
    vehicleWarranty: {
      title: 'excluir esta garantia?',
      text: 'Deseja excluir a garantia',
      button: 'Excluir garantia',
    },
  },
  headerTitle: {
    PRE_ANALYSIS: 'Realize uma pré-análise de crédito',
    PRE_ANALYSIS_true: 'Pré-análise de crédito',
    PRE_ANALYSIS_false: 'Pré-análise de crédito',
    PRODUCT_SELECT: 'Seleção de produto',
    COLLATERAL_VEHICLE_DATA: 'Garantia de veículo',
    OPERATION_DATA: 'Dados da operação',
    CUSTOMER_DATA: 'Dados do cliente',
    GUARANTOR_DATA: 'Avalistas',
    ADDRESS: 'Endereço',
    DOCUMENTS: 'Checklist',
    CONFIRMATION_DATA: 'Confirmação dos dados',
  },
  buttonText: {
    PRE_ANALYSIS: 'Realizar análise',
    PRE_ANALYSIS_true: 'Adicionar operação',
    PRE_ANALYSIS_false: 'Adicionar observação',
    ANALYSIS: 'Adicionar operação',
    PRODUCT_SELECT: 'Continuar',
    COLLATERAL_VEHICLE_DATA: 'Continuar',
    OPERATION_DATA: 'Continuar',
    CUSTOMER_DATA: 'Continuar',
    ADDRESS: 'Continuar',
    DOCUMENTS: 'Continuar',
    CONFIRMATION_DATA: 'Enviar operação',
  },
  buttonLoadingText: {
    PRE_ANALYSIS: 'Analisando..',
    PRE_ANALYSIS_true: 'Adicionando..',
    ANALYSIS: 'Adicionar operação',
    PRODUCT_SELECT: 'Carregando..',
    COLLATERAL_VEHICLE_DATA: 'Carregando..',
    OPERATION_DATA: 'Carregando..',
    CUSTOMER_DATA: 'Carregando..',
    ADDRESS: 'Carregando..',
    DOCUMENTS: 'Carregando..',
    CONFIRMATION_DATA: 'Enviando..',
  },
};
