/* eslint-disable class-methods-use-this */
import api from './api';

import { BaseDTO } from '../@types/Core';
import { WorkflowDTO, WorkflowSDTO } from '../@types/Workflow';
import { BaseService } from './baseService';

class OperationsService<E extends BaseDTO> extends BaseService<
  any,
  any
> {
  baseURL = '/operations';

  async execute(id: E['id'], dto: any): Promise<E> {
    return (await api.post(`${this.baseURL}/${id}/execute`, dto))?.data;
  }

  // Convert the operation in an operation
  async createWorkflowExecution(id: E['id']): Promise<void> {
    return (await api.post(`${this.baseURL}/workflow-execution/${id}`))?.data;
  }

  getStep(
    operationSteps: any,
    currentOperationStep: string,
    next?: boolean,
  ): boolean | void | any {
    try {
      const getStepsKeys = Object.keys(operationSteps);
      const currentKeyIndex = getStepsKeys.indexOf(currentOperationStep);
      const navigateStep = getStepsKeys[`${next ? currentKeyIndex + 1 : currentKeyIndex - 1}`];

      if (navigateStep) {
        return navigateStep;
      }
      return false;
    } catch {
      return console.log('Unable to get prev/next steps based in parameters!');
    }
  }
}

export default new OperationsService();
