import axios from 'axios';
import qs from 'qs';

const baseURL = process.env.REACT_APP_BASE_URL || '';

const api = axios.create({
  baseURL,
  paramsSerializer: (params) => qs.stringify(params),
});

export default api;
