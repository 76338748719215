import React, { ReactElement } from 'react';
import { FormikProps } from 'formik';
import InputCurrency from '../../../../../CurrencyInput';
import InputControl from '../../../../../Input';
import InputMask from '../../../../../MaskedInput';
import SelectField from '../../../../../Select';
import { InputControlRow } from '../../../../styles';
import { cnpjMask, phoneMask8 } from '../../../../../../utils/masks';
import InputDate from '../../../../../DateInput';
import { ProfessionFormProps } from '../PrimaryData.spec';
import { MaskedInput } from '../../../../..';
import { formatDate } from '../../../../../../utils/formatters';

export function SalariedForm({ formik }: ProfessionFormProps): ReactElement {
  return (
    <>
      <h2>Trabalho</h2>

      <InputControlRow>
        <InputMask
          name="companyDocumentNumber"
          label="CNPJ"
          placeholder="Digite o CNPJ"
          guide={false}
          mask={cnpjMask}
          value={formik.values.companyDocumentNumber}
          onChange={({ target: { value } }) => formik.setFieldValue(
            'companyDocumentNumber',
            value?.replace(/\D/g, ''),
          )}
        />
        <InputControl
          name="companyLegalName"
          label="Nome da empresa"
          placeholder="Digite o nome da empresa"
          value={formik.values.companyLegalName}
          onChange={formik.handleChange}
        />
      </InputControlRow>
      <InputControlRow>
        <SelectField
          name="companyBusiness"
          label="Ramo da empresa"
          value=""
          message={formik.errors.companyBusiness}
          onChange={(selectedOption: any) => formik.setFieldValue('companyBusiness', selectedOption.id)}
          options={[]}
        />
        <InputControl
          name="companyRole"
          label="Cargo"
          placeholder="Digite seu cargo"
          value={formik.values.companyRole}
          onChange={formik.handleChange}
        />
      </InputControlRow>
      <InputControlRow>
        <InputCurrency
          name="companySalary"
          label="Salário"
          placeholder="Digite seu salário"
          prefix="R$"
          value={formik.values.companySalary}
          onStringChange={(value) => formik.setFieldValue(
            'companySalary',
            value,
          )}
        />
        <InputDate
          name="companyAdmissionDate"
          label="Data de admissão"
          selected={formik.values.companyAdmissionDate || null}
          placeholderText="01/01/2001"
          onChange={(value: any) => {
            formik.setFieldValue('companyAdmissionDate', value);
          }}
          disabledDate={(value: any) => value > new Date()}
        />
      </InputControlRow>
      <InputControlRow>
        <MaskedInput
          name="telephone"
          label="Telefone comercial"
          placeholder="(99) 9999 9999"
          guide={false}
          mask={phoneMask8}
          value={formik.values.telephone}
          onChange={({ target: { value } }) => formik.setFieldValue(
            'telephone',
            value?.replace(/\D/g, ''),
          )}
        />
      </InputControlRow>
    </>
  );
}
