import { faker } from '@faker-js/faker';
import { CoreSelect } from '../@types';

export const selectItems: CoreSelect[] = [];
export const selectJurisdictionItems: CoreSelect[] = [];
export const selectEducationItems: CoreSelect[] = [];
export const selectGenderItems: CoreSelect[] = [];
export const selectAccessDays: CoreSelect[] = [];
export const selectAccessTime: CoreSelect[] = [];
export const selectAccessExternal: CoreSelect[] = [];
export const selectChangeRates: CoreSelect[] = [];

export function createRandomSelectItem(): any {
  return {
    id: faker.datatype.uuid(),
    text: faker.name.firstName(),
  };
}

const options = [
  selectItems,
  selectJurisdictionItems,
  selectEducationItems,
  selectGenderItems,
  selectAccessDays,
  selectAccessTime,
  selectAccessExternal,
  selectChangeRates,
];

Array.from({ length: 4 }).forEach(() => {
  options.forEach((element) => element.push(createRandomSelectItem()));
});
