import React, { ReactElement, useState } from 'react';
import {
  RiCheckLine,
  RiDeleteBin5Line,
  RiEdit2Line,
  RiFileCopyLine,
  RiUser2Line,
} from 'react-icons/ri';

import {
  Container,
  DynamicItem,
  HeaderItem,
  ItemContent,
  TagContent,
  TagItem,
  ActionsButton,
  BodyItem,
} from './styles';
import Button from '../Button';
import { DynamicListProps } from './DynamicList.spec';
import { InfoMode } from '../../@types/InfoMode';
import ExcludeModal from '../ExcludeModal';

const DynamicList = ({
  item,
  onRemove,
  children,
  mode,
  type,
  visibleValue,
  onCancel,
  duplicate,
  onSubmit,
  edit,
  dynamicTags,
}: DynamicListProps): ReactElement => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [removeItem, setRemoveItem] = useState<boolean>(false);

  return (
    <Container key={item.id}>
      <DynamicItem>
        <HeaderItem>
          {type === 'businessPartner' && <RiUser2Line />}
          <ItemContent>
            <h2>{item?.name || 'Nome'}</h2>
            <span>
              {item?.responsability?.name || (item?.spouse ? 'Cônjuge' : '')}
            </span>

            {dynamicTags ? (
              <TagContent>
                {dynamicTags?.map((tagItem) => (
                  <TagItem bgColor={tagItem?.bgColor} key={tagItem?.field}>
                    {tagItem?.title}
                    {' '}
                    {tagItem?.prefix}
                    {' '}
                    {item[tagItem?.field]?.name
                      || (tagItem.labelFormatter
                        ? tagItem.labelFormatter(item[tagItem?.field])
                        : item[tagItem?.field])
                      || '0'}
                    {tagItem?.suffix}
                  </TagItem>
                ))}
              </TagContent>
            ) : (
              ''
            )}
          </ItemContent>
          {mode !== InfoMode.VIEW && (
            <ActionsButton>
              {isVisible ? (
                <>
                  {item?.id > 0 && (
                    <Button
                      type="button"
                      className="cancel"
                      message="Cancelar"
                      onClick={() => {
                        setIsVisible(false);
                        onCancel?.();
                      }}
                    />
                  )}
                  <Button
                    type="button"
                    message=""
                    variant="secondary"
                    onClick={() => {
                      onSubmit?.();
                      setIsVisible(false);
                    }}
                    icon={<RiCheckLine />}
                  />
                </>
              ) : (
                <>
                  {item?.enabled ? <p>Ativada</p> : <p>Desativada</p>}
                  <Button
                    type="button"
                    message=""
                    variant="secondary"
                    onClick={() => {
                      edit?.();
                      setIsVisible(true);
                    }}
                    icon={<RiEdit2Line />}
                  />
                </>
              )}
              {type !== 'businessPartner' && (
                <Button
                  type="button"
                  className="parameterization"
                  message=""
                  variant="secondary"
                  icon={<RiFileCopyLine />}
                  onClick={() => duplicate?.()}
                />
              )}
              <Button
                type="button"
                message=""
                icon={<RiDeleteBin5Line />}
                className="delete"
                onClick={() => setRemoveItem(true)}
              />
            </ActionsButton>
          )}
        </HeaderItem>

        {isVisible && <BodyItem>{children}</BodyItem>}
      </DynamicItem>
      {removeItem && (
        <ExcludeModal
          type={type}
          visibleValue={visibleValue}
          handleCloseModal={() => setRemoveItem(false)}
          handleDeleteConfirmation={() => {
            onRemove();
            setRemoveItem(false);
          }}
        />
      )}
    </Container>
  );
};

export default DynamicList;
