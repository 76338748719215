const documentsItems = [
  {
    id: 1,
    description: 'Comprovante de endereço',
    checked: true,
    required: true,
    category: 'address',
  },
  {
    id: 2,
    description: 'Documento com foto',
    checked: false,
    required: true,
    category: 'picture',
  },
  {
    id: 3,
    description: 'Selfie com documento',
    checked: false,
    required: false,
    category: 'selfie',
  },
  {
    id: 4,
    description: 'Comprovante de renda',
    checked: true,
    required: true,
    category: 'income',
  },
  {
    id: 5,
    description: 'Documentos oficiais de pessoa jurídica',
    checked: false,
    required: false,
    category: 'legalPerson',
  },
  {
    id: 6,
    description: 'Documentos oficiais dos sócios da empresa',
    checked: false,
    required: false,
    category: 'businessPartner',
  },
];

export default documentsItems;
