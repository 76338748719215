import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    > h1 {
      ${theme.texts.headingMD};
      margin-bottom: 32px;
    }

    .tabNavigationHeader {
      /* padding: 24px 0 0 0; */
      position: sticky;
      top: 110px;
      z-index: 1000;
    }
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    background-color: ${theme.colors.white00};

    position: sticky;
    top: 0;
    z-index: 1000;

    padding: 24px;

    > h1 {
      color: ${theme.colors.black00};
      letter-spacing: -0.01em;
      line-height: 3rem;
      font-size: 2.4rem;
      font-weight: 600;

      display: flex;
      flex-direction: column;

      > small {
        font-weight: 400;
        opacity: 0.5;
        font-size: 1.3rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }

      > span {
        display: flex;
        align-items: center;

        > button {
          margin: 0 12px 0 0;
        }
      }
    }

    > button {
      border: 1px solid rgba(20, 18, 30, 0.1);
      border-radius: 4px;
      height: 36px;
      color: ${theme.colors.black00};
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 1.6rem;
      padding: 10px 18px;
      background-color: transparent;
      margin-top: auto;
    }
  `};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ActionsButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid ${theme.colors.black10};
    background-color: ${theme.colors.white00};

    position: sticky;
    bottom: 0;
    width: 100%;

    > a {
      ${theme.texts.buttonXS};
      color: ${theme.colors.black00};
      background-color: transparent;
      /* margin-right: 24px; */
    }

    > button {
      ${theme.texts.buttonXS};
      padding: 10px 20px;
      width: auto;
      height: 42px;
      border-radius: 4px;
    }

    > a {
      ${theme.texts.buttonXS};
      padding: 10px 20px;
      border-radius: 4px;

      color: ${theme.colors.white00};
      background-color: ${theme.colors.black00};

      &:hover {
        opacity: 0.9;
      }
    }
  `}
`;

export const Divider = styled.div`
  /* ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.black10};
    padding: 24px 0 0;
    width: 100%;
    position: absolute;
    bottom: 66px;
    left: 0;
  `} */
`;

export const PrevButton = styled(NavLink)`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 1px solid ${theme.colors.black10};
    border-radius: 4px;
    color: ${theme.colors.black00};
    font-size: 1.6rem;
    margin: 0 12px 0 0;

    transition: ${theme.transitions.default};

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border-color: ${theme.colors.black00};
    }
  `};
`;
