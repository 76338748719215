import styled, { css } from 'styled-components';

export const SelectContainer = styled.div`
  ${({ theme }) => css`
  
    position: absolute;
    border: 1px solid ${theme.colors.black20};
    width: 100%;
    margin-top: 12px;
    z-index: 10;
    padding: 12px 14px;
    border-radius: 4px;
    background-color: ${theme.colors.white00};

    > button {
      padding: 10px;
      border-radius: 4px;
      width: 100%;
      ${theme.texts.paragraphXS}
      background-color: transparent;
      display: flex;
      justify-content: left;

      &:hover {
        background-color: ${theme.colors.black10};
      }
    }

    button + button {
      margin-top: 4px;
    }
  `}
`;
