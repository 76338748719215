import React, {
  ReactElement, useContext,
} from 'react';
import { OperationInputContext } from '../../routes/AuthenticatedRoutes';
import { CustomerType } from '../../@types/CustomerData';
import { CustomerDataCompany, CustomerDataPerson } from './CustomerDataSteps';

const formsByCustomerType = {
  PERSON: <CustomerDataPerson />,
  COMPANY: <CustomerDataCompany />,
};

function getFormByCustomerType(customerType: CustomerType): ReactElement {
  return formsByCustomerType[customerType];
}

const CustomerData = (): ReactElement => {
  const { selectedCustomerType } = useContext(OperationInputContext);

  return getFormByCustomerType(selectedCustomerType);
};

export default CustomerData;
