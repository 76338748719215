import {
  selectAccessDays,
  selectAccessExternal,
  selectAccessTime,
  selectChangeRates,
  selectEducationItems,
  selectGenderItems,
  selectItems,
  selectJurisdictionItems,
} from '../mock/selects';

export default {

  getSelectItems(): any {
    try {
      return selectItems;
    } catch {
      return console.log('Unable to list types items!');
    }
  },

  getJurisdiction(): any {
    try {
      return selectJurisdictionItems;
    } catch {
      return console.log('Unable to list jurisdiction items!');
    }
  },

  getEducation(): any {
    try {
      return selectEducationItems;
    } catch {
      return console.log('Unable to list education items!');
    }
  },

  getGender(): any {
    try {
      return selectGenderItems;
    } catch {
      return console.log('Unable to list gender types items!');
    }
  },

  getAccessDays(): any {
    try {
      return selectAccessDays;
    } catch {
      return console.log('Unable to list access days types items!');
    }
  },

  getAccessTime(): any {
    try {
      return selectAccessTime;
    } catch {
      return console.log('Unable to list access time types items!');
    }
  },

  getAccessExternal(): any {
    try {
      return selectAccessExternal;
    } catch {
      return console.log('Unable to list access external types items!');
    }
  },

  getChangeRates(): any {
    try {
      return selectChangeRates;
    } catch {
      return console.log('Unable to list cgange rates types items!');
    }
  },
};
