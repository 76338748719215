import { useFormik } from 'formik';
import React, {
  ReactElement, useContext,
  useState,
} from 'react';

import {
  TabNavigation,
  OperationStepFormBase,
} from '../../..';

import { OperationStepsContext } from '../../../../pages/Operations';
import { YUP_SCHEMA_COMPANY } from './CustomerDataCompany.spec';
import { CustomerDataCore, INITIAL_VALUES } from '../../CustomerData.spec';
import { PrimaryData } from './PrimaryData';
import { Address } from '../Address';
import { UploadDocument } from '../../../UploadDocument';
import { BankData } from '../BankData';
import { SocialNetwork } from './Social';
import Agent from './Agent';

export const CustomerDataCompany = (): ReactElement => {
  const { currentStepData, handleStepSubmission, setCurrentOperationStep } = useContext(OperationStepsContext);

  const formik = useFormik<CustomerDataCore>({
    enableReinitialize: true,
    // validationSchema: YUP_SCHEMA_COMPANY,
    validateOnChange: false,
    initialValues: currentStepData?.inputData ?? INITIAL_VALUES,
    onSubmit: (values) => {
      const { birthdate, ...allOtherProps } = values;

      console.log(values);

      // handleStepSubmission({
      //   ...allOtherProps,
      //   birthdate: birthdate?.toISOString().split('T')[0],
      // });
    },
  });

  const tabs = [
    {
      title: 'Dados primários',
      element: <PrimaryData formik={formik} />,
      enabled: true,
    },
    {
      title: 'Endereço',
      element: <Address formik={formik} />,
      enabled: true,
    },
    {
      title: 'Documentos',
      element: <UploadDocument documents={formik.values.attachments} handleChange={(files) => formik.setFieldValue('attachments', files)} />,
      enabled: true,
    },
    {
      title: 'Sócios/representantes',
      element: <Agent agentList={formik.values.partners} setAgentList={(partners) => formik.setFieldValue('partners', partners)} />,
      enabled: true,
    },
    {
      title: 'Dados bancários',
      element: <BankData accountList={formik.values.accounts} setAccountList={(accounts) => formik.setFieldValue('accounts', accounts)} />,
      enabled: true,
    },
    {
      title: 'Social',
      element: <SocialNetwork />,
      enabled: true,
    },
  ];

  return (
    <OperationStepFormBase handleSubmit={formik.handleSubmit}>
      <TabNavigation tabNavigationItems={tabs} />
    </OperationStepFormBase>
  );
};
