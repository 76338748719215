import _ from 'lodash';
import { FindOptions, Page } from '../@types/Core';
import {
  ProductVariantDTO,
  ProductVariantSDTO,
} from '../@types/ProductVariant';
import { BaseService } from './baseService';

class ProductVariantService extends BaseService<
  ProductVariantDTO,
  ProductVariantSDTO
> {
  baseURL = '/product-variants';

  async getPageByProductID(
    productID: number,
    findOptions?: FindOptions,
  ): Promise<Page<ProductVariantSDTO>> {
    return super.getPage({
      filters: _.merge(
        {
          productID: {
            $eq: productID,
          },
        },
        findOptions,
      ),
    });
  }
}

export default new ProductVariantService();
