import React, { ReactElement, useContext } from 'react';
import { RiAddFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { Button, DynamicList } from '../../../../../components';

import { CustomerContext } from '../../CustomerInfo';

import HandshakeIcon from '../../../../../assets/HandshakeIcon.svg';

import {
  Container, HeaderBox, BoxLeft, BoxRight, NoContent,
} from './styles';
import { CoreGuarantors } from '../../../../../@types';

import FormGuarantors from './FormGuarantors';
import { InfoMode } from '../../../../../@types/InfoMode';

const Guarantors = (): ReactElement => {
  const {
    formik, mode,
  } = useContext(CustomerContext) as any;

  const { t } = useTranslation();

  const { guarantors } = formik.values as any;

  const removeGuarantors = (item: CoreGuarantors) => () => {
    formik.setFieldValue(
      'guarantors',
      guarantors?.filter((e: any) => e !== item),
    );
  };

  const spouse = guarantors?.find((e: any) => e.spouse);

  return (
    <Container>
      <HeaderBox>
        <BoxLeft>
          <h2>{t('customerGuarantors.titleGuarantors')}</h2>
          <p>
            <span>{formik.values.guarantors?.length || 0}</span>
            {' '}
            {t('customerGuarantors.textFoundGuarantors')}
            {' '}
          </p>
        </BoxLeft>
        <BoxRight>
          <Button
            type="button"
            message="Novo avalista"
            icon={<RiAddFill />}
            onClick={() => formik.setFieldValue('guarantors', [
              ...formik.values.guarantors,
              { id: -Math.random() },
            ])}
            disabled={mode === InfoMode.VIEW}
          />
        </BoxRight>
      </HeaderBox>
      {guarantors.length > 0 ? (
        <>
          {guarantors?.map((guarantor: any, index: number) => (
            <DynamicList
              item={guarantor}
              key={guarantor.id}
              onRemove={removeGuarantors(guarantor)}
              mode={mode}
              type="guarnator"
              visibleValue={guarantor.name}
            >
              <FormGuarantors
                index={index}
                guarantor={guarantor}
                mode={mode}
                disableSpouse={spouse && spouse !== guarantor}
              />
            </DynamicList>
          ))}
        </>
      ) : (
        <NoContent>
          <img src={HandshakeIcon} alt="Handshake Icon" />
          <h2>{t('customerGuarantors.titleNoGuarantor')}</h2>
          <p>{t('customerGuarantors.textNoGuarantor')}</p>
        </NoContent>
      )}

    </Container>
  );
};

export default Guarantors;
