/* eslint-disable import/no-duplicates */
import { FormikProps } from 'formik';
import React, {
  ReactElement,
  useEffect, useState,
} from 'react';
import dayjs from 'dayjs';
import {
  Form,
  InputControlRow,
} from '../../../styles';

import {
  cpfMask,
  phoneMask9,
} from '../../../../../utils/masks';
import 'dayjs/locale/pt-br';
import {
  Input as InputControl,
  InputCurrency,
  DateInput as InputDate,
  MaskedInput as InputMask,
  Loading,
  QueryInput,
  Select as SelectField,
} from '../../../..';
import { ProfessionSDTO } from '../../../../../@types/Profession';
import ProfessionService from '../../../../../services/profession';
import {
  CustomerDataCore,
} from '../../../CustomerData.spec';
import { ProfessionBasedFormFields } from './PrimaryData.spec';
import { EntrepeneurForm, SalariedForm } from './ProfessionsForm';
import { formatDate } from '../../../../../utils/formatters';
import { useListItem } from '../../../../../hooks';
import { civilStatusService, sexService } from '../../../../../services';

interface PrimaryDataProps {
  formik: FormikProps<CustomerDataCore>;
}

export function PrimaryData({ formik }: PrimaryDataProps): ReactElement {
  const [professions, setProfessions] = useState<ProfessionSDTO[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { item: selectSex } = useListItem(sexService);
  const { item: selectCivilStatus } = useListItem(civilStatusService);

  function renderProfessionBasedForm(professionID?: number): ReactElement | null {
    if (!professionID) return null;

    const FORMS: ProfessionBasedFormFields = {
      7: <EntrepeneurForm formik={formik} />,
    };

    return FORMS[professionID] || <SalariedForm formik={formik} />;
  }

  useEffect(() => {
    setIsLoading(true);
    const getProfessions = async (): Promise<void> => {
      const response = await ProfessionService.getList();
      setProfessions(response);
      setIsLoading(false);
    };
    getProfessions();
  }, []);

  return (
    <Form>
      <InputControlRow>
        <QueryInput
          name="documentNumber"
          label="CPF"
          placeholder="000.000.000-00"
          guide={false}
          mask={cpfMask}
          value={formik.values.documentNumber}
          onChange={({ target: { value } }) => formik.setFieldValue('documentNumber', value?.replace(/\D/g, ''))}
          message={formik.errors.documentNumber}
          required
        />
        <InputControl
          name="fullName"
          label="Nome completo"
          placeholder="Digite seu nome"
          value={formik.values.fullName}
          onChange={formik.handleChange}
          message={formik.errors.fullName}
          required
        />
      </InputControlRow>
      <InputControlRow>
        <InputDate
          name="birthdate"
          label="Data de nascimento"
          placeholderText="00/00/0000"
          selected={formik?.values.birthdate}
          onChange={(value: any) => {
            formik.setFieldValue('birthdate', value);
          }}
          message={formik.errors.birthdate}
          required
          disabledDate={(value: any) => value > new Date()}
        />

        <SelectField
          name="civilStatusID"
          label="Estado civil"
          value={selectCivilStatus?.find((status) => status.id === formik.values.civilStatusID)}
          message={formik.errors.civilStatusID}
          onChange={(selectedOption: any) => formik.setFieldValue('civilStatusID', selectedOption.id)}
          options={selectCivilStatus}
          required
        />
      </InputControlRow>
      <InputControlRow>
        <SelectField
          name="sexID"
          label="Sexo"
          value={selectSex?.find((sex) => sex.id === formik.values.sexID)}
          message={formik.errors.sexID}
          onChange={(selectedOption: any) => formik.setFieldValue('sexID', selectedOption.id)}
          options={selectSex}
          required
        />
        <InputMask
          name="mobilePhoneNumber"
          label="Celular"
          placeholder="(99) 9 9999 9999"
          guide={false}
          mask={phoneMask9}
          value={formik.values.mobilePhoneNumber}
          message={formik.errors.mobilePhoneNumber}
          onChange={({ target: { value } }) => formik.setFieldValue(
            'mobilePhoneNumber',
            value?.replace(/\D/g, ''),
          )}
          required
        />

      </InputControlRow>
      <InputControlRow>
        <InputControl
          name="email"
          type="email"
          placeholder="seuemail@email.com.br"
          label="E-mail"
          onChange={formik.handleChange}
          value={formik.values.email}
          message={formik.errors.email}
          required
        />
        <InputCurrency
          name="netWorth"
          label="Patrimônio líquido"
          value={formik.values.netWorth || ''}
          message={formik.errors.netWorth}
          onStringChange={(value, name) => formik.setFieldValue(name, value)}
          required
        />
      </InputControlRow>
      <InputControlRow>
        <SelectField
          name="profession"
          label="Profissão"
          value={professions?.find((profession) => profession.id === formik.values.professionID)}
          message={formik.errors.professionID}
          onChange={(selectedOption: any) => formik.setFieldValue('professionID', selectedOption.id)}
          options={professions}
          required
        />
        <InputCurrency
          name="income"
          label="Renda"
          value={formik.values.income}
          message={formik.errors.income}
          onStringChange={(value, name) => formik.setFieldValue(name, value)}
          required
        />
      </InputControlRow>
      {renderProfessionBasedForm(formik.values.professionID)}
      {isLoading && <Loading />}
    </Form>
  );
}
