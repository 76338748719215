import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > label {
      ${theme.texts.paragraphXXS};
      margin: 0 0 8px 0;

      > small {
        color: ${theme.colors.black50};
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
      }
    }

    &.error {
      > label {
        color: ${theme.colors.error00};
      }
      > div {
        > input {
        border: 1px solid ${theme.colors.error00};
      }
      }
    }
  `};
`;

export const Content = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;

    > input {
      border-radius: 4px;
      background-color: ${theme.colors.black05};
      border: 0;
      height: 42px;
      padding: 12px 16px;
      transition: ${theme.transitions.default};
      width: 100%;
      ${theme.texts.paragraphXS};

      &[type='date'] {
        text-transform: uppercase;
      }

      &[type='number'] {
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        -moz-appearance: textfield;
      }

      &::placeholder {
        color: ${theme.colors.black30};
      }

      &:disabled {
        color: ${theme.colors.black00};
        background-color: ${theme.colors.white80};
      }
    }

    
  `}
  `;

export const ContentCheckbox = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    position: absolute;
      right: 20px;
      top: 12px;

    ${theme.texts.paragraphXS};
  `}
`;
