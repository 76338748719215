import styled, { css } from 'styled-components';

export const GuarantorsTable = styled.div`
  ${({ theme }) => css`
    padding: 24px;
    border: 1px solid ${theme.colors.black10};
    border-radius: 4px;

    h2 {
      margin-bottom: 18px;
      ${theme.texts.headingXXS}
    }
  `}
`;

export const TableHeader = styled.div`
  ${({ theme }) => css`
    padding: 24px;
    margin-bottom: 11px;
    background-color: ${theme.colors.black05};
    border-radius: 4px;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 80px;

    > h3 {
      ${theme.texts.headingXXXS}
    }
  `}
`;

export const GuarantorCard = styled.div`
  ${({ theme }) => css`

    ${theme.texts.paragraphXS}
    padding: 11px 0px 11px 24px;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 100px;
    align-items: center;

    div:last-child {
      display: flex;
      align-items: center;
    }

    & + & {
      margin-top: 14px;
    }
  `} 
`;

export const GuarantorActions = styled.div`
  ${({ theme }) => css`
      display: flex;
      align-items: center;
      gap: 8px;

      button:last-child {
        background-color: ${theme.colors.error00}
      }
  `}
`;

export const GuarantorInfo = styled.div`
  ${({ theme }) => css`

    display: flex;
    align-items: center;
    gap: 6px;

    h1 {
      ${theme.texts.paragraphMD}
      font-weight: 600
    }

    small {
      ${theme.texts.paragraphXXXS}
      font-weight: 500;
      color: ${theme.colors.black50}
    }
  `}
`;

export const NoContent = styled.div`
 ${({ theme }) => css`
  width: 100%;
  max-width: 412px;
  margin: 58px auto 0;
  text-align: center;

    > h2 {
      ${theme.texts.buttonLG};
      color: ${theme.colors.black00};
      margin: 24px 14px 14px 14px;
    }

    > p {
      ${theme.texts.paragraphMD};
      color: ${theme.colors.black00};
    }
  `}
`;

export const Container = styled.div`
    padding: 0 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:  center;
  margin-bottom: 26px;
`;

export const BoxLeft = styled.div`
  ${({ theme }) => css`
    h2 {
      ${theme.texts.paragraphXS}
    }

    p {
      ${theme.texts.paragraphXXXS}
    }
  `}
`;

export const BoxRight = styled.div`
  position: relative;
  ${({ theme }) => css`
    > button {
      padding: 10px 40px 10px 20px;
      ${theme.texts.buttonXS}
    }

  `}
`;
