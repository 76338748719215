import React, {
  ReactElement,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react';

import { OperationInputContext } from '../../routes/AuthenticatedRoutes';

import {
  DataPreReview,
  VehicleWarranty,
  OperationData,
  CustomerData,
  Address,
  DocumentsChecklist,
  ConfirmationData,
  Loading,
  OperationStepFormBase,
  GuarantorData,
} from '../../components';

import {
  OperationsStepsContextValue,
  OperationStepTypes,
  OperationsStepsProps,
  OperationStepInfo,
} from './Operations.spec';

import { Container } from './styles';
import { productService, workflowService } from '../../services';
import workflowExecutions from '../../services/workflowExecutions';
import ProductData from '../../components/ProductData';

export const OperationStepsContext = createContext(
  {} as OperationsStepsContextValue,
);

export const Operations = (): ReactElement => {
  const {
    selectedCustomerType,
    selectedProduct: { workflowID, id: productID },
  } = useContext(OperationInputContext);
  const [currentOperationStep, setCurrentOperationStep] = useState<OperationStepTypes>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalSteps, setTotalSteps] = useState(0);
  const [workflowExecutionId, setWorkflowExecutionId] = useState<number | undefined>(undefined);

  const [currentStepData, setCurrentStepData] = useState<OperationStepInfo>();
  const [valuePreviousStep, setValuePreviousStep] = useState();

  const operationSteps: OperationsStepsProps = {
    '': <></>,
    SCR: (
      <OperationStepFormBase handleSubmit={() => executeStep({})}>
        <h1>SCR</h1>
      </OperationStepFormBase>
    ),
    QUOD: (
      <OperationStepFormBase handleSubmit={() => executeStep({})}>
        <h1>QUOD</h1>
      </OperationStepFormBase>
    ),
    PRE_ANALYSIS: <DataPreReview />,
    COLLATERAL_VEHICLE_DATA: <VehicleWarranty />,
    OPERATION_DATA: <OperationData />,
    GUARANTOR_DATA: <GuarantorData />,
    CUSTOMER_DATA: <CustomerData />,
    ADDRESS: <Address />,
    DOCUMENTS: <DocumentsChecklist />,
    CONFIRMATION_DATA: <ConfirmationData workflowExecutionId={workflowExecutionId} />,
    PRODUCT_DATA: <ProductData />,
  };

  const getElementFromSteps = (type: OperationStepTypes): ReactElement => operationSteps[type];

  useEffect(() => {
    if (workflowID) {
      getFirstStepInfo();
    }
  }, []);

  async function getFirstStepInfo(): Promise<void> {
    setIsLoading(true);
    try {
      const {
        type: operationStepType,
        totalSteps: stepsNumber,
        ...allOtherProps
      } = await workflowService.firstStepInfo(workflowID);
      setCurrentOperationStep(operationStepType);
      setTotalSteps(stepsNumber);
      setCurrentStepData({
        type: operationStepType,
        totalSteps: stepsNumber,
        ...allOtherProps,
      });
    } catch (error) {
      // alert(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function executeFirstStep(formValues: any): Promise<void> {
    setIsLoading(true);
    try {
      const response = await productService.executeWorkflow(productID, {
        ...formValues,
        customerType: selectedCustomerType,
      });

      const { workflowExecutionID: id, nextIndex, done } = response as any;

      setWorkflowExecutionId(id);

      if (done) {
        setCurrentStepData({
          type: 'CONFIRMATION_DATA',
          prevIndex: totalSteps - 1,
          stepIndex: totalSteps,
          totalSteps,
        });
        setCurrentOperationStep('CONFIRMATION_DATA');
        return;
      }

      const anotherResponse = (await workflowExecutions.stepInfo(
        id,
        nextIndex,
      )) as OperationStepInfo;

      setCurrentStepData(anotherResponse);
      setCurrentOperationStep(anotherResponse.type);
      setValuePreviousStep(formValues);
    } catch (error) {
      // alert(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function executeStep(formValues: any): Promise<void> {
    setIsLoading(true);
    try {
      const response = (await workflowExecutions.execute(
        workflowExecutionId,
        currentStepData?.stepIndex,
        {
          ...formValues,
          customerType: selectedCustomerType,
        },
      )) as OperationStepInfo;

      const {
        workflowExecutionID: id, nextIndex, done,
      } = response as any;

      setValuePreviousStep(formValues);

      if (done) {
        setCurrentStepData({
          type: 'CONFIRMATION_DATA',
          prevIndex: totalSteps - 1,
          stepIndex: totalSteps,
          totalSteps,
        });
        setCurrentOperationStep('CONFIRMATION_DATA');
        return;
      }

      const anotherResponse = (await workflowExecutions.stepInfo(
        id,
        nextIndex,
      )) as OperationStepInfo;

      setCurrentStepData(anotherResponse);
      setCurrentOperationStep(anotherResponse.type);
    } catch (error) {
      // alert(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  }

  async function handleStepSubmission(formValues: any): Promise<void> {
    if (currentStepData?.stepIndex === 0) executeFirstStep(formValues);
    else executeStep(formValues);
  }

  async function handlePreviousStep(): Promise<void> {
    if (currentStepData?.prevIndex === null) return;
    setIsLoading(true);
    try {
      const response = (await workflowExecutions.stepInfo(
        workflowExecutionId,
        currentStepData?.prevIndex,
      )) as any;

      setCurrentStepData(response);
      setCurrentOperationStep(response.type);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <OperationStepsContext.Provider
        value={{
          currentOperationStep,
          setCurrentOperationStep,
          handleStepSubmission,
          totalSteps,
          handlePreviousStep,
          currentStepData,
          valuePreviousStep,
          workflowExecutionId,
        }}
      >
        {getElementFromSteps(currentOperationStep)}
        {isLoading && <Loading />}
      </OperationStepsContext.Provider>
    </Container>
  );
};

export default Operations;
