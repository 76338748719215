import React, {
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import * as Yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import {
  RiAddFill,
} from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { Button, DataCardsList } from '../../../..';

import HandshakeIcon from '../../../../../assets/HandshakeIcon.svg';

import {
  Container, HeaderBox, BoxLeft, BoxRight, NoContent,
} from './styles';
import { AgentDTO, CoreFile, InfoMode } from '../../../../../@types';
import FormAgent from './FormAgent';
import {
  AGENT_YUP_SCHEMA, AgentContext, AgentCore, AgentProps, INITIAL_VALUES,
} from './Agent.spec';

const Agent = ({ agentList, setAgentList }: AgentProps): ReactElement => {
  const { t } = useTranslation();
  const [openedItemID, setOpenedItemID] = useState<string | undefined>(undefined);
  const [isCreatingAgent, setIsCreatingAgent] = useState<boolean>(false);
  const [newItem, setNewItem] = useState<AgentCore>();
  const [infoMode, setInfoMode] = useState<InfoMode>(InfoMode.CREATE);

  const remove = (agentID: string): void => {
    setAgentList(agentList.filter(({ id }) => id !== agentID));
    setOpenedItemID(undefined);
  };

  const edit = (agentID: string): void => {
    const findedAgent = agentList.find(({ id }) => id === agentID);

    if (!findedAgent) return;

    setInfoMode(InfoMode.EDIT);
    setOpenedItemID(agentID);
    newAgentFormik.setValues(findedAgent);
  };

  const newAgentFormik = useFormik<AgentCore>({
    initialValues: INITIAL_VALUES,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: AGENT_YUP_SCHEMA,
    onSubmit: (values) => {
      if (infoMode === InfoMode.CREATE) {
        setIsCreatingAgent(false);
        setAgentList([...agentList, { ...values, id: crypto.randomUUID() }]);
        setNewItem(undefined);
      }

      if (infoMode === InfoMode.EDIT) {
        const filteredAgents = agentList.filter(({ id }) => id !== openedItemID);
        setAgentList([...filteredAgents, { ...values }]);
        setOpenedItemID(undefined);
        setInfoMode(InfoMode.CREATE);
      }
      newAgentFormik.resetForm();
    },
  });

  return (
    <Container>
      <HeaderBox>
        <BoxLeft>
          <h2>{t('partnerAgent.titleAgent')}</h2>
          <p>
            <span>{agentList?.length || 0}</span>
            {' '}
            {t('partnerAgent.textFoundAgent')}
            {' '}
          </p>
        </BoxLeft>
        <BoxRight>

          <Button
            type="button"
            message="Novo sócio/representante"
            icon={<RiAddFill />}
            onClick={() => setIsCreatingAgent(true)}
          />
        </BoxRight>
      </HeaderBox>
      <AgentContext.Provider value={{ formik: newAgentFormik }}>
        {
          isCreatingAgent && (
            <DataCardsList
              item={newItem}
              visibleValue={newItem?.fullName || 'Sócio'}
              type="companyPartner"
              title={newItem?.fullName || 'Sócio'}
              subtitle={newItem?.email || 'E-mail'}
              open
              onSubmit={newAgentFormik.handleSubmit}
              onClose={() => setIsCreatingAgent(false)}
            >
              <FormAgent />
            </DataCardsList>
          )
        }
        {
          agentList.map((item, index) => (
            <DataCardsList
              key={item.id}
              item={item}
              visibleValue={item.fullName}
              type="companyPartner"
              title={item.fullName}
              subtitle={item.email}
              open={item.id === openedItemID}
              onOpen={() => edit(item.id)}
              onSubmit={newAgentFormik.handleSubmit}
              onClose={() => setOpenedItemID(undefined)}
              onRemove={() => remove(item.id)}
            >
              <FormAgent />
            </DataCardsList>
          ))
        }
      </AgentContext.Provider>
      {
        agentList.length === 0 && !isCreatingAgent && (
          <NoContent>
            <img src={HandshakeIcon} alt="Handshake Icon" />
            <h2>{t('partnerAgent.titleNoAgent')}</h2>
            <p>{t('partnerAgent.textNoAgent')}</p>
          </NoContent>
        )
      }
    </Container>
  );
};

export default Agent;
