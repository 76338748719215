import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import InputControl from '../../../../../../components/Input';
import InputMask from '../../../../../../components/MaskedInput';
import InputDate from '../../../../../../components/DateInput';
import { cpfMask, phoneMask9 } from '../../../../../../utils/masks';

import {
  Container, InputRow, ContentForm,
} from './styles';
import Select from '../../../../../../components/Select';
import { CustomerContext } from '../../../CustomerInfo';
import { selectService } from '../../../../../../services';
import { InfoMode } from '../../../../../../@types/InfoMode';
import { InputCurrency } from '../../../../../../components';
import Radio from '../../../../../../components/Radio';
import { FormGuarantorsProps } from './FormGuarantors.spec';

const FormGuarantors = ({ index, guarantor, disableSpouse }: FormGuarantorsProps): ReactElement => {
  const { t } = useTranslation();

  const { formik, mode } = useContext(CustomerContext);

  const [selectGender, setSelectGender] = useState();

  useEffect(() => {
    setSelectGender(selectService.getGender());
  }, []);

  const modeView = mode === InfoMode.VIEW;

  function fieldName(name: string): string {
    return `guarantors[${index}].${name}`;
  }

  return (
    <Container>
      <ContentForm>
        <InputRow>
          <InputControl
            name={fieldName('name')}
            label={t('customerGuarantors.labelInputName') || ''}
            placeholder={t('customerGuarantors.placeholderInputName') || ''}
            value={guarantor.name}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <InputMask
            name={fieldName('cpf')}
            label={t('customerGuarantors.labelInputCPF') || ''}
            placeholder="000.000.000-00"
            mask={cpfMask}
            guide={false}
            value={guarantor.cpf}
            onChange={({ target: { value } }) => formik.setFieldValue(fieldName('cpf'), value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <Select
            name={fieldName('civilStatus')}
            label={t('customerGuarantors.labelInputCivilStatus') || ''}
            options={selectGender}
            value={guarantor.civilStatus}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('civilStatus'), selectedOption)}
            isDisabled={modeView}
          />

          <InputDate
            name={fieldName('birthDate')}
            label={t('customerGuarantors.labelInputBirthDate') || ''}
            selected={guarantor.birthDate}
            onChange={(value: any) => {
              formik.setFieldValue(fieldName('birthDate'), value);
            }}
            disabled={modeView}
          />
        </InputRow>

        <InputRow>

          <InputMask
            name={fieldName('phone')}
            label={t('customerGuarantors.labelInputPhone') || ''}
            placeholder="+55 51 9 9999 9999"
            mask={phoneMask9}
            guide={false}
            value={guarantor.phone}
            onChange={({ target: { value } }) => formik.setFieldValue(fieldName('phone'), value?.replace(/\D/g, ''))}
            disabled={modeView}
          />

          <InputControl
            name={fieldName('email')}
            label={t('customerGuarantors.labelInputEmail') || ''}
            placeholder={t('customerGuarantors.placeholderInputEmail') || ''}
            type="email"
            value={guarantor.email}
            onChange={formik.handleChange}
            disabled={modeView}
          />

          <Select
            name={fieldName('occupation')}
            label={t('customerGuarantors.labelInputOccupation') || ''}
            options={selectGender}
            value={guarantor.occupation}
            onChange={(selectedOption: any) => formik.setFieldValue(fieldName('occupation'), selectedOption)}
            isDisabled={modeView}
          />

          <InputCurrency
            name={fieldName('income')}
            label={t('customerGuarantors.labelInputIncome') || ''}
            value={guarantor.income}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
          />

        </InputRow>
        <InputRow>
          <InputCurrency
            name={fieldName('netWorth')}
            label={t('customerGuarantors.labelInputNetWorth') || ''}
            value={guarantor.netWorth}
            onStringChange={(value, name) => {
              formik.setFieldValue(name, value);
            }}
            disabled={modeView}
          />

          <Radio
            name={fieldName('spouse')}
            label={t('customerGuarantors.labelInputOwnCompany') || ''}
            checked={guarantor.spouse}
            onChangeOne={() => formik.setFieldValue(fieldName('spouse'), true)}
            onChangeTwo={() => formik.setFieldValue(fieldName('spouse'), false)}
            readonly={modeView || disableSpouse}
          />
        </InputRow>
      </ContentForm>

    </Container>
  );
};

export default FormGuarantors;
