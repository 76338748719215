import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fuelTypeService, selectService } from '../../../../services';
import { cnpjMask, cpfMaskPlus } from '../../../../utils/masks';
import InputCurrency from '../../../CurrencyInput';
import InputControl from '../../../Input';
import InputMask from '../../../MaskedInput';
import SelectField from '../../../Select';
import { FormVehicleWarrantyProps } from '../FormVehicleWarranty.spec';

import { ContentForm, InputRow } from '../styles';
import { useListItem } from '../../../../hooks';

export function WarrantyInfoForm({
  index,
  vehicleWarranty,
  formik,
}: FormVehicleWarrantyProps): ReactElement {
  const { t } = useTranslation();
  const [selectGender, setSelectGender] = useState([]);
  const { item: fuelTypeOptions } = useListItem(fuelTypeService);

  const vehicleTypeOptions = [
    {
      id: 1,
      text: 'Carro',
    },
    {
      id: 2,
      text: 'Moto',
    },
  ];

  const brandOptions = [
    {
      id: 1,
      text: 'Volkswagen',
    },
    {
      id: 2,
      text: 'Audi',
    },
  ];

  const modelOptions = [
    {
      id: 1,
      text: 'Jetta',
    },
    {
      id: 2,
      text: 'Tiguan',
    },
  ];

  const manufacturingYearOptions = [
    {
      id: 1,
      text: '2010',
    },
    {
      id: 2,
      text: '2011',
    },
  ];

  const modelYearOptions = [
    {
      id: 1,
      text: '2010',
    },
    {
      id: 2,
      text: '2011',
    },
  ];

  const lienStateOptions = [
    {
      id: 1,
      text: 'RS',
    },
    {
      id: 2,
      text: 'PR',
    },
  ];

  function fieldName(name: string): string {
    return `collateralVehicles[${index}].${name}`;
  }

  useEffect(() => {
    setSelectGender(selectService.getSelectItems());
  }, []);

  return (
    <ContentForm>
      <InputRow>
        <SelectField
          name={fieldName('vehicleTypeID')}
          label={t('warranty.labelInputVehicleType') || ''}
          options={vehicleTypeOptions}
          value={
            vehicleWarranty.vehicleTypeID
              ? vehicleTypeOptions?.find(
                (e: any) => e.id === vehicleWarranty.vehicleTypeID,
              )
              : null
          }
          onChange={(selectedOption: any) => formik.setFieldValue(fieldName('vehicleTypeID'), selectedOption.id)}
          required
        />

        <SelectField
          name={fieldName('brandID')}
          label={t('warranty.labelInputBrand') || ''}
          options={brandOptions}
          value={
            vehicleWarranty.brandID
              ? brandOptions?.find((e: any) => e.id === vehicleWarranty.brandID)
              : null
          }
          onChange={(selectedOption: any) => formik.setFieldValue(fieldName('brandID'), selectedOption.id)}
          required
        />
      </InputRow>

      <InputRow>
        <SelectField
          name={fieldName('modelID')}
          label={t('warranty.labelInputModel') || ''}
          options={modelOptions}
          value={
            vehicleWarranty.modelID
              ? modelOptions?.find((e: any) => e.id === vehicleWarranty.modelID)
              : null
          }
          onChange={(selectedOption: any) => formik.setFieldValue(fieldName('modelID'), selectedOption.id)}
          required
        />

        <SelectField
          name={fieldName('manufacturingYearID')}
          label={t('warranty.labelInputYearManufacture') || ''}
          options={manufacturingYearOptions}
          value={
            vehicleWarranty.manufacturingYearID
              ? manufacturingYearOptions?.find(
                (e: any) => e.id === vehicleWarranty.manufacturingYearID,
              )
              : null
          }
          onChange={(selectedOption: any) => formik.setFieldValue(
            fieldName('manufacturingYearID'),
            selectedOption.id,
          )}
          required
        />
      </InputRow>

      <InputRow>
        <SelectField
          name={fieldName('modelYearID')}
          label={t('warranty.labelInputModelYear') || ''}
          options={modelYearOptions}
          value={
            vehicleWarranty.modelYearID
              ? modelYearOptions?.find(
                (e: any) => e.id === vehicleWarranty.modelYearID,
              )
              : null
          }
          onChange={(selectedOption: any) => formik.setFieldValue(fieldName('modelYearID'), selectedOption.id)}
          required
        />

        <InputControl
          name={fieldName('color')}
          label={t('warranty.labelInputColor') || ''}
          placeholder={t('warranty.placeholderInputColor') || ''}
          value={vehicleWarranty.color}
          onChange={formik.handleChange}
          required
        />
      </InputRow>
      <InputRow>
        <InputControl
          name={fieldName('licensePlate')}
          label={t('warranty.labelInputPlate') || ''}
          placeholder={t('warranty.placeholderInputPlate') || ''}
          value={vehicleWarranty.licensePlate}
          onChange={formik.handleChange}
          required
        />

        <InputControl
          name={fieldName('chassisNumber')}
          label={t('warranty.labelInputChassis') || ''}
          placeholder={t('warranty.placeholderInputChassis') || ''}
          value={vehicleWarranty.chassisNumber}
          onChange={formik.handleChange}
          required
        />
      </InputRow>
      <InputRow>
        <InputControl
          name={fieldName('vehicleDocumentNumber')}
          label={t('warranty.labelInputRenavam') || ''}
          placeholder={t('warranty.placeholderInputRenavam') || ''}
          value={vehicleWarranty.vehicleDocumentNumber}
          onChange={formik.handleChange}
          required
        />
        <InputCurrency
          name={fieldName('vehicleValue')}
          label={t('warranty.labelInputVehicleValue') || ''}
          value={vehicleWarranty.vehicleValue}
          onStringChange={(value) => formik.setFieldValue(fieldName('vehicleValue'), value)}
          required
        />
      </InputRow>

      <InputRow>
        <SelectField
          name={fieldName('lienStateID')}
          label={t('warranty.labelInputUFLien') || ''}
          placeholder={t('warranty.placeholderInputUFLien') || ''}
          options={lienStateOptions}
          value={
            vehicleWarranty.lienStateID
              ? lienStateOptions?.find(
                (e: any) => e.id === vehicleWarranty.lienStateID,
              )
              : null
          }
          onChange={(selectedOption: any) => formik.setFieldValue(fieldName('lienStateID'), selectedOption.id)}
          required
        />
        <InputControl
          name={fieldName('lien')}
          label={t('warranty.labelInputLien') || ''}
          placeholder={t('warranty.placeholderInputLien') || ''}
          value={vehicleWarranty.lien}
          onChange={formik.handleChange}
          // disabled
        />
      </InputRow>

      <InputRow>
        <InputControl
          name={fieldName('invoiceNumber')}
          label={t('warranty.labelInputReceipt') || ''}
          placeholder={t('warranty.placeholderInputReceipt') || ''}
          type="number"
          value={vehicleWarranty.invoiceNumber}
          onChange={formik.handleChange}
          required
        />

        <InputMask
          name={fieldName('creditorDocumentNumber')}
          label={t('warranty.labelInputCreditorDocumentNumber') || ''}
          placeholder={
            t('warranty.placeholderInputCreditorDocumentNumber') || ''
          }
          value={vehicleWarranty.creditorDocumentNumber}
          guide={false}
          mask={
            vehicleWarranty.creditorDocumentNumber?.length > 11
              ? cnpjMask
              : cpfMaskPlus
          }
          onChange={({ target: { value } }) => formik.setFieldValue(
            fieldName('creditorDocumentNumber'),
            value?.replace(/\D/g, ''),
          )}
          required
        />
      </InputRow>

      <InputRow>
        <InputControl
          name={fieldName('ownerFullName')}
          label={t('warranty.labelInputOwnerFullName') || ''}
          placeholder={t('warranty.placeholderInputOwnerFullName') || ''}
          value={vehicleWarranty.ownerFullName}
          onChange={formik.handleChange}
          required
        />
        <InputMask
          name={fieldName('ownerDocumentNumber')}
          label={t('warranty.labelInputOwnerDocumentNumber') || ''}
          placeholder={t('warranty.placeholderInputOwnerDocumentNumber') || ''}
          value={vehicleWarranty.ownerDocumentNumber}
          guide={false}
          mask={
            vehicleWarranty.ownerDocumentNumber?.length > 11
              ? cnpjMask
              : cpfMaskPlus
          }
          onChange={({ target: { value } }) => formik.setFieldValue(
            fieldName('ownerDocumentNumber'),
            value?.replace(/\D/g, ''),
          )}
          required
        />
      </InputRow>
      <InputRow>
        <SelectField
          name={fieldName('fuelTypeID')}
          label={t('warranty.labelInputFuelType') || ''}
          placeholder={t('warranty.placeholderInputFuelType') || ''}
          options={fuelTypeOptions}
          value={
            vehicleWarranty.fuelTypeID
              ? fuelTypeOptions?.find(
                (e: any) => e.id === vehicleWarranty.fuelTypeID,
              )
              : null
          }
          onChange={(selectedOption: any) => formik.setFieldValue(fieldName('fuelTypeID'), selectedOption.id)}
          required
        />
      </InputRow>
    </ContentForm>
  );
}
