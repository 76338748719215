import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiSearchLine } from 'react-icons/ri';

import {
  Navbar, Button, Breadcrumb, Table, Header,
} from '../../../components';

import { customerService } from '../../../services';

import {
  customerTableContentKeys,
  customerTableHeaderTitles,
  customersBreadCrumbItems,
} from '../../../mock';

import {
  Container, SubHeader, SearchInput, MainContent,
} from './styles';

import { CoreCustomer } from '../../../@types';

const Partners: React.FC = () => {
  const [customers, setCustomers] = useState<CoreCustomer[]>([]);

  useEffect(() => {
    setCustomers(customerService.get());
  }, []);

  function handleDeleteCustomer(id: number): void {
    customers.splice(id, 1);
  }

  return (
    <Container>
      <Header breadcrumbItems={customersBreadCrumbItems} title="Clientes" />

      <SubHeader>
        <SearchInput>
          <RiSearchLine />
          <input type="text" placeholder="Buscar cliente" />
        </SearchInput>
        <Link to="/registration/customers/create">
          <Button message="Novo cliente" />
        </Link>
      </SubHeader>

      <MainContent>
        <Table
          itemBaseURL="/registration/customers"
          tableHeader={customerTableHeaderTitles}
          tableData={customers}
          tableKeys={customerTableContentKeys}
          handleDeleteItem={(id: number) => {
            handleDeleteCustomer(id);
          }}
          excludeModalTexts={{
            type: 'customer',
            visibleValue: 'name',
          }}
        />
      </MainContent>
    </Container>
  );
};

export default Partners;
