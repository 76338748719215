import React, {
  ReactElement, useCallback, useState, useEffect,
} from 'react';
import _ from 'lodash';

import { RiSearchLine } from 'react-icons/ri';

import { Container } from './styles';

import { SearchInputProps } from './SearchInput.spec';

const SearchInput = ({ value, onChange }: SearchInputProps): ReactElement => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const debouncedOnChange = useCallback(
    _.debounce((val: string) => onChange?.(val), 600),
    [onChange],
  );

  useEffect(() => {
    debouncedOnChange(searchQuery);
  }, [searchQuery, debouncedOnChange]);

  useEffect(() => {
    setSearchQuery(value || '');
  }, [value]);

  return (
    <Container>
      <RiSearchLine />
      <input
        type="text"
        placeholder="Buscar produto"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </Container>
  );
};

export default SearchInput;
