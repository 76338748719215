import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    > h1 {
      ${theme.texts.headingMD};
      margin-bottom: 32px;
    }

    .tabNavigationHeader {
      /* padding: 24px 0 0 0; */
      position: sticky;
      top: 110px;
      z-index: 1000;
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    > h1 {
      ${theme.texts.headingMD};
    }

    > a {
      border: 1px solid ${theme.colors.black20};
      border-radius: 4px;

      width: 54px;
      height: 54px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: ${theme.transitions.default};

      :hover { 
        border: 1px solid ${theme.colors.black00};
      }

      > svg {
        font-size: 2rem;
        color: ${theme.colors.black00};
      }
    }
  `}
`;

export const Form = styled.form`
display: flex;
flex-direction: column;
flex: 1;
`;

export const ActionsButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid ${theme.colors.black10};
    background-color: ${theme.colors.white00};

    position: sticky;
    bottom: 0;
    width: 100%;

    > a {
      ${theme.texts.buttonXS};
      color: ${theme.colors.black00};
      background-color: transparent;
      /* margin-right: 24px; */
    }

    > button {
      ${theme.texts.buttonXS};
      padding: 10px 20px;
      width: auto;
      height: 42px;
      border-radius: 4px;
    }

    > a {
      ${theme.texts.buttonXS};
      padding: 10px 20px;
      border-radius: 4px;

      color: ${theme.colors.white00};
      background-color: ${theme.colors.black00};

      &:hover {
        opacity: 0.9;
      }
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    /* border-top: 1px solid ${theme.colors.black10}; */
    padding: 24px 0 0;
    width: 100%;
    position: absolute;
    bottom: 66px;
    left: 0;
  `}
`;
