import styled, { css } from 'styled-components';

export const Container = styled.section``;

export const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 10px;
  flex-wrap: wrap;

  margin-bottom: 24px;

  > div {
    width: 100%;

  }
`;

export const FormContainer = styled.div`
  > div {
    padding: 0;
  }
`;

export const InputSolo = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;

  margin-bottom: 24px;

  > div {
    width: 100%;
    textarea {
      height: 100px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => css`

    > div > button  {
      padding: 12px 20px;
      ${theme.texts.buttonXS} 
    }
  `}
`;

export const BoxRight = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 6px;

    button:last-child {
      padding: 12px 38px 14px 20px;
    }

    button:not(:last-child) {
      background: transparent
    }
  `};
`;

export const BoxLeft = styled.div`
  > button:first-child {
    padding-left: 0;
  }
`;

export const DeleteButton = styled.button`
  ${({ theme }) => css`
      background-color: transparent;
      color: ${theme.colors.error00};
  `}
`;

export const ContentForm = styled.div`
  ${({ theme }) => css`
    width: 100%;
    > h2 {
      ${theme.texts.headingXXS};
      margin-bottom: 24px;
    }
  `}
`;
