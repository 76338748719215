export const homeBreadCrumbItems = [
  { title: 'Página inicial' },
];

export const userBreadCrumbItems = [
  { title: 'Cadastros' },
  { title: 'Usuários' },
];

export const productsBreadCrumbItems = [
  { title: 'Cadastros' },
  { title: 'Produtos' },
];

export const agentsBreadCrumbItems = [
  { title: 'Cadastros' },
  { title: 'Agentes' },
];

export const representativesBreadCrumbItems = [
  { title: 'Cadastros' },
  { title: 'Representantes' },
];

export const customersBreadCrumbItems = [
  { title: 'Cadastros' },
  { title: 'Clientes' },
];

export const workflowBreadCrumbItems = [
  { title: 'Gerenciamento' },
  { title: 'Workflows' },
];

export const operationsBreadCrumbItems = [
  { title: 'Página inicial' },
  { title: 'Operacional' },
];

export const newOperationBreadCrumbItems = [
  { title: 'Operações' },
  { title: 'Nova operação' },
];
