import styled, { css } from 'styled-components';

export const Container = styled.ul`
  display: flex;
  align-items: center;

  margin: 0 0 24px 0;
`;

export const TabNavigationItem = styled.li<{ active?: boolean }>`
  ${({ theme, active }) => css`
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    ${theme.texts.headingXXS};
    color: ${theme.colors.black50};
    cursor: pointer;
    border-bottom: 2px solid ${theme.colors.black10};
    position: relative;
    padding: 0 0 16px 0;

    transition: ${theme.transitions.default};

    &:after {
      display: block;
      content: '';
      width: 5%;
      height: 2px;
      background-color: ${theme.colors.black10};
      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translateX(-50%);

      transition: 200ms ease-in;
    }

    ${active
    && css`
      color: ${theme.colors.black00};

      &:after {
        width: 100%;
        left: 0;
        transform: unset;
        background-color: ${theme.colors.black00};
      }
    `};
  `};
`;
