import React, {
  ReactElement, useContext, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  RiBodyScanFill,
  RiCheckboxCircleFill,
  RiGroupFill,
  RiMapPin2Fill,
  RiMoneyDollarCircleFill,
  RiProfileFill,
  RiUser2Fill,
} from 'react-icons/ri';
import documentsItems from '../../mock/documents';

import {
  Container,
  Content,
  BoxData,
  CustomerData,
  ProductData,
  Documents,
  BoxDocument,
  ItemDocument,
  Observation,
} from './styles';
import OperationStepFormBase from '../OperationStepFormBase';
import { OutletContent } from '../../pages/Operations/styles';
import { operationsService, workflowExecutionsService } from '../../services';
import { OperationInputContext } from '../../routes/AuthenticatedRoutes';

interface DocumentIconsProps {
  [key: string]: ReactElement;
}
const ConfirmationData = ({ workflowExecutionId }: any): ReactElement => {
  const { selectedProduct } = useContext(OperationInputContext);
  const [workflowSummary, setWorkflowSummary] = useState<any>([]);
  const [customerData, setCustomerData] = useState<any>({});

  const documentIcons: DocumentIconsProps = {
    address: <RiMapPin2Fill />,
    picture: <RiProfileFill />,
    selfie: <RiBodyScanFill />,
    income: <RiMoneyDollarCircleFill />,
    legalPerson: <RiUser2Fill />,
    '': <RiGroupFill />,
  };

  useEffect(() => {
    getWorkflowExecutionsSummary();
  }, []);

  async function getWorkflowExecutionsSummary(): Promise<void> {
    try {
      const { responseWorkflowExecution, workflowStepExecutions } = await workflowExecutionsService.getOne(workflowExecutionId) as any;
      const findCustomerData = await workflowStepExecutions?.find((item: any) => item?.type === 'CUSTOMER_DATA').inputData;
      setCustomerData(findCustomerData);
      setWorkflowSummary(responseWorkflowExecution);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleOperationCreate(): Promise<void> {
    try {
      const { id: operationID } = await operationsService.createWorkflowExecution(workflowExecutionId) as any;
      toast.success(
        `Operação criada com sucesso!, ${operationID}`, {
          className: 'toast-message',
          icon: RiCheckboxCircleFill,
          containerId: 'close-on',
        },
      );
    } catch (error) {
      console.error(error);
      toast.error(
        'Erro ao criar a operação!', {
          className: 'toast-message',
          icon: RiCheckboxCircleFill,
          containerId: 'close-on',
        },
      );
    }
  }

  return (
    <OperationStepFormBase handleSubmit={() => handleOperationCreate()}>
      <OutletContent>
        <Container>
          <Content>
            <CustomerData>
              <h2>Dados do cliente:</h2>
              <BoxData>
                <h4>Nome:</h4>
                <p>{customerData?.fullName || ''}</p>
              </BoxData>
              <BoxData>
                <h4>CPF:</h4>
                <p>{customerData?.documentNumber || ''}</p>
              </BoxData>
              <BoxData>
                <h4>Nascimento:</h4>
                <p>{customerData?.birthdate || ''}</p>
              </BoxData>
              <BoxData>
                <h4>Telefone:</h4>
                <p>{customerData?.mobilePhoneNumber || ''}</p>
              </BoxData>
              <BoxData>
                <h4>CEP:</h4>
                <p>{customerData?.postalCode || ''}</p>
              </BoxData>
              <BoxData>
                <h4>Endereço:</h4>
                <p>{customerData?.line1 || ''}</p>
              </BoxData>
              <BoxData>
                <h4>Número/comp.</h4>
                <p>{customerData?.houseNumber || ''}</p>
              </BoxData>
              {/* <BoxData>
                <h4>Cidade/estado:</h4>
                <p>{customerData?.houseNumber || ''}</p>
              </BoxData> */}
            </CustomerData>
            <ProductData>
              <h2>Dados do produto:</h2>
              <BoxData>
                <h4>Produto:</h4>
                <p>{selectedProduct?.name || ''}</p>
              </BoxData>
              <BoxData>
                <h4>Valor:</h4>
                <p>R$ 10.000,00</p>
              </BoxData>
              <BoxData>
                <h4>Entrada:</h4>
                <p>R$ 10.000,00</p>
              </BoxData>
              <BoxData>
                <h4>Parcelas:</h4>
                <p>64</p>
              </BoxData>
              <BoxData>
                <h4>1º vencimento:</h4>
                <p>01/01/2023</p>
              </BoxData>
              {/* <BoxData>
                <h4>Venda presenc.</h4>
                <p>Sim</p>
              </BoxData>
              <BoxData>
                <h4>Avalistas:</h4>
                <p>Maria da Silva (cônjuge)</p>
              </BoxData> */}
            </ProductData>
            {/* <Documents>
              <h2>Documentos anexados:</h2>
              <BoxDocument>
                {documentsItems.map((item) => (
                  <span key={item?.id}>
                    {item?.checked && (
                      <ItemDocument>
                        {documentIcons[item.category]}
                        {' '}
                        {item.description}
                      </ItemDocument>
                    )}
                  </span>
                ))}
              </BoxDocument>
            </Documents> */}
            {/* <Observation>
              <h2>Observações:</h2>
              <textarea placeholder="Escreva alguma observação" />
            </Observation> */}
          </Content>
        </Container>
      </OutletContent>
    </OperationStepFormBase>
  );
};

export default ConfirmationData;
