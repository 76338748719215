import React, { ReactElement } from 'react';

import { Container, LoadingCircle, Circle } from './styles';

const Loading = (): ReactElement => (
  <Container>
    <LoadingCircle>
      <Circle />
    </LoadingCircle>
  </Container>
);

export default Loading;
